import React, {useEffect} from 'react';
import {BottomPage} from '../../../clean-components/bottom-page/bottom-page';
import {LineDetailComponent} from '../../../clean-components/line-detail/line-detail.component';
import {BackHomeButton} from '../../../components/buttons/backHome-button/backHome-button';

export const LineDetailMobile = () => {

    useEffect(() => {
        document.getElementsByClassName('header')[0].classList.add('hide');
    }, [location]);

    return <>
        <BackHomeButton/>;
        <BottomPage maximizedOnCreate={true} detail={true}>
            <LineDetailComponent buttons={['report']}/>
        </BottomPage>
    </>;
};
