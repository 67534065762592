import React from 'react';
import {AlertsComponent} from '../../../clean-components/alerts/alerts.component';
import {Page} from '../../../clean-components/page/page';


export const AlertsMobile = () => {
    return <Page title="translation:app_menu_alerts_and_incidences_title">
        <AlertsComponent/>
    </Page>;
};
