export const stopSchema = {
    title: 'stops',
    primaryKey: 'id',
    version: 0,
    description: 'AMB Stops',
    type: 'object',
    properties: {
        id: {
            type: 'string',
        },
        slug: {
            type: 'string',
        },
        routesIds: {
            type: 'array',
            ref: 'routes',
            items: {
                type: 'string',
            },
        },
        stopName: {
            type: 'string',
        },
        stopDesc: {
            type: 'string',
        },
        stopCode: {
            type: 'string',
        },
        stopUrl: {
            type: 'string',
        },
        stopType: {
            type: 'string',
        },
        parentStation: {
            type: 'string',
        },
        routesTypes: {
            type: 'array',
            items: {
                type: 'number',
            },
        },
        wheelchairBoarding: {
            type: 'boolean',
        },
        stopId: {
            type: 'string',
        },
        lat: {
            type: 'number',
        },
        lng: {
            type: 'number',
        },
        locationType: {
            type: 'number',
        },
    },
    indexes: [
        'lat',
        'lng',
    ],
};
