import React, {useState} from 'react';
import {HeaderProvider} from './header.context';

export const HeaderService = ({children}: any) => {
    const [headerConfig, initHeader] = useState({});

    return <HeaderProvider value={{
        initHeader,
        headerConfig,
    }}>
        {children}
    </HeaderProvider>;
};
