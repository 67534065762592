import React, {useState} from 'react';
import {BottomSheetCtx} from './bottom-sheet-context';

export const BottomSheetService = ({children}: any) => {
    const [open] = useState(true);
    const [skipInitialTransition] = useState(true);
    const [minHeight, setMinHeightCtx] = useState(87);
    const [detailHeight] = useState(window.innerHeight / 1.5);
    const [currentPosition, setCurrentPositionCtx] = useState(window.innerHeight / 2.4);
    const [maxHeight, setMaxHeightCtx] = useState(window.innerHeight);
    const [mediumHeight, setMediumHeightCtx] = useState(366);
    const [topHeight, setTopHeightCtx] = useState(133);
    const [maximized, setMaximizedCtx] = useState(false);
    const [detail, setDetailCtx] = useState(false);
    const [useMedium, setUseMediumCtx] = useState(false);
    const setMinHeight = (height: any) => setMinHeightCtx(height);
    const setMaxHeight = (height: any) => setMaxHeightCtx(height);
    const setTopHeight = (height: any) => setTopHeightCtx(height);
    const setMediumHeight = (height: any) => setMediumHeightCtx(height);


    const setCurrentPosition = (height: any) => {
        setCurrentPositionCtx(height);
    };
    const setMaximized = (maximized: boolean) => setMaximizedCtx(maximized);
    const setDetail = (detail: boolean) => setDetailCtx(detail);
    const setMedium = (medium: boolean) => setUseMediumCtx(medium);

    const initPosition = 87;

    return <>
        <BottomSheetCtx.Provider value={{
            currentPosition,
            initPosition,
            open,
            skipInitialTransition,
            minHeight,
            mediumHeight,
            maxHeight,
            topHeight,
            maximized,
            detail,
            detailHeight,
            useMedium,
            setMedium,
            setMinHeight,
            setMaxHeight,
            setTopHeight,
            setCurrentPosition,
            setMaximized,
            setMediumHeight,
            setDetail,
        }}>
            {children}
        </BottomSheetCtx.Provider>
    </>;
};
