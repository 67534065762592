import React, {createElement} from 'react';
import {ReactComponent as zbeLogo} from '../../assets/icons/map/logoOtrosZbe.svg';
import SwitchButton from '../../components/buttons/switch-button/switch-button';

import './transport-list-item.scss';

interface TransportItemInterface {
    icon: string,
    name: string,
    active: boolean,
    changeValue: any,
    slug?: any,
    value: string,
    color: string,
}

export const TransportListItemComponent = (props: TransportItemInterface) => {

    const getOtherIcon = () => {
        if (props.slug == 'zbe') {
            return createElement(zbeLogo);
        }
    };

    return <div className="transports-item-container">
        <div className="transports-item-info">
            {(!!props.icon) ?
                <>
                    <img src={props.icon} alt="" style={{backgroundColor: props.color}}/>
                    <span className="transport-name">{props.name}</span>
                </> :
                <>
                    {getOtherIcon()}
                    <span className="transport-name">{props.name}</span>
                </>
            }
        </div>
        <SwitchButton
            active={props.active}
            changeValue={props.changeValue}
            value={props.value}
        />
    </div>;
};
