import React, {useContext, useEffect, useRef} from 'react';
import {BottomSheet, BottomSheetRef} from 'react-spring-bottom-sheet';
import {BottomSheetCtx} from '../../services/bottom-sheet/bottom-sheet-context';
import {Spinner} from '../spinner/spinner';

import './bottom-page.scss';

interface BottomPageProps {
    maximizedOnCreate?: boolean,
    children: any,
    medium?: any,
    detail?: any
};

export function BottomPage({children, maximizedOnCreate, medium, detail}: BottomPageProps) {
    const focusRef = useRef<HTMLButtonElement>(null);
    const sheetRef = useRef<BottomSheetRef>(null);
    const bottomctx = useContext(BottomSheetCtx);

    const initsnap = (): number => {
        if (medium) {
            return bottomctx.mediumHeight;
        }
        if (detail) {
            return bottomctx.detailHeight;
        } else {
            return bottomctx.maxHeight;
        }
    };

    useEffect(() => {
        if (bottomctx.maximized) {
            bottomctx.setMaximized(false);
            sheetRef.current?.snapTo(bottomctx.maxHeight);
        }
        if (bottomctx.detail) {
            bottomctx.setDetail(false);
            sheetRef.current?.snapTo(bottomctx.detailHeight);
        }
    }, [bottomctx, bottomctx.detail]);

    return <>
        <BottomSheet
            open={bottomctx.open}
            skipInitialTransition={bottomctx.skipInitialTransition}
            ref={sheetRef}
            initialFocusRef={focusRef}
            defaultSnap={({maxHeight}) => maximizedOnCreate ? initsnap() : 0}
            snapPoints={({maxHeight}) => [
                bottomctx.minHeight,
                bottomctx.mediumHeight,
                bottomctx.detailHeight,
                bottomctx.maxHeight,
            ]}
            onSpringStart={(event: any) => {
                requestAnimationFrame(() =>
                    bottomctx.setCurrentPosition(sheetRef.current?.height),
                );
            }}
            blocking={false}
        >
            <Spinner/>
            {children}
        </BottomSheet>
    </>;
}

