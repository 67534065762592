import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MessageKeys} from '../../core/constants/map.enums';
import {TRANSPORT_GROUPS} from '../../core/constants/transport.constants';
import {MapContext} from '../../services/map-v2/map.context';
import TransportComponent from './transport/transport';

import './transports.scss';

function TransportsComponent({
    selected,
}: {
    selected?: any,
}) {

    const {t} = useTranslation();
    const {warningLocationVisibility} = useContext(MapContext);

    const [buttonsDisabled, setButtonsDisabled] = useState<Boolean>(true);

    useEffect(() => {
        setButtonsDisabled(!(warningLocationVisibility === MessageKeys.NONE));
    }, [warningLocationVisibility]);

    return <div className="transports">
        <div className="transports-box">
            {
                TRANSPORT_GROUPS.map((transport: any, index: number) =>
                    <div key={index} className="transports-container">
                        <TransportComponent
                            title={t(transport.title)}
                            id={transport.id}
                            icon={transport.icon}
                            selected={selected}
                            disabled={buttonsDisabled}/>
                    </div>)
            }
        </div>
    </div>;
}

export default TransportsComponent;
