import React, {createElement} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import MapFilterComponent from '../../clean-components/map-filter/map-filter.component';
import {LocationButtonComponent} from '../../clean-components/map/location-button.component';
import {Menu} from '../../clean-components/menu/menu';
import {SidebarComponent} from '../../clean-components/sidebar/sidebar.component';
import SearchButtonComponent from '../../components/buttons/search-button/search-button';
import TransportsButtonComponent from '../../components/buttons/transports-button/transports-button';
import {DskPageService} from '../../services/desktop-page/desktop-page.service';
import {LayerService} from '../../services/layer/layer.service';
import {desktopRoutingConfig} from './desktop-routing.config';

export const DesktopRouting = () => {
    return <>
        <LayerService>
            <DskPageService>
                <MapFilterComponent/>
                <SearchButtonComponent/>
                <TransportsButtonComponent/>
                <LocationButtonComponent/>
                <Switch>
                    {desktopRoutingConfig.routes.map((route, key) => {
                        return <Route key={key} exact path={route.path}>
                            {createElement(route.component)}
                        </Route>;
                    })}
                    <Redirect to={desktopRoutingConfig.default}/>
                </Switch>
                <SidebarComponent>
                    <Menu/>
                </SidebarComponent>
            </DskPageService>
        </LayerService>
    </>;
};
