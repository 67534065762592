import React, {useContext} from 'react';
import {isMobile} from 'react-device-detect';
import {SidebarContext} from '../../../services/sidebar/sidebar.context';
import {analyticsEvent} from '../../../utils/analytics';

import './sidebar-button.scss';

function SidebarButton() {
    const {toggleVisible} = useContext(SidebarContext);

    const onToogleVisible = () => {
        analyticsEvent('Home', 'home_main_click');
        toggleVisible();
    };

    return <button className={`sidebar-button ${!isMobile ? 'desktop' : ''}`} onClick={onToogleVisible}>
        <div className="line"/>
        <div className="line"/>
        <div className="line"/>
    </button>;
}

export default SidebarButton;
