import React from 'react';
import {Page} from '../../../clean-components/page/page';
import {SettingsComponent} from '../../../clean-components/setttings/settings.component';
import './settings.scss';

export function SettingsMobile() {
    return <Page title="translation:misc_settings_title">
        <SettingsComponent/>
    </Page>;
}
