import React from 'react';
import {ListItem} from './list-item.component';
import {ListInterface} from './list.interface';

import './list.scss';

export const List = (props: ListInterface<any>) => {

    return <div className="list-container">
        {props.title && <div className="title">{props.title}</div>}
        <div className="list">
            {(props.items || []).map((item, index) => <ListItem key={index} {...item}/>)}
        </div>
    </div>;
};
