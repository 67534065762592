import {BehaviorSubject, Observable} from 'rxjs';
import {LinesApi, SharedServicesApi} from '../api';
import {TRANSPORT_GROUP_ID, TRANSPORT_GROUP_KEY_MAP} from '../constants';
import {Itinerary, RealTime, ServiceModel, SharedServiceStatus, StopModel} from '../models';
import {Db} from './db';

export interface RealTimes {
    [stopId: string]: { realTimes: { [routeId: string]: RealTime[] }, timeTables: { [routeId: string]: Itinerary[] }, status: SharedServiceStatus | null };
}

export class RealTimeWorker {

    private _currentStop: StopModel | null = null;
    private _sharedServices: ServiceModel[] = [];
    private _closeStops: StopModel[] = [];

    private publicServicesTimeout?: NodeJS.Timeout;
    private sharedServicesTimeout?: NodeJS.Timeout;
    private closeStopsTimeout?: NodeJS.Timeout;

    private realTimes: BehaviorSubject<RealTimes>;
    public realTimes$: Observable<RealTimes>;

    constructor(private db: Db) {

        this.realTimes = new BehaviorSubject<RealTimes>({});
        this.realTimes$ = this.realTimes.asObservable();
    }

    set currentStop(stop: StopModel | null) {
        if (this.publicServicesTimeout) clearTimeout(this.publicServicesTimeout);
        this._currentStop = stop;

        if (this._currentStop) this.getPublicServicesRealTimes();
    }

    set sharedServices(services: ServiceModel[]) {
        if (this.sharedServicesTimeout) clearTimeout(this.sharedServicesTimeout);
        this._sharedServices = services;
        if (this._sharedServices.length) this.getSharedServicesRealTimes();
    }

    set closeStops(stops: StopModel[]) {
        if (this.closeStopsTimeout) clearTimeout(this.closeStopsTimeout);
        this._closeStops = stops;
        if (this._closeStops.length) this.getCloseStopsRealTimes();
    }

    private async getPublicServiceStopRealTimes(stop: StopModel) {
        if (this.db.dataSources && TRANSPORT_GROUP_KEY_MAP[TRANSPORT_GROUP_ID.PUBLIC]?.slugs.includes(stop.slug)) {
            const [realTimes, timetables, routes] = await Promise.all([
                LinesApi.getStopRealTimes(stop),
                LinesApi.getStopTimeTable(stop),
                this.db.dataSources.routes.findByStop(stop),
            ]);

            const data: RealTimes = {
                [stop.id]: {
                    realTimes: {},
                    timeTables: {},
                    status: null,
                },
            };

            routes.forEach((route) => {
                const routeRealTimes = realTimes.filter((realTime) => realTime.lineCode === route.shortName);
                const routeTimeTables = timetables.find((timeTable) => timeTable.route_id === route.routeId)?.itineraries_timetables || [];

                data[stop.id].realTimes = {...data[stop.id].realTimes, [route.id]: routeRealTimes};
                data[stop.id].timeTables = {...data[stop.id].timeTables, [route.id]: routeTimeTables};
            });

            this.realTimes.next({...this.realTimes.value, ...data});
        }
    }

    private async getPublicServicesRealTimes() {

        if (this._currentStop) await this.getPublicServiceStopRealTimes(this._currentStop);

        this.publicServicesTimeout = setTimeout(this.getPublicServicesRealTimes.bind(this), 10000);
    }

    private async getSharedServicesRealTimes() {

        const realTimes: RealTimes = {};

        for (const service of this._sharedServices) {
            const data = await SharedServicesApi.getServiceStatus(service);

            data.forEach((status) => {
                realTimes[status.id] = {
                    realTimes: {},
                    timeTables: {},
                    status: status,
                };
            });
        }

        this.realTimes.next({...this.realTimes.value, ...realTimes});

        this.sharedServicesTimeout = setTimeout(this.getSharedServicesRealTimes.bind(this), 60000);
    }

    private async getCloseStopsRealTimes() {

        for (const stop of this._closeStops) {
            await this.getPublicServiceStopRealTimes(stop);
        }

        this.closeStopsTimeout = setTimeout(this.getCloseStopsRealTimes.bind(this), 10000);
    }
}
