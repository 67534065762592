import {RxCollection} from 'rxdb';
import {ServiceModel} from '../../models';
import {DataSource} from './data-source';

export class ServiceDataSource extends DataSource<ServiceModel> {

    constructor(collection: RxCollection) {
        super(collection);
    }
}
