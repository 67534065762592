import dataLinks from './links-contact.json';

export const contactOptions = [
    {title: 'translation:misc_contact_appointment_item_title', link: dataLinks.userOptionLinks.linkCitaPrevia, icon: 'external-link'},
    {title: 'translation:misc_contact_official_form_item_title', link: dataLinks.userOptionLinks.linkFormulari, icon: 'external-link', type: 'form'},
];

export const socialMediaOptions = [
    {mediaIcon: 'twitter', title: '@Mobilitat_AMB', link: dataLinks.socialMediaLinks.twitter, icon: 'external-link'},
    {mediaIcon: 'facebook', title: '@MobilitatAMB', link: dataLinks.socialMediaLinks.facebook, icon: 'external-link'},
    {mediaIcon: 'instagram', title: '@ambmobilitat', link: dataLinks.socialMediaLinks.instagram, icon: 'external-link'},
];
