import React, {useCallback, useContext, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {LINES} from '../../core/constants';
import {RouteModel, ServiceModel} from '../../core/models';
import {DataContext} from '../../services/data-v2/data.context';
import {SpinnerContext} from '../../services/spinner/spinner.context';
import {translate} from '../../services/translation/translationUtils';
import {AmbIcon} from '../amb-icon/amb-icon.component';
import {List} from '../list/list.component';
import {ListItemInterface} from '../list/list.interface';
import {NavOptionsComponent} from '../nav-options/nav-options.component';
import SearchBar from '../search-bar/search-bar';
import './lines.scss';

export const Lines = () => {

    const {db, ready, services} = useContext(DataContext);
    const {activeSpinner, deactivateSpinner} = useContext(SpinnerContext);

    const placeHolder = 'translation:web_search_line_placeholder';

    const [routes, setRoutes] = useState<{ [slug: string]: ListItemInterface<RouteModel>[] }>({});
    const [serviceCatalog, setServiceCatalog] = useState<ServiceModel[]>([]);

    const [selectedTab, setSelectedTab] = useState(LINES[0]);
    const [searchText, setSearchText] = useState('');

    const getRoutes = useCallback(async () => {

        activeSpinner();

        if (services?.length) {

            setServiceCatalog(services);

            const data: { [slug: string]: ListItemInterface<RouteModel>[] } = {};

            for (const service of services.filter((service) => service.groupSlug === selectedTab.slug)) {
                const routes = await db?.dataSources?.routes.find({
                    selector: {
                        $and: [
                            {
                                slug: service.slug,
                            },
                            {
                                $or: [
                                    {longName: {$regex: new RegExp(`.*${searchText}.*`, 'i')}},
                                    {shortName: {$regex: new RegExp(`.*${searchText}.*`, 'i')}},
                                ],
                            },
                        ],
                    },
                    sort: [{shortName: 'asc'}],
                }) || [];

                data[service.slug] = routes
                    .map((route) => ({
                        id: route.id,
                        title: route.longName,
                        href: `/lines/${route.id}`,
                        icon: <AmbIcon
                            text={route.shortName}
                            color={route.textColor}
                            backgroundColor={route.color}
                            shape={route.slug === 'busamb' ? 'round' : 'square'}
                            size="m"/>,
                        data: route,
                    }));
            }

            setRoutes(data);
        }

        deactivateSpinner();
    }, [db, services, selectedTab, searchText]);

    useEffect(() => {
        if (ready) {
            getRoutes();
        }
    }, [ready, services, selectedTab, searchText]);

    return <div className={`lines-container${isMobile ? ' mobile' : ''}`}>
        <SearchBar
            iconSearch={true}
            backIcon={false}
            value={{
                placeHolder,
                onChangeSearch: setSearchText,
            }}/>
        <div className="tabs">
            <NavOptionsComponent value={{
                tabOptions: LINES,
                changeOption: setSelectedTab,
                selectedOption: selectedTab,
            }}/>
        </div>
        <div className="bottom-line"/>
        <div className="lines">
            {
                serviceCatalog
                    .filter((service) => routes[service.slug] && routes[service.slug].length)
                    .map((service: ServiceModel, index: number) => <List
                        key={index}
                        title={translate(service.name)}
                        items={routes[service.slug]}
                    />)
            }
        </div>
    </div>;
};
