import React, {createElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {navOptions} from './home-nav.constants';

import './home-nav.scss';

interface HomeDesktopNavInterface {
    selectedOption: number,
    changeOption?: any,
};

export function HomeNavComponent(props: HomeDesktopNavInterface) {
    const {t} = useTranslation();

    return <div className="nav-desktop-option-group">
        {navOptions.map((option: any, index: number) => <Link
            className={`nav-desktop-option ${option.id === props.selectedOption ? 'selected' : ''}`}
            key={index}
            to={option.href}>
            {/* <div*/}
            {/*    role="button"*/}
            {/*    tabIndex={0}*/}
            {/*    onKeyDown={(e) => props.changeOption(option)}*/}
            {/*    // onClick={(e) => props.changeOption(option)}*/}
            {/*    className={(option.id == props.selectedOption) ?*/}
            {/*        'nav-desktop-option-selected' :*/}
            {/*        'nav-desktop-option'*/}
            {/*    }>*/}
            {createElement(option.icon, {className: 'icon-desktop'})}
            <h6 className="nav-title">{t(option.translate)}</h6>
            {/* </div>*/}
        </Link>)}
    </div>;
}
