import {ReactComponent as AlertsIcon} from '../../assets/icons/menu/alertas.svg';
import {ReactComponent as CommentsIcon} from '../../assets/icons/menu/comentario.svg';
import {ReactComponent as BuyIcon} from '../../assets/icons/menu/comprar.svg';
import {ReactComponent as LinesIcon} from '../../assets/icons/menu/lineas.svg';
import {ReactComponent as AppIcon} from '../../assets/icons/menu/menu-amb-app.svg';
import {ReactComponent as MapsIcon} from '../../assets/icons/menu/planos.svg';
import {ReactComponent as ServicesIcon} from '../../assets/icons/menu/servicios.svg';
import {ReactComponent as SettingsIcon} from '../../assets/icons/menu/settings.svg';
import {ReactComponent as RatesIcon} from '../../assets/icons/menu/tarifas.svg';
import './menu.scss';

export interface MenuSectionInterface {
    title: string,
    items: MenuSectionItemInterface[]
}

export interface MenuSectionItemInterface {
    content: string,
    to: string,
    icon?: any,
    external?: boolean,
    ga?: string
}

export const sections: MenuSectionInterface[] = [
    {
        title: 'translation:app_menu_services_information_title',
        items: [
            {
                content: 'translation:app_menu_alerts_and_incidences_title',
                to: '/alerts',
                icon: AlertsIcon,
            },
            {
                content: 'translation:app_menu_transport_lines_title',
                to: '/lines',
                icon: LinesIcon,
            },
            {
                content: 'translation:app_menu_service_plans_title',
                to: 'https://www.ambmobilitat.cat/principales/PlanosMunicipios.aspx',
                icon: MapsIcon,
                external: true,
                ga: 'menu_scheme_click',
            },
            {
                content: 'translation:app_menu_service_operators_title',
                to: '/services',
                icon: ServicesIcon,
            },
            {
                content: 'translation:app_menu_rates_title',
                to: 'http://www.ambmobilitat.cat/principales/Tarifas.aspx',
                icon: RatesIcon,
                external: true,
                ga: 'menu_rates_click',
            },
            {
                content: 'translation:app_menu_buy_tickets_title',
                to: ' https://tickets.tmb.cat',
                icon: BuyIcon,
                external: true,
            },
        ],
    },
    {
        title: 'translation:app_name',
        items: [
            {
                content: 'translation:app_menu_settings_title',
                to: '/settings',
                icon: SettingsIcon,
            },
            {
                content: 'translation:app_menu_contact_amb_title',
                to: '/contact',
                icon: CommentsIcon,
            },
            {
                content: 'menu:menu_open_app',
                to: '/home',
                icon: AppIcon,
            },
        ],
    },
];

