import React from 'react';
import {RouteModel} from '../../core/models';

import './itinerary-selector.scss';

export const ItinerarySelectorComponent = (
    {
        route,
        itineraries,
        selected,
        onChange = () => {
        },
    }: {
        route?: RouteModel,
        itineraries: { name: string, id: number }[],
        selected?: number,
        onChange: (id: number) => any,
    },
) => {

    return <>
        {itineraries.length > 2 ?
            <select
                onBlur={(e) => {
                }}
                onChange={(e) => {
                    onChange(parseInt(e.target.value));
                }}
            >
                {itineraries.map((option, index) => {
                    return <option key={index} value={option.id}>{option.name}</option>;
                })}
            </select> :
            (itineraries.length > 1) ?
                <div className="nav-mobile-option-group">
                    {
                        itineraries.map((itinerary, key) => <button
                            key={key}
                            onClick={() => onChange(itinerary.id)}
                            className={itinerary.id === selected ? 'active' : ''}
                            style={{'backgroundColor': itinerary.id === selected && route ? route.color : 'var(--gris-linies-amb)'}}
                        >
                            {itinerary.name}
                        </button>)
                    }
                </div> : null
        }
    </>;
};
