import {createContext} from 'react';

export interface DskPageCtx {
    visible: boolean;
    toggleVisible: () => void;
    show?: () => void;
    hide: () => void;
}

export const DskPageContext = createContext<DskPageCtx>({
    visible: true, toggleVisible: () => {
    }, hide: () => {
    },
});

export const DskPageProvider = DskPageContext.Provider;
