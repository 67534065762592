import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {analyticsEvent} from '../../utils/analytics';

function UserComponent(props: any) {
    const {t} = useTranslation();
    const handleClick = () => {
        if (props.type === 'form') {
            analyticsEvent('comments', 'comments_officialformlink_click');
        }
    };
    return (<div className="section-container">
            <a href={props.link}
               target="_blank"
               rel="noopener noreferrer"
               onClick={handleClick}
               onKeyDown={handleClick}
               tabIndex={0}
            >
                <div className="section-box">
                    <h4>
                        {t(props.title)}
                    </h4>
                </div>
                <span className={props.icon}>
      </span>
            </a>
        </div>
    );
}

export default UserComponent;
