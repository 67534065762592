import {LinesResponse, RealTime, RealTimesResponse, Route, RouteModel, Shape, Stop, StopModel, TimeTableResponse} from '../models';
import {toHex} from '../utils';
import {get} from '../utils/fetch.utils';

export class LinesApi {

    public static async getLines(date?: string): Promise<{ routes: RouteModel[], stops: StopModel[] }> {

        try {
            const data = await get<LinesResponse>(`/gtfs/routes-and-stops${date ? `/${date}` : ''}`);

            return Object.entries(data)
                .reduce((output: { routes: RouteModel[], stops: StopModel[] }, [slug, group]) => {

                    return {
                        routes: [
                            ...output.routes,
                            ...group.routes.map((route) => this.mapRoute(route, slug)),
                        ],
                        stops: [
                            ...output.stops,
                            ...group.stops.map((stop) => this.mapStop(stop, slug)),
                        ],
                    };
                }, {
                    routes: [],
                    stops: [],
                });
        } catch (e) {
            return {
                routes: [],
                stops: [],
            };
        }
    }

    public static async getStopRealTimes(stop: StopModel): Promise<RealTime[]> {

        const path = stop.slug === 'busamb' ? `/bus/stops/${stop.stopCode}/realtimes` : `/gtfs/${stop.slug}/realtime/${stop.stopId}`;

        const response = await get<RealTimesResponse>(path);
        return response.times?.map((time) => ({...time, responseId: response.id})) || [];
    }

    public static async getStopTimeTable(stop: StopModel): Promise<TimeTableResponse[]> {

        try {
            return (await get<TimeTableResponse[]>(`/gtfs/${stop.slug}/stops/${stop.stopId}/timetable`));
        } catch (e) {
            return [];
        }
    }

    public static async getRouteShapes(slug: string, shapeId: string): Promise<Shape[]> {

        return await get<Shape[]>(`/gtfs/${slug}/shapes/${shapeId}`);
    }

    public static mapRoute(data: Route, slug: string): RouteModel {
        return {
            id: `${slug}:${data.route.route_id}`,
            slug: slug,
            longName: data.route.route_long_name,
            shortName: data.route.route_short_name,
            textColor: toHex(data.route.route_text_color),
            type: data.route.route_type,
            color: toHex(data.route.route_color),
            routeId: data.route.route_id,
            iconShape: data.route.route_icon_shape,
            url: data.route.route_url,
            desc: data.route.route_desc,
            agencyId: data.route.agency_id,
            services: data.services.map((s) => ({
                todayService: s.todayService,
                timetable: s.timetable,
                serviceId: s.service_id,
                description: s.description,
                timetableDescription: s.timetableDescription,
            })),
            tripsPaths: data.tripsPaths.map((tp) => ({
                tripIds: tp.trip_ids,
                stopIds: tp.stop_ids,
                tripHeadsign: tp.trip_headsign,
                directionId: tp.direction_id,
                shapeId: tp.shape_id,
            })),
        };
    }

    public static mapStop(data: Stop, slug: string): StopModel {
        return {
            id: `${slug}:${data.stop_id}`,
            slug: slug,
            routesIds: data.routes_ids,
            stopName: data.stop_name,
            stopDesc: data.stop_desc,
            stopCode: data.stop_code,
            stopUrl: data.stop_url,
            stopType: data.stop_type,
            parentStation: data.parent_station || '',
            routesTypes: data.routes_types,
            wheelchairBoarding: !!data.wheelchair_boarding,
            stopId: data.stop_id,
            lat: data.stop_lat,
            lng: data.stop_lon,
        };
    };
}
