export async function get<T>(path: string): Promise<T> {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}${path}`,
            {
                method: 'GET',
                mode: 'cors',
                headers: getHeaders(),
            },
        );
        if (!response.ok) throw new Error(response.statusText);
        return await response.json() as T;
    } catch (e) {
        throw new Error(e.message);
    }
};

export async function getWithoutRoot<T>(path: string): Promise<T> {
    try {
        const response = await fetch(
            path,
            {
                method: 'GET',
                mode: 'cors',
                headers: getHeaders(),
            },
        );
        if (!response.ok) throw new Error(response.statusText);
        return await response.json() as T;
    } catch (e) {
        throw new Error(e.message);
    }
};

const getHeaders = () => {
    const headers = new Headers();
    headers.append('x-api-key', process.env.REACT_APP_API_Key || '');
    return headers;
};
