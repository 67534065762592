import React, {createElement, useCallback, useContext} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ReactComponent as BackIcon} from '../../assets/icons/buttons/back.svg';
import {ReactComponent as CloseIcon} from '../../assets/icons/buttons/system-close.svg';
import {ReactComponent as pointerIcon} from '../../assets/icons/logo-public-place.svg';
import {ReactComponent as otrosIcon} from '../../assets/icons/transports/otros.svg';
import {ReactComponent as biciIcon} from '../../assets/icons/transports/transporte-bici.svg';
import {ReactComponent as busIcon} from '../../assets/icons/transports/transporte-bus.svg';
import {ReactComponent as cocheIcon} from '../../assets/icons/transports/transporte-coche.svg';
import {ReactComponent as metroIcon} from '../../assets/icons/transports/transporte-metro.svg';
import {ReactComponent as motoIcon} from '../../assets/icons/transports/transporte-moto.svg';
import {ReactComponent as patineteIcon} from '../../assets/icons/transports/transporte-patinete.svg';
import {ReactComponent as taxiIcon} from '../../assets/icons/transports/transporte-taxi.svg';
import {ReactComponent as tramIcon} from '../../assets/icons/transports/transporte-tram.svg';
import {ReactComponent as trenIcon} from '../../assets/icons/transports/transporte-tren.svg';
import {ReactComponent as LogoAMB} from '../../assets/logo-amb-positivo.svg';
import SidebarButton from '../../components/buttons/sidebar/sidebar-button';
import IconGenerator from '../../components/icon-generator/icon-generator';
import {AlertsFilterContext} from '../../services/alerts-filters/alerts-filters.context';
import {HeaderContext} from '../../services/header/header.context';
import './header.scss';

export function Header() {
    const apiLogo = process.env.REACT_APP_MAP_API_LOGO;
    const history = useHistory();
    const {headerConfig} = useContext(HeaderContext);
    const filterService = useContext(AlertsFilterContext);
    const {t} = useTranslation();

    const menuMobile = (isMobile) ? true : false;
    const logo = (isMobile) ? true : false;

    const goBack = useCallback(() => {
        headerConfig.backPath ? history.push(headerConfig.backPath) : history.goBack();
    }, [headerConfig.backPath]);

    const filterAction = () => {
        filterService.filtersTransports();
        filterService.filtersWarnings();
    };

    const onClickLogo = () => {
        history.push('/home');
    };
    const goToPrevius = useCallback(() => {
        headerConfig.backPath ? history.push(headerConfig.backPath) : history.goBack();
    }, [headerConfig.backPath]);

    let style = {};
    if (!!apiLogo) {
        style = {
            'backgroundColor': headerConfig?.iconBackgroundWhite,
        } as React.CSSProperties;
    }
    if (headerConfig?.shape == true) {
        style = {
            'backgroundColor': headerConfig?.iconBackgroundWhite,
            'borderRadius': '50%',
        } as React.CSSProperties;
    }
    return <>
        <div
            className={`header ${!isMobile ? 'desktop' : ''}
     ${headerConfig?.customClassName ?
                'grey' : 'white'}`
            }
        >
            {!!headerConfig?.backIcon ?
                <button className="back-button" onClick={goBack}>
                    <BackIcon/>
                </button> :
                ''
            }
            {!!headerConfig?.backToPrevius ?
                <button className="back-button" onClick={goToPrevius}>
                    <BackIcon/>
                </button> :
                ''
            }
            {!!headerConfig?.pointerIcon ?
                <div className="pointer-icon">{createElement(pointerIcon)}</div> :
                ''
            }
            {!!headerConfig?.motoIcon ?
                <div className="pointer-icon">{createElement(motoIcon)}</div> :
                ''
            }
            {!!headerConfig?.busIcon ?
                <div className="pointer-icon">{createElement(busIcon)}</div> :
                ''
            }
            {!!headerConfig?.cocheIcon ?
                <div className="pointer-icon">{createElement(cocheIcon)}</div> :
                ''
            }
            {!!headerConfig?.metroIcon ?
                <div className="pointer-icon">{createElement(metroIcon)}</div> :
                ''
            }
            {!!headerConfig?.patineteIcon ?
                <div className="pointer-icon">{createElement(patineteIcon)}</div> :
                ''
            }
            {!!headerConfig?.taxiIcon ?
                <div className="pointer-icon">{createElement(taxiIcon)}</div> :
                ''
            }
            {!!headerConfig?.tramIcon ?
                <div className="pointer-icon">{createElement(tramIcon)}</div> :
                ''
            }
            {!!headerConfig?.trenIcon ?
                <div className="pointer-icon">{createElement(trenIcon)}</div> :
                ''
            }
            {!!headerConfig?.biciIcon ?
                <div className="pointer-icon">{createElement(biciIcon)}</div> :
                ''
            }
            {!!headerConfig?.otrosIcon ?
                <div className="pointer-icon">{createElement(otrosIcon)}</div> :
                ''
            }

            {!!headerConfig?.svgIcon ?
                <div className="pointer-icon">{createElement(headerConfig.svgIcon)}</div> :
                ''
            }

            {!!headerConfig.iconID ?
                <img src={`${apiLogo}${headerConfig.iconID}`} alt="icon" style={style}/> : ''
            }

            {
                headerConfig.icon && <img src={headerConfig.icon} alt="icon" style={style}/>
            }

            {!!headerConfig.lineIcon ?
                <div className="icon">
                    <IconGenerator
                        routeShortName={headerConfig.lineIcon.routeShortName}
                        routeType={headerConfig.lineIcon.routeType}
                        routeTextColor={headerConfig.lineIcon.routeTextColor}
                        routeColor={headerConfig.lineIcon.routeColor}/>
                </div> : null
            }

            {
                !!headerConfig.iconComponent && <div className="icon">{headerConfig.iconComponent}</div>
            }

            {
                !!headerConfig.logo && <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img src={headerConfig.logo} alt="icon" style={{height: '23px', width: 'auto', margin: 0, padding: 0}}/>
                </div>
            }

            {headerConfig?.menu || menuMobile ?
                <SidebarButton/> :
                ''
            }
            <div className="text">
                {headerConfig?.title ?
                    <span>{t(headerConfig?.title)}</span> :
                    ''
                }
            </div>
            {!!headerConfig?.closeIcon ?
                <CloseIcon onClick={goBack}/> :
                ''
            }
            {!!headerConfig?.logoIcon || logo ?
                <LogoAMB
                    onClick={onClickLogo}
                    className="logo-amb"
                /> :
                ''
            }

            {!!headerConfig?.action ?
                <button className="back-button" onClick={filterAction}>
                    {t('filter')}
                </button> :
                ''
            }
        </div>
    </>;
}

