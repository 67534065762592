import React from 'react';
import {useTranslation} from 'react-i18next';

interface radioProps {
    changeFunc: any;
    checkedOption: string;
    checked: string;
    label: string;
    value: any;
};

export function RadioButton(props: radioProps) {
    const changeFunc = props.changeFunc;
    const checkedOption = props.checkedOption;
    const checked = props.checked;
    const label = props.label;
    const value = props.value;
    const {t} = useTranslation();

    const clickAction = (e: any) => {
        e.preventDefault();
        changeFunc(value);
    };


    return <div
        role="button"
        tabIndex={0}
        className="radio-item"
        onClick={clickAction}
        onKeyDown={clickAction}
    >
        <input
            id="input-radio"
            type="radio"
            onChange={(e) => {
            }}
            value={value}
            checked={checkedOption === checked}
        />
        <label htmlFor="input-radio">{t(label)}</label>
    </div>;
}
