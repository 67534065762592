import React, {createElement, KeyboardEvent, useContext} from 'react';
import {isMobile} from 'react-device-detect';
import {ReactComponent as SearchIcon} from '../../../assets/icons/map/map-parada-secundario.svg';

import {BottomSheetCtx} from '../../../services/bottom-sheet/bottom-sheet-context';
import {analyticsEvent} from '../../../utils/analytics';
import './search-button.scss';

function SearchButtonComponent() {
    const sheetService = useContext(BottomSheetCtx);

    let style = {};
    if (!isMobile) {
        style = {
            'display': 'none',
        } as React.CSSProperties;
    } else {
        style = {
            'top': '75px',
        } as React.CSSProperties;
    }
    const handleClick = () => {
        analyticsEvent('Home', 'home_stationsearch_click');
        if (isMobile) {
            sheetService.setMaximized(true);
        }
    };

    const handleKeyDown = (ev: KeyboardEvent) => {
        if (ev.key === 'Enter') {
            handleClick();
        }
    };

    return <span
        style={style}
        role="button"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        className="search-button"
        id="searchButton">
    {createElement(SearchIcon)}
  </span>;
}

export default SearchButtonComponent;
