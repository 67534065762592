import {createContext} from 'react';

export interface SidebarCtx {
    visible: boolean;
    toggleVisible: () => void;
    show?: () => void;
    hide: () => void;
}

export const SidebarContext = createContext<SidebarCtx>({
    visible: false, toggleVisible: () => {
    }, hide: () => {
    },
});

export const SidebarProvider = SidebarContext.Provider;
