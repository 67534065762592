import React, {createElement} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ReactComponent as alert} from '../../assets/icons/alerts/system-alerta-general.svg';
import {ReactComponent as arrow} from '../../assets/icons/buttons/systemArrowRight.svg';
import {ReactComponent as accessibility} from '../../assets/icons/lines/system-accesibilitat.svg';
import {ReactComponent as marquesina} from '../../assets/stop/transporte-parada-bus-marquesina.svg';
import {ReactComponent as pal} from '../../assets/stop/transporte-parada-bus-poste.svg';
import IconGenerator from '../../components/icon-generator/icon-generator';
import {analyticsEvent} from '../../utils/analytics';
import {subtTransportTypes} from '../../utils/utils';
import './map-pin-info.scss';

export default function MapPinContent(props: any) {
    const {t} = useTranslation();
    const apiLogo = process.env.REACT_APP_MAP_API_LOGO || '';
    const getTypeOfTransport = (transportSlugTypes: any) => {
        switch (transportSlugTypes) {
        case 'busamb':
            return t('transport_bottom_sheet_services_bus');
        case 'metro':
            return t('transport_bottom_sheet_services_subway');
        case 'tren':
            return t('transport_bottom_sheet_services_train');
        case 'tram':
        case 'trambesos':
        case 'trambaix':
            return t('transport_bottom_sheet_services_tram');
        case 'otros':
            return t('transport_bottom_sheet_services_others');
        case 'bici':
            return t('transport_bottom_sheet_services_bike');
        case 'moto':
            return t('transport_bottom_sheet_services_motorcycle');
        case 'patinete':
            return t('transport_bottom_sheet_services_patinet');
        case 'coche':
            return t('transport_bottom_sheet_services_car');
        case 'taxi':
            return t('transport_bottom_sheet_services_taxi');
        case 'renfe':
            return t('transport_bottom_sheet_services_train');
        case 'fgc':
            return t('transport_bottom_sheet_services_train');
        }
    };

    const handleAlertClick = () => {
        analyticsEvent('Detall línia', 'linedetail_alteration_click');
    };

    let style = {};
    if (!!apiLogo) {
        style = {
            'backgroundColor': props.icon?.color,
        } as React.CSSProperties;
    }
    if (!!apiLogo && props.slug == subtTransportTypes.bus) {
        style = {
            'backgroundColor': props.icon?.color,
            'padding': '4px',
            'width': '20px',
            'height': '20px',
        } as React.CSSProperties;
    }
    if (!!apiLogo && props.slug == subtTransportTypes.metro) {
        style = {
            'backgroundColor': props.icon?.color,
            'padding': '5px',
            'width': '18px',
            'height': '18px',
        } as React.CSSProperties;
    }
    if (!!apiLogo && props.slug == subtTransportTypes.fgc) {
        style = {
            'backgroundColor': props.icon?.color,
            'padding': '3px',
            'width': '22px',
            'height': '22px',
        } as React.CSSProperties;
    }
    return (<>
        {
            isMobile ? '' : props.title.length <= 23 ?
                '' : <div className="title-overflow">
                    {props.title}
                </div>
        }
        {
            (props.pinType === 'line') ?
                <div className="pin-container pin-container-dsk">
                    <div className="mobile-line-info">
                        <div className="icon">
                            {
                                (props.icon) ?
                                    <IconGenerator
                                        routeShortName={props.icon.routeShortName}
                                        routeType={props.icon.routeType}
                                        routeTextColor={props.icon.routeTextColor}
                                        routeColor={props.icon.routeColor}/> : null
                            }
                        </div>
                        <div className="title">
                            {props.title}
                        </div>
                    </div>
                    <div className="pin-content pin-content-dsk">
          <span className="description">
            {t('translation:web_timeline_line_description')} {props.description}
          </span>
                        <div className="alert">
                            {
                                props.hasAlert ?
                                    <div
                                        className="warning-alert"
                                        onClick={handleAlertClick}
                                        onKeyDown={handleAlertClick}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <Link to={`/alerts/lines/${props.alertId}`}>
                  <span className="alert-icon">
                    {createElement(alert)}
                      <p>{t('translation:transport_line_detail_new_alerts')}</p>
                  </span>
                                            <span className="alert-arrow">{createElement(arrow)}</span>
                                        </Link>
                                    </div> : ''
                            }
                        </div>
                    </div>
                </div> :
                <div className={isMobile ? 'pin-container pin-container-mbl' : 'pin-container pin-container-dsk'}>
                    {isMobile ?
                        <div className="pin-title">
                            {props.icon}
                            <span className="title">
                      {props.title}
                     </span>
                        </div> : ''}
                    <div className="pin-content pin-content-dsk">
          <span className="description">
            <span>
              {t('translation:public_transport_stop')} {getTypeOfTransport(props?.slug)} </span>
            <span> Nº {props.description}</span>
            <span className="accessibility">
              {props.accessibility ? createElement(accessibility) : ''}
            </span>
          </span>
                        {(!!props.type) ?
                            <div className="type">
                                {props.type == 'marquesina' ? createElement(marquesina) : ''}
                                {props.type == 'pal' ? createElement(pal) : ''}
                                {t('translation:web_public_transport_stop_bus_type')} {props.type}
                            </div> : null
                        }
                    </div>
                </div>
        }
    </>);
}
