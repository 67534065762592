import React from 'react';
import CookieConsent, {getCookieConsentValue} from 'react-cookie-consent';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {BrowserRouter as Router} from 'react-router-dom';
import {DesktopRouting} from '../../routes/browser/desktop-routing';
import {MobileRouting} from '../../routes/mobile/mobile-routing';
import {AlertsFilterService} from '../../services/alerts-filters/alerts-filters.service';
import {DataService} from '../../services/data-v2/data.service';
import {HeaderService} from '../../services/header/header.service';
import {MapService} from '../../services/map-v2/map.service';
import {SidebarService} from '../../services/sidebar/sidebar.service';
import {SpinnerService} from '../../services/spinner/spinner.service';
import {ThemeService} from '../../services/theme/themeService';
import {TranslateService} from '../../services/translation/translation.service';
import {Map} from '../map/map.component';
import {Menu} from '../menu/menu';
import {SidebarComponent} from '../sidebar/sidebar.component';
import './app.scss';

function App() {
    const {t} = useTranslation();
    const cookiesAccepted = getCookieConsentValue();

    return (
        <Router>
            <SpinnerService>
                <DataService>
                    <TranslateService>
                        <ThemeService>
                            <SidebarService>
                                <AlertsFilterService>
                                    <HeaderService>
                                        <MapService>
                                            {(!cookiesAccepted) ?
                                                <CookieConsent
                                                    enableDeclineButton
                                                    location="bottom"
                                                    buttonText={t('cookies_accept')}
                                                    declineButtonText={t('cookies_reject')}
                                                    cookieName="amb-cookie-analytics"
                                                    cookieValue="true"
                                                    declineCookieValue="false"
                                                    style={{background: '#2B373B'}}
                                                    buttonStyle={{color: '#4e503b', fontSize: '13px'}}
                                                    expires={365}
                                                >
                                                    {t('cookie_descripton')}
                                                </CookieConsent> : null
                                            }
                                            {/* <Router>*/}
                                            <div className={isMobile ? 'app mobile' : 'app desktop'}>
                                                <Map/>
                                                <SidebarComponent>
                                                    <Menu/>
                                                </SidebarComponent>
                                                {
                                                    isMobile ? <MobileRouting/> : <DesktopRouting/>
                                                }
                                            </div>
                                            {/* </Router>*/}
                                        </MapService>
                                    </HeaderService>
                                </AlertsFilterService>
                            </SidebarService>
                        </ThemeService>
                    </TranslateService>
                </DataService>
            </SpinnerService>
        </Router>
    );
}

export default App;
