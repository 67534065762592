import {Service, ServiceGroupModel, ServiceModel, ServiceResponse} from '../models/service.model';
import {fileToBase64, getSvgElement, toHex} from '../utils';
import {get} from '../utils/fetch.utils';

export class ServicesApi {

    public static async getServices(): Promise<{ serviceGroups: ServiceGroupModel[], services: ServiceModel[] }> {

        try {
            const response = await get<{ data: ServiceResponse[] }>(`/appmobcontent/services-catalogue`);

            return response.data.reduce((data: { serviceGroups: ServiceGroupModel[], services: ServiceModel[] }, group: ServiceResponse) => {

                return {
                    serviceGroups: [
                        ...data.serviceGroups,
                        this.mapServiceGroup(group),
                    ],
                    services: [
                        ...data.services,
                        ...(group.services
                            .filter((s) => s.services_id)
                            .map((s) => this.mapService(s.services_id, group.slug))),
                    ],
                };
            }, {
                serviceGroups: [],
                services: [],
            });
        } catch (e) {
            return {
                serviceGroups: [],
                services: [],
            };
        }
    }

    public static async getAsset(id: string): Promise<string> {

        const svg = await getSvgElement(`https://apistg.ambmobilitat.cat/appmobcontent/assets/${id}`);

        if (svg) {

            svg.setAttribute('width', svg.viewBox.baseVal.width.toString());
            svg.setAttribute('height', svg.viewBox.baseVal.height.toString());
            return `data:image/svg+xml;base64,${btoa(new XMLSerializer().serializeToString(svg))}`;
        }

        return fileToBase64(`https://apistg.ambmobilitat.cat/appmobcontent/assets/${id}`);
    }

    public static mapServiceGroup(data: ServiceResponse): ServiceGroupModel {
        return {
            id: String(data.id),
            slug: data.slug,
            parent: data.parent?.id,
            name: data.translations.map((t) => ({code: t.languages_code, text: t.name || ''})),
        };
    }

    public static mapService(data: Service, groupSlug: string): ServiceModel {
        return {
            id: `${groupSlug}:${data.slug}`,
            slug: data.slug,
            groupSlug: groupSlug,
            dataEndPoint: data.data_endpoint,
            dataTypeOptions: data.data_type_options || '',
            dataType: data.data_type,
            name: data.translations.map((t) => ({code: t.languages_code, text: t.name || ''})),
            description: data.translations.map((t) => ({code: t.languages_code, text: t.description || ''})),
            color: toHex(data.color),
            colorDark: toHex(data.color_dark_mode),
            icon: data.icon,
            iconDark: data.icon_dark,
            logo: data.logo,
            logoDark: data.logo_dark,
        };
    }
}
