import {getCookieConsentValue} from 'react-cookie-consent';

export const analyticsEvent = (category: string, label: string, other: string = '') => {
    if (getCookieConsentValue()) {
        const w: any = window;
        if (other != '') {
            w.gtag('event', `${category}`, {'event_label': `${label}`, 'event_params': `${other}`});
        } else {
            w.gtag('event', `${category}`, {'event_label': `${label}`});
        }
    }
};
export const analyticsPageView = (page: string) => {
    if (getCookieConsentValue()) {
        const w: any = window;
        w.gtag('event', `page_view`, {'page_title': `${page}`});
    }
};
