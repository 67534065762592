import React, {useCallback, useContext, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {LinesApi} from '../../core/api';
import {TRANSPORT_GROUP_ID, TRANSPORT_GROUP_KEY_MAP, TRANSPORT_ID, TRANSPORTS_KEY_MAP} from '../../core/constants';
import {RouteModel, ServiceModel, TimeTableResponse} from '../../core/models';
import {findBySlug} from '../../core/utils';
import {DataContext} from '../../services/data-v2/data.context';
import {HeaderContext} from '../../services/header/header.context';
import {MapContext} from '../../services/map-v2/map.context';
import {SpinnerContext} from '../../services/spinner/spinner.context';
import {translate} from '../../services/translation/translationUtils';
import {AmbIcon} from '../amb-icon/amb-icon.component';
import {BikeStopDetailComponent} from '../bike-stop-detail/bike-stop-detail.component';
import {List} from '../list/list.component';
import {ListItemInterface} from '../list/list.interface';
import MapPinInfo from '../map-pin-info/map-pin-info';
import {MotoStopDetailComponent} from '../moto-stop-detail/moto-stop-detail.component';
import {NavOptionsComponent} from '../nav-options/nav-options.component';
import {RealTimesComponent} from '../real-times/real-times.component';
import {ScheduleComponent} from '../schedule/schedule.component';
import {CarStopDetailComponent} from '../car-stop-detail/car-stop-detail.component';

import './stop-detail.scss';

interface TabInterface {
    id: number,
    translate: string,
    visible: boolean,
};

export const TABS: TabInterface[] = [
    {id: 1, translate: 'translation:transport_search_lines_title', visible: true},
    {id: 3, translate: 'translation:transport_line_detail_tab_schedules', visible: true},
    {id: 4, translate: 'translation:web_nav_access', visible: false},
];

export default function StopDetailComponent({buttons}: { buttons: string[] }) {

    const {stopId} = useParams<any>();
    const {t} = useTranslation();

    const {ready, db, pinSet, services, getCurrentStop, currentStop} = useContext(DataContext);
    const {initHeader} = useContext(HeaderContext);
    const {map} = useContext(MapContext);
    const {activeSpinner, deactivateSpinner} = useContext(SpinnerContext);

    const [selectedTab, setSelectedTab] = useState<TabInterface>(TABS[0]);
    const [routes, setRoutes] = useState<ListItemInterface<RouteModel>[]>([]);
    const [timeTables, setTimeTables] = useState<TimeTableResponse[]>([]);
    const [distance, setDistance] = useState<number>();
    const [service, setService] = useState<ServiceModel | undefined>(findBySlug(services, currentStop?.slug));
    const [isSharedService, setIsSharedService] = useState<boolean>(false);

    const getRoutes = useCallback(async () => {

        activeSpinner();

        const isSharedService = !TRANSPORT_GROUP_KEY_MAP[TRANSPORT_GROUP_ID.PUBLIC]?.slugs.includes(currentStop?.slug || '');

        if (!isSharedService && currentStop) {
            const routes = (await db?.dataSources?.routes.findByStop(currentStop) || [])
                .map((route) => ({
                    id: route.id,
                    title: route.longName,
                    subtitle: <RealTimesComponent stop={currentStop} route={route}/>,
                    href: `/lines/${route.id}/stop/${currentStop.id}`,
                    icon: <AmbIcon
                        text={route.shortName}
                        color={route.textColor}
                        backgroundColor={route.color}
                        shape={route.slug === 'busamb' ? 'round' : 'square'}
                        size="m"/>,
                    data: route,
                }));

            setRoutes(routes);

            LinesApi.getStopTimeTable(currentStop)
                .then(setTimeTables);
        }

        setIsSharedService(isSharedService);

        deactivateSpinner();

    }, [db, currentStop]);

    const setHeader = useCallback(() => {
        let headerConfig = {};
        if (!isMobile && currentStop && pinSet) {
            headerConfig = {
                title: (isSharedService ? translate(service?.name) : currentStop.stopName) || '',
                backIcon: true,
                backToPrevius: false,
                closeIcon: false,
                logoIcon: false,
                icon: pinSet[currentStop.slug].icon,
                iconBackgroundWhite: pinSet[currentStop.slug].color || '',
                customClassName: {
                    'backgroundColor': 'var(--gris-fons-amb)',
                },
                backPath: '/home',
            };
            initHeader(headerConfig);
        }
    }, [currentStop, pinSet, isSharedService, service]);

    useEffect(() => {
        if (ready) getCurrentStop(stopId);
        return () => {
            getCurrentStop();
        };
    }, [stopId, ready]);

    useEffect(() => {
        if (currentStop) {
            setSelectedTab(TABS[0]);
            getRoutes();
        } else {
            setRoutes([]);
        }
    }, [currentStop]);

    useEffect(() => {
        setService(findBySlug(services, currentStop?.slug));
    }, [currentStop, services]);

    useEffect(() => {
        if (currentStop && service) {
            setHeader();
        }
    }, [currentStop, service]);

    return <div className={`stop-detail-container${isMobile ? ' mobile' : ''}`}>
        {
            currentStop && pinSet && !isSharedService && <MapPinInfo
                icon={<AmbIcon
                    img={pinSet[currentStop.slug].icon}
                    backgroundColor={pinSet[currentStop.slug].color}
                    size="s"/>}
                title={currentStop.stopName}
                description={currentStop.stopCode}
                accessibility={currentStop.wheelchairBoarding}
                distance={distance}
                type={currentStop.stopType}
                slug={currentStop.slug}
                coord={{lat: currentStop.lat, lng: currentStop.lng}}
                buttons={buttons}
            />
        }

        {
            currentStop && !isSharedService && <>
                <div className="pin_nav-tabs" style={{
                    ...(isMobile && {'padding': '0 20px'}),
                }}>
                    <NavOptionsComponent value={{
                        tabOptions: TABS,
                        changeOption: setSelectedTab,
                        selectedOption: selectedTab,
                    }}/>
                </div>

                <div className="route-list-container" style={{
                    ...(isMobile && {'padding': '0 20px'}),
                }}>
                    {
                        selectedTab.id == 1 && !!routes.length &&
                        <List items={routes}/>
                    }

                    {
                        selectedTab.id == 3 &&
                        <ScheduleComponent
                            routes={routes}
                            timeTables={timeTables}
                        />
                    }
                </div>
            </>
        }

        {
            currentStop && isSharedService &&
            <>

                {
                    TRANSPORTS_KEY_MAP[TRANSPORT_ID.BIKE]?.slugs.includes(currentStop.slug) && <BikeStopDetailComponent stop={currentStop}/>
                }

                {
                    [
                        ...(TRANSPORTS_KEY_MAP[TRANSPORT_ID.CAR]?.slugs || []),
                        ...(TRANSPORTS_KEY_MAP[TRANSPORT_ID.TAXI]?.slugs || []),
                    ].includes(currentStop.slug) &&
                    <CarStopDetailComponent stop={currentStop}/>
                }

                {
                    TRANSPORTS_KEY_MAP[TRANSPORT_ID.MOTO]?.slugs.includes(currentStop.slug) && <MotoStopDetailComponent stop={currentStop}/>
                }
            </>
        }
    </div>;
};
