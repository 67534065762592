import React, {createRef, LegacyRef, useCallback, useContext, useEffect} from 'react';
import {MapContext} from '../../services/map-v2/map.context';

import './map.scss';

export const Map = () => {

    const {ready, init} = useContext(MapContext);

    const elementRef: LegacyRef<HTMLDivElement> | undefined = createRef();

    const initService = useCallback(async () => {
        if (!ready && elementRef.current) {
            await init(elementRef.current as HTMLElement);
        }
    }, [ready, elementRef]);

    useEffect(() => {
        initService();
    }, []);

    return <div
        id="map"
        ref={elementRef}>
    </div>;
};
