import React, {createElement, MouseEventHandler} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';

import {ReactComponent as iconInfo} from '../../../assets/icons/lines/system-info.svg';
import {ReactComponent as iconGo} from '../../../assets/icons/lines/system-quieroir-1.svg';
import {ReactComponent as iconShare} from '../../../assets/icons/lines/system-share.svg';
import {ReactComponent as iconReport} from '../../../assets/icons/lines/systemReportar.svg';

import './button-section.scss';

interface ButtonSectionInterface {
    txtButtons: Array<string>,
    redirectMoreInfo?: MouseEventHandler<HTMLButtonElement>,
    share?: MouseEventHandler<HTMLButtonElement>,
    report?: MouseEventHandler<HTMLButtonElement>,
    goToDirection?: MouseEventHandler<HTMLButtonElement>,
}

export default function ButtonSection({
        txtButtons = [],
        redirectMoreInfo = () => {
        },
        share = () => {
        },
        report = () => {
            const link = 'https://forms.office.com/pages/responsepage.aspx?id=ykrKQfDoPkChzXoLtcxfqPQxcTjV81tLsGdHYaa5bR1UNUFVT0RLS0JJWTI4VDE3Ukg4QVdETUdYTy4u';
            return window.open(link, '_blank');
        },
        goToDirection = () => {
        },
    }: ButtonSectionInterface,
) {
    const {t} = useTranslation();

    const buttons = txtButtons.map((btn: string) => {
        let icon;
        let label;
        let onClickFunc;
        if (btn == 'info') {
            icon = iconInfo;
            label = t('translation:web_btn_more_info');
            onClickFunc = redirectMoreInfo;
        } else if (btn == 'share') {
            icon = iconShare;
            label = t('translation:transport_sheet_toolbar_share');
            onClickFunc = share;
        } else if (btn == 'report') {
            icon = iconReport;
            label = t('translation:transport_sheet_toolbar_report');
            onClickFunc = report;
        } else {
            icon = iconGo;
            label = t('translation:transport_sheet_toolbar_indications');
            onClickFunc = goToDirection;
        }

        return (
            <button
                key={btn}
                className={btn}
                onClick={onClickFunc}
            >
                {createElement(icon)}
                <span>{label}</span>
            </button>
        );
    });

    return (<>
        <div className={isMobile ? 'pin-buttons pin-buttons-mobile' : 'pin-buttons pin-buttons-dtp'}>
            {buttons}
        </div>
        <div className="separator separator-dsk"></div>
    </>);
}
