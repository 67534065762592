export const getProjectionPoint = (coords: google.maps.LatLngLiteral, polyline: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral => {

    return polyline.reduce((prev, curr) => {

        const prevDistance = getDistance(coords, prev);
        const currDistance = getDistance(coords, curr);

        return currDistance < prevDistance ? curr : prev;
    });
};

export const getDistance = (a: google.maps.LatLngLiteral, b: google.maps.LatLngLiteral): number => google.maps.geometry.spherical
    .computeDistanceBetween(
        new google.maps.LatLng(a),
        new google.maps.LatLng(b),
    );

export const getTimeDistance = (a: google.maps.LatLngLiteral, b: google.maps.LatLngLiteral): number => Math.ceil(google.maps.geometry.spherical
    .computeDistanceBetween(
        new google.maps.LatLng(a),
        new google.maps.LatLng(b),
    ) / 1.4 / 60);

export const splitLine = (a: google.maps.LatLngLiteral | undefined, b: google.maps.LatLngLiteral, n: number): google.maps.LatLngLiteral[] => {
    const chunks = [];

    if (a && b) {
        const v = {lat: b.lat - a.lat, lng: b.lng - a.lng};
        const length = Math.sqrt(Math.pow(v.lat, 2) + Math.pow(v.lng, 2));
        const uv = {lat: v.lat / length, lng: v.lng / length};
        const chunkLength = length / n;

        for (let i = 0; i < n; i++) {
            chunks.push({
                lat: a.lat + i * uv.lat * chunkLength,
                lng: a.lng + i * uv.lng * chunkLength,
            });
        }
    }

    return chunks;
};
