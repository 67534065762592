import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';

import './list-item.scss';
import {ListItemInterface} from './list.interface';

export const ListItem = (props: ListItemInterface<any>) => {

    const addLink = (content: ReactNode) => {
        return props.href ? <Link to={props.href} className={props.item ? '' : 'list-item'}>
            {content}
        </Link> : <div className={props.item ? '' : 'list-item'}>{content}</div>;
    };

    return <>
        {
            addLink(
                props.item ||
                <>
                    {props.icon}
                    <div className="text">
                        <div className="title">{props.title || ''}</div>
                        {props.subtitle && <div className="subtitle">{props.subtitle}</div>}
                    </div>
                </>,
            )
        }

        {
            !!props.subList && <div className="sub-list">
                {props.subList}
            </div>
        }
    </>;
};
