import {TranslationModel} from '../../core/models';
import i18n from './i18n.config';

export const translateArray = (tranlations: any, key: string) => {
    let translation: string = '';

    tranlations?.forEach((tranlationTmp: any) => {
        if (tranlationTmp.languages_code.includes(i18n.language)) {
            translation = tranlationTmp[key];
        }
    });

    return translation;
};

export const translate = (translation: TranslationModel[] = []): string => {
    return translation ? translation.find((t) => t.code?.includes(i18n.language))?.text || translation[0]?.text || '' : '';
};
