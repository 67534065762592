import {AlertDetailDesktop} from './alert-detail-desktop/alert-detail.desktop';
import {CloseStopsDesktop} from './close-stops-descktop/close-stops.desktop';
import {ContactDesktop} from './contact-desktop/contact.desktop';
import {HomeDesktop} from './home-desktop/home.desktop';
import {LineDetailDesktop} from './line-detail-desktop/line-detail.desktop';
import {SettingsDesktop} from './settings-desktop/settings.desktop';
import {StopDetailDesktop} from './stop-detail-desktop/stop-detail.desktop';

export const desktopRoutingConfig = {
    default: '/home',
    routes: [
        {
            path: '/home',
            component: HomeDesktop,
            disabled: false,
        },
        {
            path: '/lines',
            component: HomeDesktop,
            disabled: false,
        },
        {
            path: ['/lines/:routeId', '/lines/:routeId/stop/:stopId'],
            component: LineDetailDesktop,
            disabled: false,
        },
        {
            path: '/alerts',
            component: HomeDesktop,
            disabled: false,
        },
        {
            path: '/alerts/:type/:id',
            component: AlertDetailDesktop,
            disabled: false,
        },
        {
            path: '/settings',
            component: SettingsDesktop,
            disabled: false,
        },
        {
            path: '/stops/:stopId',
            component: StopDetailDesktop,
            disabled: false,
        },
        {
            path: '/close-stops',
            component: CloseStopsDesktop,
            disabled: false,
        },
        {
            path: '/info',
            component: ContactDesktop,
            disabled: false,
        },
        // {
        //     path: '/serveis-parada',
        //     component: CloseServicesList,
        //     disabled: false,
        // },
        // {
        //     path: '/serveis-aprop',
        //     component: CloseServices,
        //     disabled: false,
        // },
        // {
        //     path: '/serveis-compartits',
        //     component: SharedTransportsList,
        //     disabled: false,
        // },
        // {
        //     path: '/stop/:id',
        //     component: Pin,
        //     disabled: false,
        // },
        // {
        //     path: '/stop-route/:id',
        //     component: Pin,
        //     disabled: false,
        // },
        // {
        //     path: '/line-timeline/:id',
        //     component: PinTimeline,
        //     disabled: false,
        // },
        // {
        //     path: '/line-timeline/:id/:stop',
        //     component: PinTimeline,
        //     disabled: false,
        // },
        // {
        //     path: '/alerts-filters',
        //     component: AlertFiltersDsk,
        //     disabled: false,
        // },
        // {
        //     path: '/maps',
        //     component: MapsDesktop,
        //     disabled: false,
        // },
        // {
        //     path: '/services',
        //     component: ServicesDesktop,
        //     disabled: false,
        // },
    ],
};
