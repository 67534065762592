import {createContext} from 'react';

export interface LayerCtx {
    visible: boolean
    activeFilters: any
    toggleActive: (type: string, active: boolean) => void
    toggleVisible: (value?: any) => void,
}

export const LayerContext = createContext<LayerCtx>(
    {
        visible: false,
        activeFilters: {},
        toggleActive: (type: string, active: boolean) => {
        },
        toggleVisible: () => {
        },
    });

export const LayerProvider = LayerContext.Provider;
