import MarkerClusterer from '@googlemaps/markerclustererplus';
import {isMobile} from 'react-device-detect';
import {BehaviorSubject, Observable} from 'rxjs';
import {CLUSTERER_CONFIG, getMapStyles, INIT_BOUNDS, INIT_COORDS, INIT_ZOOM} from '../constants';
import {getCurrentPosition, getInfoTimeMarkers, getLocationHalo, getLocationShapes, getPointerMarker} from '../utils';

export class Map {

    private readonly map: google.maps.Map;

    private markers: google.maps.Marker[] = [];
    private markerClusterer: MarkerClusterer;
    private locationShapes: google.maps.Circle[] = [];
    private locationHalo: google.maps.Marker;
    private locationInfo: google.maps.Marker[] = [];
    private pointerMarker: google.maps.Marker;
    private pointerTimeout: any;
    private routes: (google.maps.Polyline | google.maps.Marker)[] = [];

    public currentLocation: google.maps.LatLngLiteral | null = null;

    private bounds: BehaviorSubject<google.maps.LatLngBoundsLiteral>;
    public bounds$: Observable<google.maps.LatLngBoundsLiteral>;

    private isPointerActive: boolean = false;
    private pointer: BehaviorSubject<google.maps.LatLngLiteral | null>;
    public pointer$: Observable<google.maps.LatLngLiteral | null>;

    constructor(element: HTMLElement, theme: string) {

        this.map = new window.google.maps.Map(element,
            {
                center: INIT_COORDS,
                zoom: INIT_ZOOM,
                fullscreenControl: false,
                zoomControl: !isMobile,
                mapTypeControl: false,
                styles: getMapStyles(theme),
            },
        );

        this.markerClusterer = new MarkerClusterer(this.map, [], CLUSTERER_CONFIG);
        this.locationHalo = getLocationHalo();
        this.locationShapes = getLocationShapes();
        this.pointerMarker = getPointerMarker();
        this.setInfoTimeMarkers();

        this.bounds = new BehaviorSubject<google.maps.LatLngBoundsLiteral>(INIT_BOUNDS);
        this.bounds$ = this.bounds.asObservable();

        this.pointer = new BehaviorSubject<google.maps.LatLngLiteral | null>(this.currentLocation);
        this.pointer$ = this.pointer.asObservable();

        this.map.addListener('bounds_changed', this.boundsHandler.bind(this));
        this.map.addListener('mousedown', this.mouseDownHandler.bind(this));
        this.map.addListener('drag', this.cancelTimeoutPointer.bind(this));
        this.map.addListener('mouseup', this.cancelTimeoutPointer.bind(this));
    }

    public drawMarkers(markers: google.maps.Marker[]) {

        if (this.routes.length) {
            this.markerClusterer.clearMarkers();
        } else {

            this.markers = markers;

            const currentMarkers = this.markerClusterer.getMarkers();

            const toRemove = currentMarkers.filter((marker) => !markers.some((m) => m.get('id') === marker.get('id')));
            const toAdd = markers.filter((marker) => !currentMarkers.some((m) => m.get('id') === marker.get('id')));

            this.markerClusterer.removeMarkers(toRemove);
            this.markerClusterer.addMarkers(toAdd);
        }

        this.drawCurrentLocation();
    }

    public async drawCurrentLocation() {

        if (this.pointer.value || this.currentLocation) {

            this.locationShapes.forEach((shape) => {
                shape.setMap(this.routes.length ? null : this.map);
                shape.setCenter(this.pointer.value || this.currentLocation);
            });

            this.locationInfo.forEach((marker, index) => {
                marker.setMap(this.routes.length ? null : this.map);
                marker.setPosition(
                    google.maps.geometry.spherical.computeOffset(
                        new google.maps.LatLng(this.pointer.value || this.currentLocation || 0),
                        index === 0 ? 420 : index === 1 ? 840 : 2520,
                        0,
                    ),
                );
            });

            this.locationHalo.setMap(this.routes.length || this.isPointerActive ? null : this.map);
            this.locationHalo.setPosition(this.currentLocation);
        }
    }

    public drawTrip(polyline: google.maps.Polyline, stops: google.maps.Marker[]) {

        this.markerClusterer.clearMarkers();
        this.locationShapes.forEach((shape) => {
            shape.setMap(null);
        });

        this.routes = [...this.routes, polyline, ...stops];

        polyline.setMap(this.map);
        stops.forEach((stop) => stop.setMap(this.map));
    }

    public clearTrips() {
        this.routes.forEach((polyline) => {
            polyline.setMap(null);
        });
        this.routes = [];

        this.markerClusterer.addMarkers(this.markers);
    }

    public async setCurrentLocationMarker(): Promise<void> {
        try {
            this.currentLocation = await getCurrentPosition();
            this.setPointer(null);
            this.map.panTo(this.currentLocation);
            this.map.setZoom(16);
        } catch (e) {
            throw e;
        }
    }

    public zoomPosition(position: google.maps.LatLngLiteral): void {

        if (!this.map.getBounds()?.contains(position) || (this.map.getZoom() || 0) < 16) {
            this.map.panTo(position);
            this.map.setZoom(16);
        }

    }

    public fitBounds(bounds: google.maps.LatLngBounds): void {
        this.map.fitBounds(bounds);
    }

    private async setInfoTimeMarkers(): Promise<void> {
        this.locationInfo = await getInfoTimeMarkers();
    }

    public setPointer(position: google.maps.LatLngLiteral | null): void {
        this.pointerMarker.setMap(position ? this.map : null);
        this.pointerMarker.setPosition(position);
        if (position) this.zoomPosition(position);

        this.isPointerActive = !!position;
        this.pointer.next(position);
    }

    private boundsHandler(): void {
        const bounds = this.map.getBounds();
        if (bounds) {
            this.bounds.next(bounds.toJSON());
        }
    }

    private mouseDownHandler(event: google.maps.MapMouseEvent): void {
        this.pointerTimeout = setTimeout(() => {
            this.setPointer(event.latLng?.toJSON() || null);
        }, 500);
    }

    private cancelTimeoutPointer(): void {
        clearTimeout(this.pointerTimeout);
    }
}
