import {createContext} from 'react';

export interface HeaderCtx {
    headerConfig: any;
    initHeader: (config: any) => void;
}

export const HeaderContext = createContext<HeaderCtx>({
    headerConfig: {},
    initHeader: (config: any) => {
    },
});

export const HeaderProvider = HeaderContext.Provider;
