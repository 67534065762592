import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import ca from './locales/ca/translation.json';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';

export const fallbackLng = ['ca'];
export const whitelist = ['en', 'es', 'ca'];

const detection = {
    order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'],
    cookieMinutes: 10,
    cookieDomain: 'myDomain',
    htmlTag: document.documentElement,
    checkWhitelist: true,
};

i18n
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        fallbackLng,
        debug: false,
        whitelist,
        detection,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            ca,
            es,
            en,
        },
    });
// Per obligar a fer el languageDetector
i18n.changeLanguage();

export default i18n;
