import {translationSchema} from './common.schema';

export const serviceGroupSchema = {
    title: 'service group',
    version: 0,
    description: 'AMB service groups',
    type: 'object',
    primaryKey: 'id',
    properties: {
        id: {
            type: 'string',
        },
        slug: {
            type: 'string',
        },
        parent: {
            type: 'number',
        },
        name: {
            type: 'array',
            items: {
                type: 'object',
                properties: translationSchema,
            },
        },
    },
    indexes: [],
};
