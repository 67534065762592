import React, {useCallback, useContext, useEffect, useState} from 'react';
import {animated} from 'react-spring';
import {DataContext} from '../../services/data-v2/data.context';
import {LayerContext} from '../../services/layer/layer.context';
import {translate} from '../../services/translation/translationUtils';
import {NavOptionsComponent} from '../nav-options/nav-options.component';
import {TransportListComponent} from '../transport-list/transport-list.component';
import AccordionFilterComponent from './accordion-filter.component';
import './map-filter.scss';

const FilterOptions = [
    {id: 1, translate: 'translation:web_filter_public_transport_title', visible: true},
    {id: 2, translate: 'translation:web_filter_shared_transport_title', visible: true},
    {id: 3, translate: 'translation:misc_transport_lines_others', visible: true},
];

export function MapFilterComponent() {

    const tabOptions = FilterOptions;
    const [selectedOption, setSelectedOption] = useState(FilterOptions[0]);
    const [serviceGroups, setServiceGroups] = useState<{ public: any[], private: any[], other: any[] }>({public: [], private: [], other: []});
    const {ready, db, pinSet, services} = useContext(DataContext);
    const LayerCtx = useContext(LayerContext);

    const getServices = useCallback(async () => {

        const servicesData: {
            public: any[],
            private: any[],
            other: any[],
        } = {
            public: [],
            private: [],
            other: [
                {
                    icon: '',
                    iconDark: '',
                    slug: 'zbe',
                    name: 'ZBE',
                    active: false,
                },
                {
                    icon: '',
                    iconDark: '',
                    slug: 'BiciMap',
                    name: 'BiciMap',
                    active: false,
                },
            ],
        };

        const serviceGroups = await db?.dataSources?.serviceGroups.find({
            selector: {
                parent: {$in: [2, 35]},
            },
            sort: [{slug: 'asc'}],
        }) || [];

        for (const serviceGroup of serviceGroups) {

            if (pinSet && services) {
                const s = services.filter((service) => service.groupSlug === serviceGroup.slug)
                    .map((service) => {

                        return {
                            icon: pinSet[service.slug]?.icon,
                            iconDark: pinSet[service.slug]?.iconDark,
                            slug: service.slug,
                            name: translate(service.name),
                            active: db?.dataSources?.stops.slugFilter[service.slug] || false,
                            color: service.color,
                        };
                    });

                if (s.length) {
                    switch (serviceGroup.parent) {
                    case 2:
                        servicesData.private.push({
                            icon: '',
                            slug: serviceGroup.slug,
                            name: translate(serviceGroup.name),
                            active: false,
                            transports: s,
                        });
                        break;
                    case 35:
                        servicesData.public = [
                            ...servicesData.public,
                            ...s,
                        ]
                            .sort((a, b) => {
                                return a.name.localeCompare(b.name, undefined, {
                                    numeric: true,
                                    sensitivity: 'base',
                                });
                            });
                        break;
                    }
                }
            }
        }

        setServiceGroups(servicesData);
    }, [db, pinSet, services]);

    useEffect(() => {
        if (ready && pinSet && services) {
            getServices();
        }
    }, [ready, pinSet, services]);

    useEffect(() => {
        // if (LayerCtx.visible) {
        //     getServices();
        // }
    }, [LayerCtx.visible]);

    return <>
        <animated.div className="filter-list-dsk">
            <NavOptionsComponent value={{
                tabOptions,
                changeOption: setSelectedOption,
                selectedOption,
            }}/>
        </animated.div>
        <div className="separator"></div>
        <animated.div className="filter-list-content">
            <div className="bottom-line"/>
            <div className="group-list">
                {selectedOption.id === 1 &&
                <div className="public-transports-list">
                    <TransportListComponent transports={serviceGroups.public}/>
                </div>
                }
                {selectedOption.id === 2 &&
                <div>
                    {serviceGroups.private.map((option: any, index: number) =>
                        <div key={index} className="shared-transports-list">
                            <AccordionFilterComponent title={option.name} transports={option.transports} icon={option.icon}/>
                        </div>)}
                </div>
                }
                {selectedOption.id === 3 &&
                <TransportListComponent transports={serviceGroups.other}/>
                }
            </div>
        </animated.div>
    </>;
}

export default MapFilterComponent;
