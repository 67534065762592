import React, {useState} from 'react';
import {SidebarProvider} from './sidebar.context';

export const SidebarService = ({children}: any) => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        setVisible(!visible);
    };

    const show = () => {
        setVisible(true);
    };

    const hide = () => {
        setVisible(false);
    };

    return <SidebarProvider value={{visible, toggleVisible, show, hide}}>
        {children}
    </SidebarProvider>;
};
