import React, {useState} from 'react';
import {SpinnerProvider} from './spinner.context';

export const SpinnerService = ({children}: any) => {
    const [active, setActive] = useState(false);

    const activeSpinner = () => {
        setActive(true);
    };

    const deactivateSpinner = () => {
        setActive(false);
    };

    return <SpinnerProvider value={{active, activeSpinner, deactivateSpinner}}>
        {children}
    </SpinnerProvider>;
};
