import {MangoQuery, RxCollection, RxDocument} from 'rxdb';
import {BehaviorSubject, Observable} from 'rxjs';
import {skip} from 'rxjs/operators';

export abstract class DataSource<T> {

    private data: BehaviorSubject<T[]>;
    public data$: Observable<T[]>;

    protected constructor(protected collection: RxCollection<T>) {

        this.data = new BehaviorSubject<T[]>([]);
        this.data$ = this.data.asObservable()
            .pipe(
                skip(1),
            );

        this.init();
    }

    private async init(): Promise<void> {

        this.data.next(await this.find());
    }

    public async find(query?: MangoQuery): Promise<T[]> {
        const documents = await this.collection.find(query)
            .exec();

        return documents.map(this.map) as T[];
    }

    public async findOne(query?: MangoQuery): Promise<T | null> {
        const document = await this.collection.findOne(query)
            .exec();

        return document ? this.map(document) : null;
    }

    public async findOneById(id: string): Promise<T | null> {
        return await this.findOne({
            selector: {
                id,
            },
        }) || null;
    }

    public async bulkInsert(data: T[]): Promise<{
        success: RxDocument<T>[],
        error: any[],
    }> {

        const documents = await this.collection.find().exec();

        if (documents.length) {
            await this.collection.bulkRemove(documents.map((item: RxDocument<T>) => item.get('id')));
        }

        const result = await this.collection.bulkInsert(data);

        this.data.next(result.success.map(this.map));

        return result;
    }

    private map(doc: RxDocument<T>): T {
        return doc.toJSON() as T;
    }
}
