import {RxCollection} from 'rxdb';
import {BehaviorSubject, Observable} from 'rxjs';
import {INIT_BOUNDS, INIT_COORDS} from '../../constants/map.constants';
import {SLUG_FILTER_INITIAL_STATE} from '../../constants/stop.constants';
import {StopModel} from '../../models';
import {getDistance, getTimeDistance} from '../../utils';
import {DataSource} from './data-source';

export class StopDataSource extends DataSource<StopModel> {

    private visibleStops: BehaviorSubject<StopModel[]>;
    public visibleStops$: Observable<StopModel[]>;

    private closeStops: BehaviorSubject<StopModel[]>;
    public closeStops$: Observable<StopModel[]>;

    public currentBounds: google.maps.LatLngBoundsLiteral = INIT_BOUNDS;
    public currentPointer: google.maps.LatLngLiteral = INIT_COORDS;
    public slugFilter: { [slug: string]: boolean } = SLUG_FILTER_INITIAL_STATE;
    public slugs: string[] = [];
    public closeStopsSlugs: string[] = [];

    constructor(collection: RxCollection) {
        super(collection);

        this.visibleStops = new BehaviorSubject<StopModel[]>([]);
        this.visibleStops$ = this.visibleStops.asObservable();

        this.closeStops = new BehaviorSubject<StopModel[]>([]);
        this.closeStops$ = this.closeStops.asObservable();

        this.setBounds(this.currentBounds);
        this.setPointer(this.currentPointer);
    }

    public toggleSlug(slug: string, value: boolean = !this.slugFilter[slug]): { [slug: string]: boolean } {

        this.slugFilter[slug] = value;
        this.setBounds();
        return this.slugFilter;
    }

    public setCloseStopsSlugs(slugs: string[] = []): void {
        this.closeStopsSlugs = slugs;
        this.setPointer();
    }

    public async setBounds(bounds: google.maps.LatLngBoundsLiteral = this.currentBounds): Promise<StopModel[]> {

        this.currentBounds = bounds;

        const slugs: string[] = this.slugs.length ? this.slugs : Object.entries(this.slugFilter)
            .reduce((slugs: string[], [slug, value]) => value ? [...slugs, slug] : slugs, []);

        const stops = await this.find({
            selector: {
                $and: [
                    {
                        parentStation: '',
                    },
                    {
                        slug: {
                            $in: slugs,
                        },
                    },
                    {
                        lng: {
                            $gt: bounds.west,
                        },
                    },
                    {
                        lng: {
                            $lt: bounds.east,
                        },
                    },
                    {
                        lat: {
                            $gt: bounds.south,
                        },
                    },
                    {
                        lat: {
                            $lt: bounds.north,
                        },
                    },
                    {},
                ],
            },
        });

        this.visibleStops.next(stops);
        return stops;
    }

    public async setPointer(pointer: google.maps.LatLngLiteral = this.currentPointer): Promise<StopModel[]> {

        this.currentPointer = pointer;

        const stops = (await this.find({
            selector: {
                $and: [
                    {
                        slug: {
                            $in: this.closeStopsSlugs,
                        },
                    },
                    {
                        parentStation: '',
                    },
                ],
            },
        }) || [])
            .filter((stop) => getTimeDistance(
                stop,
                pointer,
            ) < 30)
            .sort((a, b) => getDistance(a, pointer) - getDistance(b, pointer));

        this.closeStops.next(stops.slice(0, 15 * this.closeStopsSlugs.length));

        return stops;
    }
}
