export const toHex = (color: string) => {
    return `${!color.includes('#') ? '#' : ''}${color}`;
};

export const goTo = (coords: google.maps.LatLngLiteral) => {
    window.open(`https://www.google.com/maps/dir/?api=1&origin&destination=${coords.lat},${coords.lng}`, '_blank');
};

export const filterBySlug = <T extends { slug: string }>(data: T[], slugs: string[] | undefined = []): T[] => data.filter((item) => slugs.includes(item.slug));

export const findBySlug = <T extends { slug: string }>(data: T [], slug: string | undefined = ''): T | undefined => data.find((item) => item.slug === slug);

export const diacriticSensitiveRegex = (string: string = '') => string
    .replace(/a/g, '[a,á,à,ä,â]')
    .replace(/e/g, '[e,é,ë,è]')
    .replace(/i/g, '[i,í,ï,ì]')
    .replace(/o/g, '[o,ó,ö,ò]')
    .replace(/u/g, '[u,ü,ú,ù]');
