import React from 'react';
import {AlertsDetailComponent} from '../../../clean-components/alert-detail/alerts-detail.component';
import {Page} from '../../../clean-components/page/page';

export function AlertDetailMobile() {
    return <>
        <Page title="translation:misc_alert_detail_title">
            <AlertsDetailComponent/>
        </Page>
    </>;
};
