import React, {useEffect, useState} from 'react';

import './switch-button.scss';

function switchButton(props: any) {
    const [isActive, setActive] = useState(props.active);

    const toggleClass = () => {
        props.changeValue(!isActive, props.value);
        setActive(!isActive);
    };

    useEffect(() => {
        setActive(props.active);
    }, [props.active]);

    return (<div className="switch-button">
    <span
        role="button"
        onClick={toggleClass}
        onKeyDown={toggleClass}
        tabIndex={0}
        id="switchButton"
    >
      <label htmlFor="switchButton" className={isActive ? 'switch-label active' : 'switch-label'}>
        <span className={isActive ? 'switch-button active' : 'switch-button'}/>
      </label>
    </span>
        </div>
    );
}

export default switchButton;
