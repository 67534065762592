export const getCurrentPosition = async (): Promise<google.maps.LatLngLiteral> => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                resolve({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            },
            (error) => {
                reject(error);
            },
            {
                maximumAge: 180000,
            },
        );
    });
};
