export const routeSchema = {
    title: 'lines',
    primaryKey: 'id',
    version: 0,
    description: 'AMB lines',
    type: 'object',
    properties: {
        id: {
            type: 'string',
        },
        slug: {
            type: 'string',
        },
        shortName: {
            type: 'string',
        },
        longName: {
            type: 'string',
        },
        textColor: {
            type: 'string',
        },
        type: {
            type: 'number',
        },
        color: {
            type: 'string',
        },
        routeId: {
            type: 'string',
        },
        iconShape: {
            type: 'string',
        },
        url: {
            type: 'string',
        },
        desc: {
            type: 'string',
        },
        agencyId: {
            type: 'string',
        },
        services: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    todayService: {
                        type: 'boolean',
                    },
                    timetable: {
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    id: {
                        type: 'string',
                    },
                    description: {
                        type: 'string',
                    },
                    timetableDescription: {
                        type: 'string',
                    },
                },
            },
        },
        tripsPaths: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    tripIds: {
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    stopIds: {
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    tripHeadsign: {
                        type: 'string',
                    },
                    directionId: {
                        type: 'number',
                    },
                    shapeId: {
                        type: 'string',
                    },
                },
            },
        },
    },
    indexes: [],
};
