import React from 'react';
import {RouteModel} from '../../core/models';
import {ListItemInterface} from '../list/list.interface';

import './schedule-route-selector.scss';

export const ScheduleRouteSelectorComponent = ({
    routes,
    selected,
    onChange = () => {
    },
}: {
    routes: ListItemInterface<RouteModel>[],
    selected?: ListItemInterface<RouteModel>,
    onChange: (route: ListItemInterface<RouteModel>) => any,
}) => {
    return <>
        <div className="schedule-slider-container">
            {
                routes.map((route, key) =>
                    <div
                        onClick={() => onChange(route)}
                        onKeyDown={() => onChange(route)}
                        role="button"
                        tabIndex={0}
                        key={key}
                        className={(selected && route.id === selected.id) ? 'schedule-slider-box active' : 'schedule-slider-box'}
                    >
                        {route.icon}
                    </div>)
            }
        </div>
    </>;
};
