export const THEMES = [
    {
        title: 'translation:theme_light_mode',
        value: 'theme-light',
    },
    {
        title: 'translation:theme_dark_mode',
        value: 'theme-dark',
    },
];
