import {ReactComponent as BikeIcon} from '../../assets/icons/transports/transporteBiciRed.svg';
import {ReactComponent as BusIcon} from '../../assets/icons/transports/transporteBusRed.svg';
import {ReactComponent as CarIcon} from '../../assets/icons/transports/transporteCocheRed.svg';
import {ReactComponent as SubwayIcon} from '../../assets/icons/transports/transporteMetroRed.svg';
import {ReactComponent as MotoIcon} from '../../assets/icons/transports/transporteMotoRed.svg';
import {ReactComponent as OtherIcon} from '../../assets/icons/transports/transporteOtrosRed.svg';
import {ReactComponent as ScooterIcon} from '../../assets/icons/transports/transportePatineteRed.svg';
import {ReactComponent as TaxiIcon} from '../../assets/icons/transports/transporteTaxiRed.svg';
import {ReactComponent as TramIcon} from '../../assets/icons/transports/transporteTramRed.svg';
import {ReactComponent as TrainIcon} from '../../assets/icons/transports/transporteTrenRed.svg';

export enum TRANSPORT_ID {
    BUS,
    SUBWAY,
    TRAIN,
    TRAM,
    OTHER,
    BIKE,
    MOTO,
    SCOOTER,
    CAR,
    TAXI,
}

export enum TRANSPORT_GROUP_ID {
    PUBLIC,
    TAXI,
    BIKE,
    CAR,
    OTHER,
}

export interface Transport {
    id: TRANSPORT_ID,
    title: string,
    icon: any,
    slugs: string[],
    groupSlug: string,
}

export interface TransportGroup {
    id: TRANSPORT_GROUP_ID,
    title: string,
    icon: any,
    slugs: string[],
    groupSlug: string[],
}

export const TRANSPORTS: Transport[] = [
    {
        id: TRANSPORT_ID.BUS,
        title: 'transport_bottom_sheet_services_bus',
        icon: BusIcon,
        slugs: ['busamb'],
        groupSlug: 'cat-bus',
    },
    {
        id: TRANSPORT_ID.SUBWAY,
        title: 'transport_bottom_sheet_services_subway',
        icon: SubwayIcon,
        slugs: ['metro'],
        groupSlug: 'cat-metro',
    },
    {
        id: TRANSPORT_ID.TRAIN,
        title: 'transport_bottom_sheet_services_train',
        icon: TrainIcon,
        slugs: ['renfe', 'fgc'],
        groupSlug: 'cat-tren',
    },
    {
        id: TRANSPORT_ID.TRAM,
        title: 'transport_bottom_sheet_services_tram',
        icon: TramIcon,
        slugs: ['trambaix', 'trambesos'],
        groupSlug: 'cat-tramvia',
    },
    {
        id: TRANSPORT_ID.OTHER,
        title: 'transport_bottom_sheet_services_others',
        icon: OtherIcon,
        slugs: [],
        groupSlug: '',
    },
    {
        id: TRANSPORT_ID.BIKE,
        title: 'transport_bottom_sheet_services_bike',
        icon: BikeIcon,
        slugs: ['bicing', 'donkey-republic', 'bicibox', 'e-bicibox'],
        groupSlug: 'cat-bicicletes',
    },
    {
        id: TRANSPORT_ID.MOTO,
        title: 'transport_bottom_sheet_services_motorcycle',
        icon: MotoIcon,
        slugs: ['cooltra', 'tucycle', 'yego'],
        groupSlug: 'cat-motos',
    },
    {
        id: TRANSPORT_ID.SCOOTER,
        title: 'transport_bottom_sheet_services_patinet',
        icon: ScooterIcon,
        slugs: [],
        groupSlug: 'cat-patinets',
    },
    {
        id: TRANSPORT_ID.CAR,
        title: 'transport_bottom_sheet_services_car',
        icon: CarIcon,
        slugs: ['punts-carrega', 'park-ride-amb', 'parkride-altres'],
        groupSlug: 'cat-cotxe',
    },
    {
        id: TRANSPORT_ID.TAXI,
        title: 'transport_bottom_sheet_services_taxi',
        icon: TaxiIcon,
        slugs: ['taxi'],
        groupSlug: 'cat-taxi',
    },
];

export const TRANSPORT_GROUPS: TransportGroup[] = [
    {
        id: TRANSPORT_GROUP_ID.PUBLIC,
        title: 'commons_transport_prefs_tab_public_transport',
        icon: BusIcon,
        slugs: [
            'busamb',
            'metro',
            'renfe',
            'fgc',
            'trambaix',
            'trambesos',
        ],
        groupSlug: [
            'cat-bus',
            'cat-metro',
            'cat-tren',
            'cat-tramvia',
        ],
    },
    {
        id: TRANSPORT_GROUP_ID.TAXI,
        title: 'transport_bottom_sheet_services_taxi',
        icon: TaxiIcon,
        slugs: ['taxi'],
        groupSlug: ['cat-taxi'],
    },
    {
        id: TRANSPORT_GROUP_ID.BIKE,
        title: 'transport_bottom_sheet_services_bike',
        icon: BikeIcon,
        slugs: ['bicing', 'donkey-republic', 'bicibox', 'e-bicibox'],
        groupSlug: ['cat-bicicletes'],
    },
    {
        id: TRANSPORT_GROUP_ID.CAR,
        title: 'transport_bottom_sheet_services_car',
        icon: CarIcon,
        slugs: ['punts-carrega', 'park-ride-amb', 'parkride-altres'],
        groupSlug: ['cat-cotxe'],
    },
    {
        id: TRANSPORT_GROUP_ID.OTHER,
        title: 'transport_bottom_sheet_services_others',
        icon: MotoIcon,
        slugs: ['cooltra', 'tucycle', 'yego'],
        groupSlug: ['cat-motos', 'cat-patinets'],
    },
];

export const PUBLIC_SERVICES_PARENT_SLUGS = [
    'cat-bus',
    'cat-metro',
    'cat-tren',
    'cat-tramvia',
];

export const SHARED_SERVICES_GROUP_SLUGS = [
    'cat-vehicles-compartits',
    'cat-bicicletes',
    'cat-patinets',
    'cat-cotxes-compartits',
    'cat-taxi',
    'cat-cotxe',
    'cat-motos',
];

export const TRANSPORTS_KEY_MAP: { [key in TRANSPORT_ID]?: Transport } = TRANSPORTS.reduce((slugs, transport) => ({
    ...slugs,
    [transport.id]: {...transport},
}), {});

export const TRANSPORT_GROUP_KEY_MAP: { [key in TRANSPORT_GROUP_ID]?: TransportGroup } = TRANSPORT_GROUPS.reduce((slugs, group) => ({
    ...slugs,
    [group.id]: {...group},
}), {});
