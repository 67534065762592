import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {animated, useSpring} from 'react-spring';
import {DskPageContext} from '../../services/desktop-page/desktop-page.context';
import {Header} from '../header/header';
import {Spinner} from '../spinner/spinner';

import './desktop-page.scss';

export const DesktopPage = (props: any) => {
    const {t} = useTranslation();
    const DskPageCtx = useContext(DskPageContext);
    const isOpen = DskPageCtx.visible;
    const {
        bottom,
        color,
        backgroundColor,
        opacity,
        transform,
        marginTop,
    } = useSpring({
        from: {
            bottom: '29px',
            color: 'var(--vermell-amb)',
            backgroundColor: 'var(--blanc-amb)',
            opacity: 1,
            transform: 'rotate(-135deg)',
            marginTop: '6px',
        },
        bottom: isOpen ? '29px' : getBottom(),
        color: isOpen ? 'var(--vermell-amb)' : 'var(--blanc-amb)',
        backgroundColor: isOpen ? 'var(--blanc-amb)' : 'var(--vermell-amb)',
        opacity: isOpen ? 1 : 0,
        transform: isOpen ? 'rotate(-135deg)' : 'rotate(45deg)',
        marginTop: isOpen ? '6px' : '-2px',
    });

    const toggle = () => {
        DskPageCtx.toggleVisible();
    };

    function getBottom() {
        return `${window.innerHeight - 125}px`;
    }

    return <>
        <animated.div style={{bottom}} className="desktop-page">
            <Header/>
            <Spinner/>
            <animated.div style={{opacity}} className="content">
                {props.children}
            </animated.div>
            <animated.div
                style={{color, backgroundColor}}
                className={`footer ${isOpen ? 'open' : 'close'}`}
                onClick={toggle}>
                <animated.div
                    style={{borderColor: color, transform, marginTop}}
                    className="arrow"/>
                <div className="text">
                    {isOpen ? t('translation:hide_panel') : t('translation:show_panel')}
                </div>
            </animated.div>
        </animated.div>
    </>;
};
