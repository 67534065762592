import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import './backhome-button.scss';

export function BackHomeButton(props: any) {
    const history = useHistory();
    const redirectHome = () => {
        document.getElementsByClassName('header')[0].classList.remove('hide');
    };
    const goTo = () => {
        history.goBack();
    };
    return <div className="back-home-butto213n">
        {(props.goToPrevius) ?
            <button className="btn btn-primary" onClick={goTo}>
                <div className="back-home-button"></div>
            </button> :
            <Link to="/" className="btn btn-primary" onClick={redirectHome}>
                <button className="btn btn-primary">
                    <div className="back-home-button"></div>
                </button>
            </Link>
        }
    </div>;
}
