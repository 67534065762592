import React from 'react';

import './amb-icon.scss';

export const AmbIcon = ({
    color,
    backgroundColor,
    size = 'm',
    shape = 'square',
    text,
    img,
}: {
    color?: string,
    backgroundColor?: string,
    size?: 's' | 'm' | 'l' | 'xl',
    shape?: 'square' | 'round',
    text?: string,
    img?: string,
}) => {

    const className = `amb-icon ${size} ${shape}`;

    return <div className="amb-icon-container">
        <div
            className={className}
            style={{
                ...(color && {color: `${color.includes('#') ? '' : '#'}${color}`}),
                ...(backgroundColor && {backgroundColor: `${backgroundColor.includes('#') ? '' : '#'}${backgroundColor}`}),
            }}>
            {
                img ?
                    <img
                        alt="amb icon"
                        className="image"
                        src={img}/> :
                    <span>{text || ''}</span>
            }

        </div>
    </div>;
};
