import React, {useCallback, useContext, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {DataContext} from '../../services/data-v2/data.context';
import {LayerContext} from '../../services/layer/layer.context';
import {analyticsEvent} from '../../utils/analytics';
import {TransportListItemComponent} from './transport-list-item.component';
import './transport-list.scss';

interface ITransports {
    transports: Array<any>;
}

export const TransportListComponent = (props: ITransports) => {
    const {transports} = props;
    const LayerService = useContext(LayerContext);
    const {db} = useContext(DataContext);
    const [transportList, setTransportList] = useState(transports);

    const changeValue = useCallback((value: boolean, slug: string) => {
        analyticsEvent('Mostrar en el mapa', 'displayonmap_serviceselector_select');
        db?.dataSources?.stops.toggleSlug(slug, value);
    }, [db]);

    const initLayerService = () => {
        transports.forEach((transport) => {
            if (LayerService.activeFilters[transport.slug] === undefined) {
                LayerService.toggleActive(transport.slug, transport.active);
            }
        });
    };

    useEffect(() => {
        initLayerService();
        transports.forEach((transport) => {
            if (LayerService.activeFilters[transport.slug] !== undefined) {
                transport.active = LayerService.activeFilters[transport.slug];
            }
        });
        setTransportList([...transports]);
    }, [transports]);
    return <div className={isMobile ? 'transports-list-container mobile' : 'transports-list-container'}>
        {transportList.map((transport: any, i: number) =>
            <div key={i} className="result-container">
                <TransportListItemComponent
                    icon={transport.icon}
                    name={transport.name}
                    active={db?.dataSources?.stops.slugFilter[transport.slug] || false}
                    changeValue={changeValue}
                    value={transport.slug}
                    slug={transport.slug}
                    color={transport.color}
                />
            </div>)}
    </div>;
};
