import pointerIcon from '../../assets/icons/map/pinPlace1.svg';
import {INFO_TIME_MARKER_HEIGHT, INFO_TIME_MARKER_WIDTH} from '../constants';
import {createWalkInfoPin} from './file.utils';

export const getLocationHalo = (): google.maps.Marker => new google.maps.Marker({
    icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 6,
        fillColor: '#497BDE',
        fillOpacity: 1,
        strokeColor: '#ffffff',
        strokeWeight: 2,
    },
});

export const getLocationShapes = (): google.maps.Circle[] => [
    new google.maps.Circle({
        radius: 2520,
        fillColor: '#000000',
        fillOpacity: .1,
        strokeColor: 'transparent',
        strokeWeight: 0,
        clickable: false,
    }),
    new google.maps.Circle({
        radius: 840,
        fillColor: '#000000',
        fillOpacity: .1,
        strokeColor: 'transparent',
        strokeWeight: 0,
        clickable: false,
    }),
    new google.maps.Circle({
        radius: 420,
        fillColor: '#000000',
        fillOpacity: .1,
        strokeColor: 'transparent',
        strokeWeight: 0,
        clickable: false,
    }),
];

export const getPointerMarker = (): google.maps.Marker => new google.maps.Marker({
    icon: {
        url: pointerIcon,
        scaledSize: new google.maps.Size(20, 26),
    },
    optimized: true,
});

export const getInfoTimeMarkers = async (): Promise<google.maps.Marker[]> => {
    const pin5 = await createWalkInfoPin(5) || '';
    const pin10 = await createWalkInfoPin(10) || '';
    const pin30 = await createWalkInfoPin(30) || '';

    return [
        new google.maps.Marker({
            icon: {
                url: pin5,
                scaledSize: new google.maps.Size(
                    INFO_TIME_MARKER_WIDTH,
                    INFO_TIME_MARKER_HEIGHT,
                ),
            },
            clickable: false,
        }),
        new google.maps.Marker({
            icon: {
                url: pin10,
                scaledSize: new google.maps.Size(
                    INFO_TIME_MARKER_WIDTH,
                    INFO_TIME_MARKER_HEIGHT,
                ),
            },
            clickable: false,
        }),
        new google.maps.Marker({
            icon: {
                url: pin30,
                scaledSize: new google.maps.Size(
                    INFO_TIME_MARKER_WIDTH,
                    INFO_TIME_MARKER_HEIGHT,
                ),
            },
            clickable: false,
        }),
    ];
};
