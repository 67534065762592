import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RealTime, RouteModel, StopModel} from '../../core/models';
import {DataContext} from '../../services/data-v2/data.context';

import './real-times.scss';

export const RealTimesComponent = ({stop, route}: { stop: StopModel, route: RouteModel }) => {

    const {t} = useTranslation();

    const {realTimes} = useContext(DataContext);

    const [rt, setRt] = useState<RealTime[]>([]);

    const realTimesHandler = useCallback(() => {
        setRt(realTimes[stop.id]?.realTimes[route.id] || []);
    }, [stop, route, realTimes]);

    useEffect(() => {
        realTimesHandler();
    }, [realTimes]);

    return <div className="real-times-container">
        {
            !!rt.length && <div className="real-times">
                <div className="label">{t('translation:web_stop_bus_time')}</div>
                <div className="times">
                    {
                        rt.map((realTime, key) => {

                            const minutes = Math.round(realTime.arrivalTime / 60);
                            const seconds = realTime.arrivalTime % 60;

                            return <div key={key} className="time">
                                {key > 0 && <div className="separator">·</div>}
                                <div>{minutes > 0 ? minutes : seconds}</div>
                                <div className="units">{minutes > 0 ? 'min' : 'sec'}</div>
                            </div>;
                        })
                    }
                </div>
            </div>
        }
    </div>;
};
