import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {StopModel} from '../../core/models';
import {DataContext} from '../../services/data-v2/data.context';
import './line-detail-stops.scss';
import {RealTimesComponent} from '../real-times/real-times.component';

export const LineDetailStopsComponent = () => {

    const {ready, db, currentRoute, currentTrip, currentStop} = useContext(DataContext);

    const [stops, setStops] = useState<StopModel[]>([]);

    const getStops = useCallback(async () => {

        const stops = [];

        if (currentRoute) {

            for (const stopId of currentRoute.tripsPaths[currentTrip].stopIds || []) {

                const stop = await db?.dataSources?.stops.findOne({
                    selector: {
                        stopId,
                        slug: currentRoute.slug,
                    },
                });

                if (stop) stops.push(stop);
            }
        }


        setStops(stops);

    }, [db, currentRoute, currentTrip]);

    useEffect(() => {
        if (ready) getStops();
    }, [ready, currentRoute, currentTrip]);

    return <div className="line-stops">
        {
            stops.map(
                (stop, key) => <Link
                    key={key}
                    className={`stop${key === 0 ? ' first' : key === stops.length - 1 ? ' last' : ''}${stop.id === currentStop?.id ? ' current' : ''}`}
                    to={`/stops/${stop.id}`}
                >
                    <div className="marker">
                        <div className="circle" style={{...(!!currentRoute && {backgroundColor: currentRoute.color, borderColor: currentRoute.color})}}/>
                        <div className="line" style={{...(!!currentRoute && {backgroundColor: currentRoute.color, borderColor: currentRoute.color})}}/>
                    </div>
                    <div className="stop-data">
                        <div className="name">
                            {stop.stopName}
                        </div>
                        {
                            stop.id === currentStop?.id && currentRoute && <RealTimesComponent stop={currentStop} route={currentRoute}/>
                        }
                    </div>
                </Link>,
            )
        }
    </div>;
};
