import {AlertModel, AlertResponse, AlertsResponse} from '../models';
import {get} from '../utils/fetch.utils';

export class AlertsApi {

    public static async getAlerts(): Promise<AlertModel[]> {

        try {
            const data = await get<AlertsResponse>(`/gtfs/alerts`);

            return Object.entries(data)
                .reduce((alerts: AlertModel[], [slug, group]) => {

                    return [...alerts, ...(group.entity?.map((alert) => this.map(alert, slug)) || [])];
                }, []);
        } catch (e) {
            return [];
        }

    }

    public static map(data: AlertResponse, slug: string): AlertModel {

        return {
            id: data.id,
            slug,
            alertActivePeriod: data.alert.activePeriod,
            alertRoute: data.alert.informedEntity.reduce((arr, item) => item.routeId ? [...arr, item.routeId] : arr, [] as string[]),
            alertStop: data.alert.informedEntity.reduce((arr, item) => item.stopId ? [...arr, item.stopId] : arr, [] as string[]),
            cause: data.alert.cause,
            effect: data.alert.effect,
            url: data.alert.url?.translation.map((t) => ({code: t.language, text: t.text || ''})),
            headerText: data.alert.headerText?.translation.map((t) => ({code: t.language, text: t.text || ''})),
            descriptionText: data.alert.descriptionText?.translation.map((t) => ({code: t.language, text: t.text || ''})),
            isNew: true,
        };
    }
}
