import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {contactOptions, socialMediaOptions} from './contact.options';
import './contact.scss';
import SocialMediaComponent from './social-media.component';
import UserComponent from './user.component';

function ContactComponent(props: any) {
    const {t} = useTranslation();
    return (<div className="contact-container">
            <p className="description">{t('translation:misc_contact_description')}</p>
            <h3 className="section-title">{t('translation:misc_contact_text_social_media')}</h3>
            {socialMediaOptions.map((option: any, index: number) =>
                <div key={index}>
                    <SocialMediaComponent mediaIcon={option.mediaIcon} title={option.title} link={option.link} icon={option.icon}/>
                </div>)}
            <h3 className="section-title">{t('translation:misc_contact_text_citizen_service')}</h3>
            {contactOptions.map((option: any, index: number) =>
                <div key={index}>
                    <UserComponent mediaIcon={option.mediaIcon} title={option.title} link={option.link} icon={option.icon} type={option.type}/>
                </div>)}
        </div>
    );
}

export default ContactComponent;
