import {createContext} from 'react';

export interface AlertsFiltersCtx {
    filtersWarnings: any,
    filtersTransports: any,
    tmpFiltersWarnings: any,
    tmpFiltersTransports: any,
    setFiltersWarnings: () => void,
    setFiltersTransports: () => void,
    setTempFiltersWarningsctx?: any,
    settmpFiltersTransportsctx?: any,
}

export const AlertsFilterContext = createContext<AlertsFiltersCtx>({
    filtersWarnings: [],
    filtersTransports: [],
    tmpFiltersWarnings: [],
    tmpFiltersTransports: [],
    setFiltersWarnings: () => {
    },
    setFiltersTransports: () => {
    },
});

export const AlertsFilterProvider = AlertsFilterContext.Provider;
