import React, {useState} from 'react';
import {AlertsFilterProvider} from './alerts-filters.context';

export const AlertsFilterService = ({children}: any) => {
    const [filtersWarnings, setfiltersWarningsctx] = useState([]);
    const [tmpFiltersWarnings, setTempFiltersWarningsctx] = useState([]);
    const [filtersTransports, setfiltersTransportsctx] = useState([]);
    const [tmpFiltersTransports, settmpFiltersTransportsctx] = useState([]);

    const setFiltersWarnings = () => {
        setfiltersWarningsctx(tmpFiltersWarnings);
    };

    const setFiltersTransports = () => {
        setfiltersTransportsctx(tmpFiltersTransports);
    };
    return <AlertsFilterProvider value={{
        filtersWarnings,
        filtersTransports,
        tmpFiltersWarnings,
        tmpFiltersTransports,
        setFiltersWarnings,
        setFiltersTransports,
        setTempFiltersWarningsctx,
        settmpFiltersTransportsctx,
    }}>
        {children}
    </AlertsFilterProvider>;
};
