import React, {useEffect} from 'react';
import {BottomPage} from '../../../clean-components/bottom-page/bottom-page';
import {SearchComponent} from '../../../clean-components/search/search.component';


export const HomeMobile = () => {

    useEffect(() => {
        document.getElementsByClassName('search-button')[0].classList.remove('detail-page');
        document.getElementsByClassName('transports-button')[0].classList.remove('detail-page');
        document.getElementsByClassName('search-button')[0].classList.remove('hide');
        document.getElementsByClassName('transports-button')[0].classList.remove('hide');
    }, [location]);

    return <BottomPage maximizedOnCreate={true} medium={true}>
        <SearchComponent/>
    </BottomPage>;
};
