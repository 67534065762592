import React, {useCallback, useContext, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useParams} from 'react-router-dom';
import {MAP_VIEW, TRANSPORT_GROUP_ID, TRANSPORT_ID} from '../../core/constants';
import {TRANSPORT_GROUP_KEY_MAP, TRANSPORTS} from '../../core/constants/transport.constants';
import {useQuery} from '../../core/hooks/query.hook';
import {ServiceModel, StopModel} from '../../core/models';
import {filterBySlug} from '../../core/utils';
import {DataContext} from '../../services/data-v2/data.context';
import {HeaderContext} from '../../services/header/header.context';
import {MapContext} from '../../services/map-v2/map.context';
import {AmbIcon} from '../amb-icon/amb-icon.component';
import {BikeStopDetailComponent} from '../bike-stop-detail/bike-stop-detail.component';
import {List} from '../list/list.component';
import {ListItemInterface} from '../list/list.interface';
import {MotoStopDetailComponent} from '../moto-stop-detail/moto-stop-detail.component';
import {RealTimesComponent} from '../real-times/real-times.component';
import {ServiceSelectorComponent} from '../service-selector/service-selector.component';
import {CarStopDetailComponent} from '../car-stop-detail/car-stop-detail.component';
import {TimeWalkComponent} from '../time-walk/time-walk.component';
import TransportsComponent from '../transports/transports';

import './close-stops.scss';

export const CloseStopsComponent = () => {

    const {type} = useParams<any>();
    const query = useQuery();

    const {ready, db, pinSet, services, closeStops} = useContext(DataContext);
    const {setView, map} = useContext(MapContext);
    const {initHeader} = useContext(HeaderContext);

    const [transport, setTransport] = useState<any>(TRANSPORT_GROUP_KEY_MAP[TRANSPORT_GROUP_ID.PUBLIC]);
    const [stops, setStops] = useState<ListItemInterface<StopModel>[]>([]);

    const [selectedService, setSelectedService] = useState<ServiceModel>();

    const getStopView = (stop: StopModel) => {

        const transport = TRANSPORTS.find((transport) => transport.slugs.includes(stop.slug));

        if (transport) {
            switch (transport.id) {
            case TRANSPORT_ID.BIKE:
                return <BikeStopDetailComponent stop={stop}/>;
            case TRANSPORT_ID.TAXI:
            case TRANSPORT_ID.CAR:
                return <CarStopDetailComponent stop={stop}/>;
            case TRANSPORT_ID.MOTO:
                return <MotoStopDetailComponent stop={stop}/>;
            }
        }

        return '';
    };

    const mapCloseStops = useCallback(async () => {

        if (db && pinSet && transport) {

            const stops = [];

            for (const stop of closeStops) {

                if (transport.id === TRANSPORT_GROUP_ID.PUBLIC) {

                    const routes = (await db?.dataSources?.routes.findByStop(stop) || [])
                        .map((route) => ({
                            id: route.id,
                            title: route.longName,
                            subtitle: <RealTimesComponent stop={stop} route={route}/>,
                            href: `/lines/${route.id}/stop/${stop.id}`,
                            icon: <AmbIcon
                                text={route.shortName}
                                color={route.textColor}
                                backgroundColor={route.color}
                                shape={route.slug === 'busamb' ? 'round' : 'square'}
                                size="s"/>,
                            data: route,
                        }));

                    stops.push({
                        id: stop.id,
                        title: <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div>
                                {stop.stopName}
                            </div>
                            <TimeWalkComponent coords={stop}/>
                        </div>,
                        subList: <List items={routes}/>,
                        href: `/stops/${stop.id}`,
                        icon: <AmbIcon
                            size="m"
                            shape="square"
                            backgroundColor={pinSet[stop.slug].color}
                            img={pinSet[stop.slug].icon}/>,
                        data: stop,
                    });

                } else {

                    stops.push({
                        id: stop.id,
                        item: getStopView(stop),
                        href: `/stops/${stop.id}`,
                        data: stop,
                    });
                }
            }

            setStops(stops);
        }

    }, [db, pinSet, transport, closeStops]);

    const setHeader = useCallback(() => {

        if (!isMobile) {

            initHeader({
                title: transport?.title || type,
                backIcon: true,
                backToPrevius: false,
                closeIcon: false,
                logoIcon: false,
                svgIcon: transport?.icon,
                customClassName: {
                    'backgroundColor': 'var(--gris-fons-amb)',
                },
                backPath: '/home',
            });
        }
    }, [transport]);

    const onServiceChangeHandler = useCallback((service: ServiceModel | undefined) => {
        setSelectedService(service);
        if (db) {
            db.dataSources?.stops.setCloseStopsSlugs(service ? [service.slug] : transport.slugs);
        }
    }, [db, transport]);

    useEffect(() => {

        setTransport(TRANSPORT_GROUP_KEY_MAP[parseInt(query.type) as TRANSPORT_GROUP_ID]);
    }, [query]);

    useEffect(() => {
        if (ready) {
            mapCloseStops();
        }
    }, [ready, pinSet, closeStops, transport]);

    useEffect(() => {
        setHeader();
    }, [transport]);

    useEffect(() => {
        if (transport && db) {
            db.dataSources?.stops.setCloseStopsSlugs(transport.slugs);
        }
    }, [transport, db]);

    useEffect(() => {
        setView(MAP_VIEW.CLOSE_STOPS);
        return () => {
            setView(MAP_VIEW.STOPS);
            map?.setPointer(null);
        };
    }, []);

    return <div className={`close-stops-container${isMobile ? ' mobile' : ''}`}>
        <TransportsComponent selected={transport}/>

        {
            transport && <ServiceSelectorComponent
                services={filterBySlug<ServiceModel>(services, transport.slugs)}
                onChange={onServiceChangeHandler}
                selected={selectedService}
            />
        }

        <div className="close-stops">

            <List items={stops}/>
        </div>
    </div>;
};
