import * as React from 'react';
import {useContext} from 'react';
import {SpinnerContext} from '../../services/spinner/spinner.context';
import './spinner.scss';

export const Spinner = (props: any) => {
    const SpinnerService = useContext(SpinnerContext);
    const active = SpinnerService.active;

    return <>
        {
            active && <div className="spinner-container loading">
                <div className="spinner">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
        }
    </>;
};
