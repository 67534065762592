import React from 'react';
import {useTranslation} from 'react-i18next';
import {SettingsLangComponent} from './settings-lang.component';
import {SettingsStyleComponent} from './settings-style.component';
import './settings.scss';

export const SettingsComponent = () => {
    const {t} = useTranslation();
    return <div className="content-setting">
        <div className="content-link">
            <h3 className="text">{t('translation:misc_settings_language_item_title')}</h3>
        </div>
        <SettingsLangComponent/>
        <div className="content-link">
            <h3 className="text">{t('translation:misc_settings_theme_item_title')}</h3>
        </div>
        <SettingsStyleComponent/>
    </div>;
};
