import React from 'react';
import {useTranslation} from 'react-i18next';
import MapFilterComponent from '../../../clean-components/map-filter/map-filter';

import '../../../clean-components/map-filter/map-filter.scss';
import {Page} from '../../../clean-components/page/page';
import {Spinner} from '../../../clean-components/spinner/spinner';

export function MapFilterMobile() {
    const {t} = useTranslation();

    return <>
        <Page title="commons_map_layers_title">
            <div className="mapFilter-container-mobile">
                <div className="filter-list-description">
                    {t('translation:commons_map_layers_description')}
                </div>
                <Spinner/>
                <MapFilterComponent/>
            </div>
        </Page>
    </>;
}
