import {activePeriodSchema, translationSchema} from './common.schema';

export const alertSchema = {
    title: 'alerts',
    version: 0,
    description: 'AMB Alerts',
    type: 'object',
    primaryKey: 'id',
    properties: {
        id: {
            type: 'string',
        },
        slug: {
            type: 'string',
        },
        alertActivePeriod: {
            type: 'array',
            items: {
                type: 'object',
                properties: activePeriodSchema,
            },
        },
        alertRoute: {
            type: 'array',
            items: {
                type: 'string',
            },
        },
        alertStop: {
            type: 'array',
            items: {
                type: 'string',
            },
        },
        cause: {
            type: 'string',
        },
        effect: {
            type: 'string',
        },
        url: {
            type: 'array',
            items: {
                type: 'object',
                properties: translationSchema,
            },
        },
        headerText: {
            type: 'array',
            items: {
                type: 'object',
                properties: translationSchema,
            },
        },
        descriptionText: {
            type: 'array',
            items: {
                type: 'object',
                properties: translationSchema,
            },
        },
        isNew: {
            type: 'boolean',
        },
    },
};
