import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {ServiceModel} from '../../core/models';
import {DataContext} from '../../services/data-v2/data.context';
import {translate} from '../../services/translation/translationUtils';
import {AmbIcon} from '../amb-icon/amb-icon.component';

import './service-selector.scss';


export const ServiceSelectorComponent = (
    {
        services = [],
        onChange = () => {
        },
        selected,
    }: {
        services?: ServiceModel[],
        onChange: (value: ServiceModel | undefined) => void,
        selected?: ServiceModel
    },
) => {

    const {t} = useTranslation();

    const {pinSet} = useContext(DataContext);

    return <>
        {
            services.length > 1 && pinSet && <div className="service-selector">
                <button
                    className={`service-selector-item${!selected ? ' selected' : ''}`}
                    onClick={() => onChange(undefined)}
                >
                    <AmbIcon
                        backgroundColor="#6e6259"
                        color="#ffffff"
                        shape="round"
                        size="m"
                        text={t('translation:transport_shared_services_all')}
                    />
                </button>

                {
                    services.map(
                        (service, key) => <button
                            key={key}
                            className={`service-selector-item${selected?.slug === service.slug ? ' selected' : ''}`}
                            onClick={() => onChange(service)}
                        >
                            <AmbIcon
                                img={pinSet[service.slug].icon}
                                backgroundColor={pinSet[service.slug].color}
                                shape="round"
                                size="m"
                            />
                            <div className="name">{translate(service.name)}</div>
                        </button>,
                    )
                }
            </div>
        }
    </>;
};
