import React from 'react';
import {subtTransportTypes} from '../../utils/utils';
import './icon-generator.scss';

interface iconGenerator {
    routeType: any;
    routeTextColor: string;
    routeColor: string;
    routeShortName: string;
    routeShape?: string;
}

function IconGenerator(props: iconGenerator) {
    const routeType = () => {
        switch (props.routeType) {
        case '1':
            return 'metro';
        case '2':
            return 'bus';
            break;
        case 3:
            return 'bus';
            break;
        case 4:
            return 'stops-icons-small';
            break;
        }
    };
    let shape = '';
    if (props.routeShape == 'square') {
        shape = '0';
    }
    if (props.routeShape == 'circle') {
        shape = '100%';
    }
    let style = {};
    if (props.routeType == '0') {
        style = {
            'color': `#${props.routeTextColor}`,
            'backgroundColor': `#${props.routeColor}`,
            'width': '26px',
            'height': '26px',
            'fontSize': '18.5px',
            'letterSpacing': '-1.38px',
        } as React.CSSProperties;
    }
    if (props.routeType == '1') {
        style = {
            'color': `#${props.routeTextColor}`,
            'backgroundColor': `#${props.routeColor}`,
            'width': '26px',
            'height': '26px',
            'fontSize': '18.5px',
            'letterSpacing': '-1.38px',
        } as React.CSSProperties;
    }
    if (props.routeType == '2') {
        style = {
            'color': `#${props.routeTextColor}`,
            'backgroundColor': `#${props.routeColor}`,
            'borderRadius': `${shape}`,
            'width': '26px',
            'height': '26px',
            'fontSize': '14.5px',
            'letterSpacing': '-0.1px',
        } as React.CSSProperties;
    }
    if (props.routeType == '3') {
        style = {
            'color': `#${props.routeTextColor}`,
            'backgroundColor': `#${props.routeColor}`,
            'borderRadius': `${shape}`,
            'width': '14px',
            'height': '24px',
            'fontSize': '11.5px',
            'letterSpacing': '-0.1px',
            'display': 'inlineBlock',
        } as React.CSSProperties;
    }
    if (props.routeType == undefined) {
        style = {
            'color': `#${props.routeTextColor}`,
            'backgroundColor': `#${props.routeColor}`,
            'borderRadius': `${shape}`,
            'width': '24px',
            'height': '24px',
            'fontSize': '11.5px',
            'letterSpacing': '-0.1px',
            'display': 'inlineBlock',
        } as React.CSSProperties;
    }
    if (props.routeType == subtTransportTypes.bus) {
        style = {
            'color': `#${props.routeTextColor}`,
            'backgroundColor': `#${props.routeColor}`,
            'borderRadius': `${shape}`,
            'width': '24px',
            'height': '24px',
            'fontSize': '11.5px',
            'letterSpacing': '-0.1px',
            'display': 'inlineBlock',
        } as React.CSSProperties;
    }
    if (props.routeType == subtTransportTypes.metro) {
        style = {
            'color': `#${props.routeTextColor}`,
            'backgroundColor': `#${props.routeColor}`,
            'borderRadius': `${shape}`,
            'width': '24px',
            'height': '24px',
            'fontSize': '11.5px',
            'letterSpacing': '-0.1px',
            'display': 'inlineBlock',
        } as React.CSSProperties;
    }
    if (props.routeType == 'stops-icons-small') {
        style = {
            'color': `#${props.routeTextColor}`,
            'backgroundColor': `#${props.routeColor}`,
            'borderRadius': `${shape}`,
            'width': '12px',
            'height': '12px',
            'fontSize': '8px',
        } as React.CSSProperties;
    }
    return (<div className="icon-generator-container">
            <div
                className={routeType()}
                style={style}
            >
                {props.routeShortName}
            </div>
        </div>
    );
}

export default IconGenerator;
