export const LINES = [
    {
        id: 1,
        translate: 'translation:misc_transport_lines_bus',
        visible: true,
        slug: 'cat-bus',
    },
    {
        id: 2,
        translate: 'translation:misc_transport_lines_subway',
        visible: true,
        slug: 'cat-metro',
    },
    {
        id: 3,
        translate: 'translation:misc_transport_lines_train',
        visible: true,
        slug: 'cat-tren',
    },
    {
        id: 4,
        translate: 'translation:misc_transport_lines_others',
        visible: true,
        slug: 'cat-tramvia',
    },
];
