export const SEARCH_HISTORY_SIZE = 15;

export const transportTypes = {
    train: 'cat-tren',
    bus: 'cat-bus',
    metro: 'cat-metro',
    others: 'others',
    tram: 'cat-tram',
    public: 'cat-transport-public',
};

export const subtTransportTypes = {
    bus: 'busamb',
    metro: 'metro',
    cooltra: 'cooltra',
    tren: 'fgc',
    renfe: 'renfe',
    tranvia: 'tranvia',
    tram: 'tram',
    trambesos: 'trambesos',
    otros: 'otros',
    donkeyRepublic: 'donkey-republic',
    bicing: 'bicing',
    bici: 'bici',
    bicibox: 'bicibox',
    fgc: 'fgc',
    moto: 'moto',
    patinete: 'patinete',
    coche: 'coche',
    taxi: 'taxi',
    ubeeqo: 'ubeeqo',
    virtuo: 'virtuo',
    getaround: 'getaround',
    endolla: 'endolla',
    puntsCarrega: 'punts-carrega',
    parkRideAltres: 'parkride-altres',
    parkRideAmb: 'park-ride-amb',
    ebicibox: 'e-bicibox',
};
export const subtTransportSliderTypes = {
    renfe: 'Renfe',
    fgc: 'FGC',
};

export const subtTransportCar = {
    ubeeqo: 'ubeeqo',
    virtuo: 'virtuo',
    getaround: 'getaround',
    endolla: 'endolla',
};

export const markerTypes = {
    NO_STOP: 'no_stop',
    HOME: 'home',
    LOCATION: 'location',
    BUS: 'busamb',
    FGC: 'fgc',
};

export const themeOptions = ['theme-light', 'theme-dark'];

export const plainDocument = (documents: any) => {
    if (documents?.length > 0) {
        return documents.map((doc: any) => {
            return doc.toJSON();
        });
    } else {
        return [];
    }
};

export const formatServiceCatalog = (serviceCatalog: any) => {
    const tmp: any = {};
    serviceCatalog?.forEach((service: any) => {
        service.services?.forEach((innerService: any) => {
            if (!!innerService.services_id) {
                tmp[innerService.services_id?.slug] = {
                    slug: innerService.services_id.slug,
                    icon: innerService.services_id.icon,
                    iconDark: innerService.services_id.icon_dark,
                    logoDark: innerService.services_id.logo_dark,
                    logoWhite: innerService.services_id.logo,
                    translations: innerService.services_id.translations,
                    color: innerService.services_id.color || '',
                    colorDark: innerService.services_id.color_dark_mode || '',
                    dataEndPoint: innerService.services_id.data_endpoint || '',
                    dataTypeOptions: innerService.services_id.data_type_options,
                    dataType: innerService.services_id.data_type || null,
                };
            }
        });
    });
    return tmp;
};

export const createIconRouteJson = (document: any) => {
    const routeLine = document;
    const iconLine = {
        routeType: routeLine.route.route_type,
        routeTextColor: routeLine.route.route_text_color,
        routeColor: routeLine.route.route_color,
        routeShortName: routeLine.route.route_short_name,
        route_icon_shape: routeLine.route.route_icon_shape,
    };
    routeLine['iconLine'] = iconLine;
    return routeLine;
};

export const plainDocumentIconLine = (documents: any) => {
    if (!!documents) {
        return documents.map((doc: any) => {
            return createIconRouteJson(doc.toJSON());
        });
    }
};
export const plainDocumentStop = (documents: any) => {
    return documents.map((doc: any) => {
        return doc.toJSON();
    });
};
export const orderByList = (list: Array<any>, orderBy: string) => {
    return list.sort(function(a, b) {
        const nameA = a[orderBy].toUpperCase(); // ignore upper and lowercase
        const nameB = b[orderBy].toUpperCase(); // ignore upper and lowercase
        const lA: any = lineOrderParts(nameA);
        const lB: any = lineOrderParts(nameB);
        // prefix diderent
        if (lA.prefix != lB.prefix) {
            return (lA.prefix < lB.prefix) ? -1 : 1;
        }
        //
        if (lA.number != lB.number) {
            return lA.number - lB.number;
        }

        return 0;
    });
};

export const lineOrderParts = (str: any) => {
    const strArray = str.split('');
    let indexOfnumber: any = null;
    let indexOfLastnumber: any = null;

    for (let i = 0; i < strArray.length; i++) {
        if (isNaN(strArray[i]) == false) {
            indexOfnumber = i;
            break;
        }
    }
    if (strArray.length >= indexOfnumber + 1 && isNaN(strArray[indexOfnumber + 1]) == false) {
        if (strArray.length >= indexOfnumber + 2 && isNaN(strArray[indexOfnumber + 2]) == false) {
            indexOfLastnumber = indexOfnumber + 2;
        } else {
            indexOfLastnumber = indexOfnumber + 1;
        }
    } else {
        indexOfLastnumber = indexOfnumber;
    }
    return {
        prefix: (indexOfnumber >= 0) ? str.slice(0, indexOfnumber) : null,
        number: (indexOfnumber >= 0 && indexOfLastnumber >= 0) ? parseInt(str.slice(indexOfnumber, indexOfLastnumber + 1)) : null,
    };
};
