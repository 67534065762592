import React, {useEffect, useState} from 'react';
import {themeOptions} from '../../utils/utils';

import {ThemeContext} from './themeContext';

const ThemeService = (props: any) => {
    const [theme, setThemeCtx] = useState(localStorage.getItem('theme') || themeOptions[0]);

    useEffect(() => {
        localStorage.setItem('theme', theme);
        document.documentElement.className = theme;
    }, [theme]);

    const setTheme = (theme: string) => {
        setThemeCtx(theme);
    };

    const getTheme = () => {
        return theme;
    };

    return (
        <ThemeContext.Provider value={{
            setTheme,
            getTheme,
        }}>
            {props.children}
        </ThemeContext.Provider>
    );
};

export {ThemeService};
