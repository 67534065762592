import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AlertsApi, LinesApi, SharedServicesApi} from '../../core/api';
import {ServicesApi} from '../../core/api/services.api';
import {SHARED_SERVICES_GROUP_SLUGS, TRANSPORT_GROUP_ID, TRANSPORT_GROUP_KEY_MAP} from '../../core/constants/transport.constants';
import {Db} from '../../core/data/db';
import {RealTimes, RealTimeWorker} from '../../core/data/real-time-worker';
import {AlertModel, RouteModel, ServiceModel, StopModel, TripCoords} from '../../core/models';
import {PinModel} from '../../core/models/pin.model';
import {createPin, filterBySlug, getTripCoords} from '../../core/utils';
import {SpinnerContext} from '../spinner/spinner.context';
import {DataProvider} from './data.context';

export const DataService = (props: any) => {

    const {activeSpinner, deactivateSpinner} = useContext(SpinnerContext);

    const [db, setDb] = useState<Db>();
    const [ready, setReady] = useState<boolean>(false);

    const [realTimeWorker, setRealTimeWorker] = useState<RealTimeWorker>();

    const [currentStop, setCurrentStop] = useState<StopModel | null>(null);
    const [currentStopAlerts, setCurrentStopAlerts] = useState<AlertModel[]>([]);

    const [visibleStops, setVisibleStops] = useState<StopModel[]>([]);
    const [closeStops, setCloseStops] = useState<StopModel[]>([]);

    const [currentRoute, setCurrentRoute] = useState<RouteModel | null>(null);
    const [currentRouteAlerts, setCurrentRouteAlerts] = useState<AlertModel[]>([]);

    const [currentTrip, setCurrentTrip] = useState<number>(0);
    const [tripCoords, setTripCoords] = useState<TripCoords[]>([]);

    const [pinSet, setPinSet] = useState<{ [slug: string]: PinModel } | null>(null);
    const [services, setServices] = useState<ServiceModel[]>([]);

    const [realTimes, setRealTimes] = useState<RealTimes>({});

    const unsubscribe$: Subject<boolean> = new Subject<boolean>();

    const init = useCallback(async (): Promise<Db> => {

        activeSpinner();

        let coreDb = db;
        if (!coreDb) {

            coreDb = new Db();
            await coreDb.init();

            setDb(coreDb);

            setReady(true);
        }

        await initMainData();

        if (!realTimeWorker) {
            const realTimeWorker = new RealTimeWorker(coreDb);
            setRealTimeWorker(realTimeWorker);
        }

        deactivateSpinner();

        return coreDb;

    }, [db, realTimeWorker]);

    const initMainData = useCallback(async () => {
        await Promise.all([
            getPinSet(),
            getServices(),
        ]);
    }, [db]);

    const sync = async (db: Db) => {
        const [servicesData, alerts, lines] = await Promise.all([
            ServicesApi.getServices(),
            AlertsApi.getAlerts(),
            LinesApi.getLines(),
        ]);

        const pins = [];
        let stops: StopModel[] = [];

        for (const service of servicesData.services) {

            const isSharedService = SHARED_SERVICES_GROUP_SLUGS.includes(service.groupSlug);

            const [icon, iconDark, logo, logoDark, currentStops, newStops] = await Promise.all([
                ServicesApi.getAsset(service.icon.id),
                ServicesApi.getAsset(service.iconDark.id),
                ServicesApi.getAsset(service.logo.id),
                ServicesApi.getAsset(service.logoDark.id),
                db.dataSources?.stops.find({selector: {slug: service.slug}}) || Promise.resolve([]),
                (isSharedService ? SharedServicesApi.getStops(service) : Promise.resolve([])),
            ]);

            pins.push({
                id: service.slug,
                color: service.color,
                colorDark: service.colorDark,
                icon,
                iconDark,
                logo,
                logoDark,
                pin: service.icon.id ? await createPin(icon, service.color) : '',
            });

            if (isSharedService) {

                stops = [
                    ...stops,
                    ...(!newStops || !newStops.length ? currentStops : newStops),
                ];
            }
        }

        await Promise.all([
            db.dataSources?.pins.bulkInsert(pins),
            db.dataSources?.services.bulkInsert(servicesData.services),
            db.dataSources?.serviceGroups.bulkInsert(servicesData.serviceGroups),
            db.dataSources?.alerts.bulkInsert(alerts),
            db.dataSources?.routes.bulkInsert(lines.routes),
            db.dataSources?.stops.bulkInsert([...stops, ...lines.stops]),
        ]);

        await initMainData();
    };

    const getPinSet = useCallback(async () => {
        setPinSet(await db?.dataSources?.pins.getPinSet() || null);
    }, [db]);

    const getServices = useCallback(async () => {
        setServices(await db?.dataSources?.services.find() || []);
    }, [db]);

    const currentRouteHandler = useCallback(async () => {

        activeSpinner();

        setCurrentTrip(0);

        if (currentRoute && db) {
            setTripCoords(await getTripCoords(currentRoute, db));
        } else {
            setTripCoords([]);
        }

        await getCurrentRouteAlerts();

        deactivateSpinner();
    }, [db, currentRoute]);

    const currentStopHandler = useCallback(async () => {
        activeSpinner();
        await getCurrentStopAlerts();
        if (realTimeWorker) realTimeWorker.currentStop = currentStop;
        deactivateSpinner();
    }, [db, currentStop, realTimeWorker]);

    const servicesHandler = useCallback(() => {

        if (realTimeWorker) {
            realTimeWorker.sharedServices = filterBySlug(services, [
                ...(TRANSPORT_GROUP_KEY_MAP[TRANSPORT_GROUP_ID.BIKE]?.slugs || []),
                ...(TRANSPORT_GROUP_KEY_MAP[TRANSPORT_GROUP_ID.OTHER]?.slugs || []),
            ]);
        }
    }, [realTimeWorker, services]);

    const getCurrentStop = useCallback(async (id?: string) => {
        if (id) {
            setCurrentStop(await db?.dataSources?.stops.findOneById(id) || null);
        } else {
            setCurrentStop(null);
        }
    }, [db]);

    const getCurrentStopAlerts = useCallback(async () => {
        if (currentStop) {
            setCurrentStopAlerts(await db?.dataSources?.alerts.find({
                selector: {
                    alertStop: {
                        $contains: currentStop.stopId,
                    },
                },
            }) || []);
        } else {
            setCurrentStopAlerts([]);
        }
    }, [currentStop]);

    const getCurrentRoute = useCallback(async (id?: string) => {
        if (id) {
            setCurrentRoute(await db?.dataSources?.routes.findOneById(id) || null);
        } else {
            setCurrentRoute(null);
        }
    }, [db]);

    const getCurrentRouteAlerts = useCallback(async () => {
        if (currentRoute) {
            setCurrentRouteAlerts(await db?.dataSources?.alerts.find({
                selector: {
                    alertRoute: {
                        $contains: currentRoute.routeId,
                    },
                },
            }) || []);
        } else {
            setCurrentRouteAlerts([]);
        }
    }, [currentRoute]);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (db && realTimeWorker) {
            sync(db);

            db.dataSources?.stops.visibleStops$
                .pipe(
                    takeUntil(unsubscribe$),
                )
                .subscribe(setVisibleStops);

            db.dataSources?.stops.closeStops$
                .pipe(
                    takeUntil(unsubscribe$),
                )
                .subscribe(setCloseStops);

            realTimeWorker.realTimes$
                .pipe(
                    takeUntil(unsubscribe$),
                )
                .subscribe(setRealTimes);
        }
        return () => {
            unsubscribe$.next(true);
            unsubscribe$.complete();
        };
    }, [db, realTimeWorker]);

    useEffect(() => {
        currentStopHandler();
    }, [currentStop]);

    useEffect(() => {
        currentRouteHandler();
    }, [currentRoute]);

    useEffect(() => {
        if (realTimeWorker) realTimeWorker.closeStops = closeStops;
    }, [closeStops]);

    useEffect(() => {
        servicesHandler();
    }, [services]);

    return <DataProvider value={{
        db,
        ready,
        init,
        pinSet,
        services,
        getCurrentStop,
        currentStop,
        currentStopAlerts,
        visibleStops,
        closeStops,
        getCurrentRoute,
        currentRoute,
        currentRouteAlerts,
        currentTrip,
        setCurrentTrip,
        tripCoords,
        realTimes,
    }}>
        {props.children}
    </DataProvider>;
};
