export const SLUG_FILTER_INITIAL_STATE = {
    'bicibox': false,
    'bicing': false,
    'donkey-republic': false,
    'e-bicibox': false,
    'busamb': true,
    'park-ride-amb': false,
    'parkride-altres': false,
    'punts-carrega': false,
    'metro': true,
    'cooltra': false,
    'tucycle': false,
    'yego': false,
    'taxi': false,
    'trambaix': true,
    'trambesos': true,
    'fgc': true,
    'renfe': true,
};
