import {TRANSPORT_GROUP_ID, TRANSPORT_GROUP_KEY_MAP, TRANSPORT_ID, TRANSPORTS_KEY_MAP} from '../constants/transport.constants';
import {
    BikeStation,
    BikeStationsResponse,
    BikeStatus,
    BikeStatusResponse,
    CarStop,
    Moto,
    MotoResponse,
    ServiceModel,
    SharedServiceStatus,
    StopModel,
    TaxiStop,
} from '../models';
import {get, getWithoutRoot} from '../utils';

export class SharedServicesApi {

    public static async getStops(service: ServiceModel): Promise<StopModel[]> {

        try {

            const options = service.dataTypeOptions.split('|');

            const response = [
                ...(TRANSPORT_GROUP_KEY_MAP[TRANSPORT_GROUP_ID.TAXI]?.slugs || []),
                ...(TRANSPORT_GROUP_KEY_MAP[TRANSPORT_GROUP_ID.CAR]?.slugs || []),
            ].includes(service.slug) ? await getWithoutRoot<TaxiStop[] | CarStop[]>(service.dataEndPoint) : await get<BikeStationsResponse | MotoResponse>(
                `/sharing/${service.slug}/gbfs/${options.includes('station_information') ?
                    'station_information.json' :
                    options.includes('free_bike_status') ?
                        'free_bike_status.json' :
                        ''}`,
            );

            switch (service.groupSlug) {
            case TRANSPORTS_KEY_MAP[TRANSPORT_ID.BIKE]?.groupSlug:
                return (response as BikeStationsResponse).data.stations.map((station: any) => this.mapBikeStop(station, service.slug));
            case TRANSPORTS_KEY_MAP[TRANSPORT_ID.MOTO]?.groupSlug:
                return (response as MotoResponse).data.bikes.map((bike: any) => this.mapMotoStop(bike, service.slug));
            case TRANSPORTS_KEY_MAP[TRANSPORT_ID.TAXI]?.groupSlug:
                return (response as TaxiStop[]).map((stop: any) => this.mapTaxiStop(stop, service.slug));
            case TRANSPORTS_KEY_MAP[TRANSPORT_ID.CAR]?.groupSlug:
                return (response as CarStop[]).map((stop: any) => this.mapCarStop(stop, service.slug));
            }

            return [];
        } catch (e) {
            return [];
        }
    }

    public static async getServiceStatus(service: ServiceModel): Promise<SharedServiceStatus[]> {
        try {

            const options = service.dataTypeOptions.split('|');


            const response = [
                ...(TRANSPORT_GROUP_KEY_MAP[TRANSPORT_GROUP_ID.TAXI]?.slugs || []),
                ...(TRANSPORT_GROUP_KEY_MAP[TRANSPORT_GROUP_ID.CAR]?.slugs || []),
            ].includes(service.slug) ? await getWithoutRoot<TaxiStop[] | CarStop[]>(service.dataEndPoint) : await get<BikeStatusResponse | MotoResponse>(
                `/sharing/${service.slug}/gbfs/${options.includes('station_status') ?
                    'station_status.json' :
                    options.includes('free_bike_status') ?
                        'free_bike_status.json' :
                        ''}`,
            );

            switch (service.groupSlug) {
            case TRANSPORTS_KEY_MAP[TRANSPORT_ID.BIKE]?.groupSlug:
                return (response as BikeStatusResponse).data.stations.map((station: BikeStatus) => ({
                    id: `${service.slug}:${station.station_id}`,
                    bikesAvailable: station.num_bikes_available,
                    bikesDisabled: station.num_bikes_disabled,
                    docksAvailable: station.num_docks_available,
                    docksDisabled: station.num_bikes_disabled,
                    battery: 0,
                    range: 0,
                }));
            case TRANSPORTS_KEY_MAP[TRANSPORT_ID.MOTO]?.groupSlug:
                return (response as MotoResponse).data.bikes.map((bike: Moto) => ({
                    id: `${service.slug}:${bike.bike_id}`,
                    bikesAvailable: 0,
                    bikesDisabled: 0,
                    docksAvailable: 0,
                    docksDisabled: 0,
                    battery: bike.battery || 0,
                    range: bike.current_range_meters || 0,
                }));
            }

            return [];
        } catch (e) {
            return [];
        }
    }

    public static mapBikeStop(data: BikeStation, slug: string): StopModel {
        return {
            id: `${slug}:${data.station_id}`,
            slug: slug,
            routesIds: [],
            stopName: data.name,
            stopDesc: data.address || '',
            stopCode: data.short_name || data.station_id,
            stopUrl: '',
            stopType: '',
            parentStation: '',
            routesTypes: [],
            wheelchairBoarding: false,
            stopId: data.station_id,
            lat: data.lat,
            lng: data.lon,
        };
    }

    public static mapMotoStop(data: Moto, slug: string): StopModel {
        return {
            id: `${slug}:${data.bike_id}`,
            slug: slug,
            routesIds: [],
            stopName: data.name,
            stopDesc: '',
            stopCode: data.bike_id,
            stopUrl: '',
            stopType: '',
            parentStation: '',
            routesTypes: [],
            wheelchairBoarding: false,
            stopId: data.bike_id,
            lat: data.lat,
            lng: data.lon,
        };
    }

    public static mapTaxiStop(data: TaxiStop, slug: string): StopModel {
        return {
            id: `${slug}:${data.Id}`,
            slug: slug,
            routesIds: [],
            stopName: data.Name,
            stopDesc: data.Description,
            stopCode: data.Id,
            stopUrl: '',
            stopType: '',
            parentStation: '',
            routesTypes: [],
            wheelchairBoarding: false,
            stopId: data.Id,
            lat: data.Coordinates.Latitude,
            lng: data.Coordinates.Longitude,
        };
    }

    public static mapCarStop(data: CarStop, slug: string): StopModel {
        return {
            id: `${slug}:${data.Id}`,
            slug: slug,
            routesIds: [],
            stopName: data.Name,
            stopDesc: data.Description,
            stopCode: data.Id,
            stopUrl: '',
            stopType: data.Type,
            parentStation: '',
            routesTypes: [],
            wheelchairBoarding: false,
            stopId: data.Id,
            lat: data.Coordinates.Latitude,
            lng: data.Coordinates.Longitude,
        };
    }
}
