import React from 'react';
import ErrorPage from '../../components/errorPage/errorPage';
import {Itinerary} from '../../core/models';

export const ScheduleTimeTableComponent = (
    {
        itinerary,
    }: {
        itinerary?: Itinerary
    },
) => {

    return <div className="schedule-info">
        <div className={itinerary ? 'schedule-table' : 'schedule-empty-table'}>
            {
                itinerary ? itinerary.services_timetables.map((serviceTimeTable, key) =>
                    <div key={key}>
                        <div className="schedule-info--title">
                            {serviceTimeTable.description}
                        </div>
                        <div className="schedule-info--times">
                            {serviceTimeTable.timetable.map((time, timeKey) => <span key={timeKey} className="schedule-info--value">{time}</span>)}
                        </div>
                    </div>,
                ) : <ErrorPage/>
            }
        </div>
    </div>;
};
;
;
