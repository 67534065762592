import React, {useContext, useEffect} from 'react';
import ContactComponent from '../../../clean-components/contact/contact.component';
import {DesktopPage} from '../../../clean-components/desktop-page/desktop-page';
import {HeaderContext} from '../../../services/header/header.context';

export function ContactDesktop() {
    const HeaderService = useContext(HeaderContext);
    const headerConfig = {
        title: 'translation:misc_info_contact_item_title',
        backIcon: true,
        closeIcon: false,
        logoIcon: false,
        customClassName: {
            'backgroundColor': 'var(--gris-fons-amb)',
        },
    };

    useEffect(() => {
        if (HeaderService.headerConfig.title != headerConfig.title) {
            HeaderService.initHeader(headerConfig);
        }
    }, [HeaderService.headerConfig]);

    return <DesktopPage config={headerConfig}>
        <ContactComponent/>
    </DesktopPage>;
}
