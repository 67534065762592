import React, {createElement, useCallback, useContext, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {ReactComponent as GeoIconDisabled} from '../../assets/icons/map/map-geo-no-fons.svg';

import {ReactComponent as GeoIcon} from '../../assets/icons/map/map-geo-si.svg';

import {BottomSheetCtx} from '../../services/bottom-sheet/bottom-sheet-context';
import {MapContext} from '../../services/map-v2/map.context';
import {analyticsEvent} from '../../utils/analytics';
import './location-button.scss';

export const LocationButtonComponent = () => {

    const {currentPosition} = useContext(BottomSheetCtx);
    const {goToCurrentLocation, map, geolocationGranted} = useContext(MapContext);

    const [bottom, setBottom] = useState<number>(87);

    const triggerEvent = useCallback(async (e: any) => {
        analyticsEvent('Home', 'home_mylocation_click');
        await goToCurrentLocation();
    }, [map]);

    useEffect(() => {
        setBottom(currentPosition);
    }, [currentPosition]);

    return <div
        style={{
            ...(isMobile && {bottom}),
        }}
        className={`location-button ${isMobile ? 'mobile' : ''}`}
        onClick={triggerEvent}
        onKeyDown={triggerEvent}
        role="button"
        tabIndex={0}>
        {
            createElement(geolocationGranted ? GeoIcon : GeoIconDisabled)
        }
    </div>;
};
