import {ReactComponent as SearchIcon} from '../../assets/icons/buttons/system-search.svg';
import {ReactComponent as AlertIcon} from '../../assets/icons/menu/alertas.svg';
import {ReactComponent as LinesIcon} from '../../assets/icons/menu/lineas.svg';

export const navOptions = [
    {
        id: 1,
        icon: SearchIcon,
        translate: 'translation:web_nav_search_tab',
        href: '/home',
    },
    {
        id: 2,
        icon: LinesIcon,
        translate: 'translation:web_nav_line_tab',
        href: '/lines',
    },
    {
        id: 3,
        icon: AlertIcon,
        translate: 'translation:web_nav_alerts_tab',
        href: '/alerts',
    },
];
