import {mapScriptLoaderOptions} from '../constants/map.constants';

export class GoogleApiLoader {

    static scriptId: string = 'google-maps-api-script';


    public static async loadScript(): Promise<any> {

        return new Promise((resolve, reject) => {
            let scriptElement: HTMLScriptElement | null = document.getElementById(this.scriptId) as HTMLScriptElement;

            if (!scriptElement) {
                scriptElement = document.createElement('script');
                scriptElement.id = this.scriptId;

                scriptElement.addEventListener('load', (event) => {

                    resolve(scriptElement);
                });

                scriptElement.addEventListener('error', (error) => {
                    console.error(error.message);
                    reject(error);
                });
                scriptElement.src = this.buildUrl();
                document.body.appendChild(scriptElement);
            } else {
                console.log('google maps api already loaded.');
                resolve(scriptElement);
            }

        });
    }

    private static buildUrl(): string {
        const url = new URL(mapScriptLoaderOptions.url || process.env.REACT_APP_MAP_URL);

        url.searchParams.append('key', process.env.REACT_APP_MAP_API_KEY || '');
        if (mapScriptLoaderOptions.client) url.searchParams.append('client', mapScriptLoaderOptions.client);
        if (mapScriptLoaderOptions.libraries) url.searchParams.append('libraries', mapScriptLoaderOptions.libraries);

        return url.toString();
    }
}
