import {RxCollection} from 'rxdb';
import {AlertModel} from '../../models';
import {DataSource} from './data-source';

export class AlertDataSource extends DataSource<AlertModel> {

    constructor(collection: RxCollection) {
        super(collection);
    }
}
