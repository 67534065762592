import React, {useCallback, useEffect, useState} from 'react';
import {Itinerary, RouteModel, TimeTableResponse} from '../../core/models';
import {ItinerarySelectorComponent} from '../itinerary-selector/itinerary-selector.component';
import {ListItemInterface} from '../list/list.interface';
import {ScheduleRouteSelectorComponent} from './schedule-route-selector.component';
import {ScheduleTimeTableComponent} from './schedule-time-table.component';

export const ScheduleComponent = ({
                                      routes,
                                      timeTables = [],
                                  }: {
    routes: ListItemInterface<RouteModel>[],
    timeTables: TimeTableResponse[]
}) => {

    const [selectedRoute, setSelectedRoute] = useState<ListItemInterface<RouteModel>>(routes[0]);
    const [timeTable, setTimeTable] = useState<TimeTableResponse | undefined>(timeTables.find((timetable) => timetable.route_id === selectedRoute?.data?.routeId));
    const [itinerary, setItinerary] = useState<Itinerary | undefined>(timeTable?.itineraries_timetables[0]);

    const itinerarySelectorHandler = useCallback((index) => {

        setItinerary(timeTable?.itineraries_timetables[index]);
    }, [timeTable]);

    useEffect(() => {

        setTimeTable(undefined);
        setTimeTable(timeTables.find((timetable) => timetable.route_id === selectedRoute?.data?.routeId));
    }, [selectedRoute, timeTables]);

    useEffect(() => {
        setItinerary(timeTable?.itineraries_timetables[0]);
    }, [timeTable]);

    return <>
        <ScheduleRouteSelectorComponent
            routes={routes}
            onChange={setSelectedRoute}
            selected={selectedRoute}
        />

        {
            timeTable && <ItinerarySelectorComponent
                route={selectedRoute.data}
                itineraries={timeTable.itineraries_timetables.map((itinerary, index) => ({
                    name: itinerary.trips_headsign,
                    id: index,
                }))}
                onChange={itinerarySelectorHandler}
                selected={timeTable.itineraries_timetables.findIndex((item) => item === itinerary)}
            />
        }

        <ScheduleTimeTableComponent
            itinerary={itinerary}
        />
    </>;
};
