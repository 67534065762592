import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ReactComponent as BackIcon} from '../../assets/icons/buttons/back.svg';
import {AlertsFilterContext} from '../../services/alerts-filters/alerts-filters.context';
import {Spinner} from '../spinner/spinner';
import './page.scss';

export function Page({children, title, link, action}: { children: any, title: string, link?: string, action?: string }) {
    const {t} = useTranslation();
    const history = useHistory();
    const filterService = useContext(AlertsFilterContext);

    const goBack = () => {
        history.goBack();
    };

    const filterAction = () => {
        filterService.setFiltersTransports();
        filterService.setFiltersWarnings();
        goBack();
    };

    return <>
        <div className="page">
            <div className="header">
                <button className="back-button" onClick={goBack}>
                    <BackIcon/>
                </button>
                <div className="text">{t(title)}</div>
                {(action == 'filter') ?
                    <button className="filter-button-mobile" onClick={filterAction}>{t('translation:misc_alert_filter_apply')}</button> : null}
            </div>
            <div className="content">
                <Spinner/>
                {children}
            </div>
        </div>
    </>;
}
