import * as React from 'react';
import {createElement} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as Check} from '../../assets/icons/buttons/systemCheck.svg';
import {RadioButton} from '../../components/buttons/radio-button/radio-button';
import {whitelist as languages} from '../../services/translation/i18n.config';
import './settings-lang.scss';

export const SettingsLangComponent = () => {
    const {i18n} = useTranslation();
    let icon: any;
    let traduction: any;
    const changeLanguage = (value: string) => {
        i18n.changeLanguage(value);
    };

    const getLangTraduction = (value: string) => {
        switch (value) {
        case 'en':
            return 'translation:web_settings_language_en';
        case 'es':
            return 'translation:web_settings_language_es';
        case 'ca':
            return 'translation:web_settings_language_cat';
        }
    };

    const getIcon = (value: string) => {
        if (i18n.language === value) {
            icon = createElement(Check);
        } else {
            icon = '';
        }

        return icon;
    };

    return <div className="lang-content">
        {languages.map((value, index) => {
            traduction = getLangTraduction(value);
            getIcon(value);

            return (
                <div
                    className="language-class"
                    key={index}
                >
                    <RadioButton
                        changeFunc={changeLanguage}
                        checkedOption={i18n.language}
                        checked={value}
                        label={traduction}
                        value={value}
                    />
                </div>
            );
        })}
    </div>;
};
