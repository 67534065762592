import React, {useState} from 'react';
import {LayerProvider} from './layer.context';

export const LayerService = ({children}: any) => {
    const [visible, setVisible] = useState(false);
    const initValue: any = {};
    const [activeFilters, setactiveFilters] = useState(initValue);

    const toggleVisible = (value: any = null) => {
        if (!!value) {
            setVisible(value);
        } else {
            setVisible(!visible);
        }
    };
    const toggleActive = (type: string, active: boolean) => {
        if (!activeFilters[type]) {
            activeFilters[type] = null;
        }
        activeFilters[type] = active;
        setactiveFilters(activeFilters);
    };

    return <LayerProvider value={{visible, toggleVisible, activeFilters, toggleActive}}>
        {children}
    </LayerProvider>;
};
