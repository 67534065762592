import React, {useCallback, useContext, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useParams} from 'react-router-dom';
import ButtonSection from '../../components/buttons/button-section/button-section';
import {MAP_VIEW} from '../../core/constants';
import {DataContext} from '../../services/data-v2/data.context';
import {HeaderContext} from '../../services/header/header.context';
import {MapContext} from '../../services/map-v2/map.context';
import {AmbIcon} from '../amb-icon/amb-icon.component';
import {ItinerarySelectorComponent} from '../itinerary-selector/itinerary-selector.component';
import {NavOptionsComponent} from '../nav-options/nav-options.component';
import {LineDetailStopsComponent} from './line-detail-stops.component';

import './line-detail.scss';

interface TabInterface {
    id: number,
    translate: string,
    visible: boolean,
};

export const TABS = [
    {id: 1, translate: 'translation:transport_line_detail_tab_route', visible: true},
    {id: 2, translate: 'translation:transport_line_detail_tab_schedules', visible: true},
    {id: 3, translate: 'translation:web_nav_information', visible: false},
];

export const LineDetailComponent = ({buttons}: { buttons: string[] }) => {

    const {routeId, stopId} = useParams<any>();

    const {
        ready,
        getCurrentStop,
        currentStop,
        currentRoute,
        currentRouteAlerts,
        getCurrentRoute,
        currentTrip,
        setCurrentTrip,
    } = useContext(DataContext);
    const {setView} = useContext(MapContext);
    const {initHeader} = useContext(HeaderContext);

    const [selectedTab, setSelectedTab] = useState<TabInterface>(TABS[0]);

    const setHeader = useCallback(() => {
        let headerConfig = {};
        if (!isMobile && currentRoute) {
            headerConfig = {
                title: currentRoute?.longName || '',
                backIcon: true,
                backToPrevius: false,
                closeIcon: false,
                logoIcon: false,
                iconComponent: <AmbIcon
                    text={currentRoute.shortName}
                    color={currentRoute.textColor}
                    backgroundColor={currentRoute.color}
                    shape={currentRoute.slug === 'busamb' ? 'round' : 'square'}
                    size="m"/>,
                customClassName: {
                    'backgroundColor': 'var(--gris-fons-amb)',
                },
                packPath: currentStop ? `/stops/${currentStop.id}` : '/home',
            };
            initHeader(headerConfig);
        }
    }, [currentRoute]);

    useEffect(() => {

        if (ready) getCurrentRoute(routeId);
        return () => {
            getCurrentRoute();
        };
    }, [routeId, ready]);

    useEffect(() => {

        if (ready) getCurrentStop(stopId);
        return () => {
            getCurrentStop();
        };
    }, [stopId, ready]);

    useEffect(() => {
        setHeader();
    }, [currentRoute]);

    useEffect(() => {
        setView(MAP_VIEW.ROUTE);
        return () => {
            setView(MAP_VIEW.STOPS);
        };
    }, []);

    return <div className={`line-detail-container${isMobile ? ' mobile' : ''}`}>
        {
            isMobile && currentRoute && <div className="mobile-header">
                <AmbIcon
                    text={currentRoute.shortName}
                    color={currentRoute.textColor}
                    backgroundColor={currentRoute.color}
                    shape={currentRoute.slug === 'busamb' ? 'round' : 'square'}
                    size="m"/>
                <div>{currentRoute.longName}</div>
            </div>
        }

        {
            currentRoute && <ButtonSection txtButtons={buttons}/>
        }

        {
            currentRoute && <div className="pin_nav-tabs" style={{
                ...(isMobile && {'padding': '0 20px'}),
            }}>
                <NavOptionsComponent value={{
                    tabOptions: TABS,
                    changeOption: setSelectedTab,
                    selectedOption: selectedTab,
                }}/>
            </div>
        }

        {
            selectedTab.id === 1 && currentRoute && <>
                <ItinerarySelectorComponent
                    route={currentRoute}
                    itineraries={currentRoute.tripsPaths.map((trip, index) => ({name: trip.tripHeadsign, id: index}))}
                    selected={currentTrip}
                    onChange={setCurrentTrip}
                />

                <LineDetailStopsComponent/>
            </>

        }

        {
            selectedTab.id === 2 && currentRoute && <div className="time-table">
                {
                    currentRoute.services.map((service, key) => <div key={key}>
                        <div className="period">
                            {service.description}
                        </div>
                        <div className="times">
                            {service.timetableDescription}
                        </div>
                    </div>)
                }
            </div>
        }
    </div>;
};
