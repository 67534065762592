import React, {Dispatch, SetStateAction} from 'react';
import {INIT_COORDS, MAP_VIEW} from '../../core/constants/map.constants';
import {MessageKeys} from '../../core/constants/map.enums';
import {Map} from '../../core/map/map';

export interface MapCtx {
    map?: Map,
    ready: boolean,
    init: (element: HTMLElement) => Promise<Map | undefined>,
    geolocationGranted: boolean,
    warningLocationVisibility: MessageKeys,
    goToCurrentLocation: () => Promise<void>,
    currentLocation: google.maps.LatLngLiteral,
    setView: Dispatch<SetStateAction<MAP_VIEW>>,
    pointer: google.maps.LatLngLiteral,
}

export const MapContext = React.createContext<MapCtx>(
    {
        ready: false,
        init: () => new Promise(Promise.resolve),
        geolocationGranted: false,
        warningLocationVisibility: MessageKeys.NONE,
        goToCurrentLocation: () => new Promise(Promise.resolve),
        currentLocation: INIT_COORDS,
        setView: () => {
        },
        pointer: INIT_COORDS,
    },
);

export const MapProvider = MapContext.Provider;
