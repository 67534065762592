import React, {useCallback, useContext, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Subject} from 'rxjs';
import {debounceTime, skip, takeUntil} from 'rxjs/operators';
import {INIT_COORDS, MAP_VIEW, MARKER_HEIGHT, MARKER_SELECTED_HEIGHT, MARKER_SELECTED_WIDTH, MARKER_WIDTH} from '../../core/constants/map.constants';
import {MessageKeys} from '../../core/constants/map.enums';
import {useQuery} from '../../core/hooks/query.hook';
import {GoogleApiLoader} from '../../core/map/google-maps-api.loader';
import {Map} from '../../core/map/map';
import {DataContext} from '../data-v2/data.context';
import {ThemeContext} from '../theme/themeContext';

import './clusters.scss';
import {MapProvider} from './map.context';

export const MapService = (props: any) => {

    const {t} = useTranslation();
    const history = useHistory();
    const query = useQuery();

    const {getTheme} = useContext(ThemeContext);
    const {db, pinSet, currentStop, visibleStops, closeStops, currentRoute, tripCoords, currentTrip, setCurrentTrip} = useContext(DataContext);

    const [map, setMap] = useState<Map>();
    const [geolocationGranted, setGeolocationGranted] = useState<boolean>(false);
    const [ready, setReady] = useState<boolean>(false);

    const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral>(INIT_COORDS);
    const [pointer, setPointer] = useState<google.maps.LatLngLiteral>(INIT_COORDS);

    const [view, setView] = useState<MAP_VIEW>(MAP_VIEW.STOPS);

    const [warningLocationVisibility, setWarningLocationVisibility] = useState<MessageKeys>(MessageKeys.NONE);

    const unsubscribe$: Subject<boolean> = new Subject<boolean>();

    const init = useCallback(async (element: HTMLElement): Promise<Map | undefined> => {

        let coreMap = map;

        const permission = await navigator.permissions.query({name: 'geolocation'});
        setGeolocationGranted(permission.state === 'granted');

        permission.onchange = async (event) => {
            const permission = await navigator.permissions.query({name: 'geolocation'});
            setGeolocationGranted(permission.state === 'granted');
        };

        if (element && !coreMap) {
            await GoogleApiLoader.loadScript();
            coreMap = new Map(element, getTheme());
            setMap(coreMap);
        }

        setReady(true);

        return coreMap;
    }, [map]);

    const goToCurrentLocation = useCallback(async (): Promise<void> => {

        if (navigator.geolocation) {
            try {
                await map?.setCurrentLocationMarker();
                setWarningLocationVisibility(MessageKeys.NONE);
                setCurrentLocation(map?.currentLocation || INIT_COORDS);
            } catch (e) {
                setWarningLocationVisibility(MessageKeys.ENABLE);
                if (!isMobile) {
                    alert(t('commons_dialog_enable_location_message'));
                }
                throw e;
            }

        } else {
            setWarningLocationVisibility(MessageKeys.NO_SUPPORT);
            throw new Error('Geolocation not supported by browser');
        }
    }, [map]);

    const boundsHandler = useCallback((bounds: google.maps.LatLngBoundsLiteral) => {
        db?.dataSources?.stops.setBounds(bounds);
    }, [db]);

    const pointerHandler = useCallback((pointer: google.maps.LatLngLiteral | null) => {

        setPointer(pointer || map?.currentLocation || INIT_COORDS);
        if (pointer) {
            history.push(`/close-stops?type=${query.type || '0'}&lat=${pointer.lat}&lng=${pointer.lng}`);
        }
    }, [query, db]);

    const drawRouteTrips = useCallback(async () => {

        map?.clearTrips();

        if (tripCoords.length && currentRoute) {

            tripCoords
                .forEach((trip, index) => {

                    const polyline = new google.maps.Polyline({
                        path: trip.polyline,
                        geodesic: true,
                        strokeColor: trip.index === currentTrip ? currentRoute?.color : '#6d6d6d',
                        strokeOpacity: 1.0,
                        strokeWeight: trip.index === currentTrip ? 3 : 2,
                        zIndex: trip.index === currentTrip ? 2 : 1,
                    });

                    polyline.addListener('click', () => {
                        setCurrentTrip(index);
                    });

                    const stops = trip.stops.map((stop, index) => {

                        const path = index === trip.stops.length - 1 ? 'M -1,-1 1,-1 1,1 -1,1 z' : google.maps.SymbolPath.CIRCLE;
                        const fillColor = stop.id === currentStop?.id ?
                            '#ffffff' :
                            index === trip.stops.length - 1 || index === 0 ?
                                '#ffffff' :
                                trip.index === currentTrip ?
                                    currentRoute?.color :
                                    '#6d6d6d';
                        const strokeColor = stop.id === currentStop?.id ?
                            '#000000' :
                            trip.index === currentTrip ?
                                currentRoute?.color :
                                '#6d6d6d';
                        const strokeWeight = stop.id === currentStop?.id ? 3 :
                            index === trip.stops.length - 1 || index === 0 ? 2 : 1;


                        const marker: google.maps.Marker = new google.maps.Marker({
                            position: {
                                lat: stop.lat,
                                lng: stop.lng,
                            },
                            icon: {
                                path,
                                scale: stop.id === currentStop?.id ? 6 : 5,
                                fillColor,
                                fillOpacity: 1,
                                strokeColor,
                                strokeWeight,
                            },
                            zIndex: trip.index === currentTrip ? 2 : 1,
                            title: stop.stopName,
                        });

                        marker.addListener('click', () => {
                            history.push(`/stops/${stop.id}`);
                        });

                        return marker;
                    });

                    if (index === currentTrip) {
                        const bounds = new google.maps.LatLngBounds();
                        const points = polyline.getPath().getArray();
                        points.forEach((point) => bounds.extend(point));
                        map?.fitBounds(bounds);
                    }

                    map?.drawTrip(polyline, trip.index === currentTrip ? stops : []);
                });
        }
    }, [map, currentStop, currentRoute, currentTrip, tripCoords]);

    useEffect(() => {

        if (map) {

            if (geolocationGranted) {
                setWarningLocationVisibility(MessageKeys.NONE);
                goToCurrentLocation();
            } else {
                setWarningLocationVisibility(MessageKeys.ENABLE);
            }
        }
    }, [map, geolocationGranted]);

    useEffect(() => {

        if (map && db) {
            map.bounds$
                .pipe(
                    debounceTime(300),
                    takeUntil(unsubscribe$),
                )
                .subscribe(boundsHandler);

            map.pointer$
                .pipe(
                    takeUntil(unsubscribe$),
                    skip(1),
                )
                .subscribe(pointerHandler);
        }

        return () => {
            unsubscribe$.next(true);
            unsubscribe$.complete();
        };

    }, [map, db]);

    useEffect(() => {

        const stops = view === MAP_VIEW.STOPS ? visibleStops : view === MAP_VIEW.CLOSE_STOPS ? closeStops : [];

        if (pinSet) {

            map?.drawMarkers(stops.map((stop) => {
                const marker = new google.maps.Marker({
                    position: {
                        lat: stop.lat,
                        lng: stop.lng,
                    },
                    clickable: true,
                    icon: {
                        url: pinSet[stop.slug].pin,
                        scaledSize: new google.maps.Size(
                            stop.id === currentStop?.id ? MARKER_SELECTED_WIDTH : MARKER_WIDTH,
                            stop.id === currentStop?.id ? MARKER_SELECTED_HEIGHT : MARKER_HEIGHT,
                        ),
                    },
                    optimized: true,
                });

                marker.set('id', `${stop.id}${stop.id === currentStop?.id ? ':selected' : ''}`);

                marker.addListener('click', () => {

                    history.push(`/stops/${stop.id}`);
                });

                return marker;
            }));
        }

    }, [pinSet, visibleStops, closeStops, currentStop, view]);

    useEffect(() => {
        if (currentStop) map?.zoomPosition({lat: currentStop.lat, lng: currentStop.lng});
    }, [currentStop]);

    useEffect(() => {

        drawRouteTrips();
    }, [tripCoords, currentRoute, currentTrip, currentStop]);

    useEffect(() => {
        db?.dataSources?.stops.setPointer(pointer);
    }, [pointer]);


    return <MapProvider value={{
        map,
        ready,
        init,
        geolocationGranted,
        warningLocationVisibility,
        goToCurrentLocation,
        currentLocation,
        setView,
        pointer,
    }}>
        {props.children}
    </MapProvider>;
};
