import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {animated, useSpring} from 'react-spring';
import {DskPageContext} from '../../services/desktop-page/desktop-page.context';
import {LayerContext} from '../../services/layer/layer.context';
import {Spinner} from '../spinner/spinner';
import MapFilter from './map-filter';

import './map-filter-component.scss';

export function MapFilterComponent() {
    const {t} = useTranslation();
    const LayerCtx = useContext(LayerContext);
    const DskPageCtx = useContext(DskPageContext);
    const isOpen = LayerCtx.visible;

    const handleClick = () => {
        LayerCtx.toggleVisible();
        DskPageCtx.toggleVisible();
    };


    const {
        bottom,
        opacity,
    } = useSpring({
        from: {
            bottom: '29px',
            backgroundColor: '#ffffff',
            opacity: 1,
        },
        bottom: isOpen ? '340px' : getBottom(),
        color: isOpen ? '#da322a' : '#ffffff',
        opacity: isOpen ? 1 : 0,
    });

    function getBottom() {
        return `${window.innerHeight - 125}px`;
    };

    return <>
        <div className={LayerCtx.visible ? 'mapFilter-show' : 'mapFilter-hide'}>
            <animated.div style={{bottom}} className="mapFilter-container">
                <animated.div style={{opacity}}>
                    <div className="mapFilter-header">
                        <h1>{t('translation:commons_map_layers_title')}</h1>
                        <span
                            role="button"
                            className="close"
                            onClick={handleClick}
                            onKeyDown={handleClick}
                            tabIndex={0}
                        ></span>
                    </div>
                </animated.div>
                <Spinner/>
                <MapFilter/>
            </animated.div>
        </div>
    </>;
}

export default MapFilterComponent;
