export const ALERTS_TABS = [
    {
        id: 1,
        translate: 'translation:misc_alerts_issues_tab_lines',
        placeHolder: 'translation:web_search_placeholder_lines',
        visible: true,
    },
    {
        id: 2,
        translate: 'translation:misc_alerts_issues_tab_stations',
        placeHolder: 'translation:web_search_placeholder_stops',
        visible: true,
    },
    {
        id: 3,
        translate: 'alerts:search_list_results',
        placeHolder: 'translation:web_search_placeholder_stops',
        visible: false,
    },
];
