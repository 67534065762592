import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {AlertsComponent} from '../../../clean-components/alerts/alerts.component';
import {DesktopPage} from '../../../clean-components/desktop-page/desktop-page';
import {HomeNavComponent} from '../../../clean-components/home-nav/home-nav.component';
import {Lines} from '../../../clean-components/lines/lines';
import {SearchComponent} from '../../../clean-components/search/search.component';
import {DataContext} from '../../../services/data-v2/data.context';
import {HeaderContext} from '../../../services/header/header.context';

export const HomeDesktop = () => {

    const {pathname} = useLocation();

    const HeaderService = useContext(HeaderContext);
    const {ready} = useContext(DataContext);

    const getActiveOption = (path: string): number => {
        return path.includes('alerts') ? 3 : path.includes('line') ? 2 : 1;
    };

    const [navOption, setNavOption] = useState<number>(getActiveOption(pathname));

    const getTabComponent = useCallback(() => {
        switch (navOption) {
        case 1:
            return <SearchComponent/>;
        case 2:
            return <Lines/>;
        case 3:
            return <AlertsComponent/>;
        }
    }, [navOption]);

    useEffect(() => {
        if (ready) {
            const headerConfig = {
                logoIcon: true,
                menu: true,
            };
            HeaderService.initHeader(headerConfig);
        }
    }, [ready]);

    useEffect(() => {

        setNavOption(getActiveOption(pathname));
    }, [pathname]);

    return <DesktopPage>
        <HomeNavComponent selectedOption={navOption}/>
        {getTabComponent()}
    </DesktopPage>;
};
