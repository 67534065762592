import React, {createElement, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as batteryIcon} from '../../assets/icons/bikes/iconBattery.svg';
import {ReactComponent as iconGo} from '../../assets/icons/lines/system-quieroir-1.svg';
import {SharedServiceStatus, StopModel} from '../../core/models';
import {goTo} from '../../core/utils';
import {DataContext} from '../../services/data-v2/data.context';
import {TimeWalkComponent} from '../time-walk/time-walk.component';

import './moto-stop-detail.scss';

export const MotoStopDetailComponent = ({
    stop,
}: {
    stop: StopModel,
}) => {

    const {t} = useTranslation();

    const {pinSet, realTimes} = useContext(DataContext);
    const [status, setStatus] = useState<SharedServiceStatus | null>(null);

    const realTimesHandler = useCallback(() => {
        setStatus(realTimes[stop.id].status || null);
    }, [stop, realTimes]);

    useEffect(() => {
        realTimesHandler();
    }, [realTimes]);

    return <div className="moto-stop">

        <div className="stop-header">
            {
                pinSet && <img
                    src={pinSet[stop.slug].logo}
                    alt="logo"
                    className="logo"
                />
            }
            <button className="go-to-button" onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                goTo(stop);
            }}>
                {createElement(iconGo)}
                {t('translation:transport_sheet_toolbar_indications')}
            </button>
        </div>

        <div className="stop-info">
            <div className="title">
                {stop.stopName}
                <TimeWalkComponent coords={stop} size="s"/>
            </div>
            {
                status && <div className="stop-status">
                    {
                        !!status.battery && < div className="battery">
                            {createElement(batteryIcon)}
                            <div>
                                {`${status.battery} %`}
                            </div>
                        </div>
                    }

                    {
                        !!status.range && < div className="range">
                            {createElement(batteryIcon)}
                            <div>
                                {`${status.range / 1000} Km`}
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>;
};
