import React, {createElement, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ReactComponent as parkingIcon} from '../../assets/icons/bikes/bici-aparcamientos.svg';
import {ReactComponent as mechanicalBikeIcon} from '../../assets/icons/bikes/bici-mecanica.svg';
import {ReactComponent as placeIcon} from '../../assets/icons/lines/system-place.svg';
import {ReactComponent as iconGo} from '../../assets/icons/lines/system-quieroir-1.svg';
import {RealTimes} from '../../core/data/real-time-worker';
import {SharedServiceStatus, StopModel} from '../../core/models';
import {goTo} from '../../core/utils';
import {DataContext} from '../../services/data-v2/data.context';
import {MapContext} from '../../services/map-v2/map.context';
import {TimeWalkComponent} from '../time-walk/time-walk.component';

import './bike-stop-detail.scss';

export const BikeStopDetailComponent = ({
    stop,
}: {
    stop: StopModel,
}) => {

    const {t} = useTranslation();

    const {pinSet, realTimes} = useContext(DataContext);
    const [status, setStatus] = useState<SharedServiceStatus | null>(null);

    const realTimesHandler = useCallback(() => {
        setStatus(realTimes[stop.id]?.status || null);
    }, [stop, realTimes]);

    useEffect(() => {
        realTimesHandler();
    }, [realTimes]);


    return <div className="bike-stop">

        <div className="stop-header">
            {
                pinSet && <img
                    src={pinSet[stop.slug].logo}
                    alt="logo"
                    className="logo"
                />
            }
            <button className="go-to-button" onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                goTo(stop);
            }}>
                {createElement(iconGo)}
                {t('translation:transport_sheet_toolbar_indications')}
            </button>
        </div>

        <div className="stop-info">
            <div className="title">
                {`${t('translation:transport_marker_detail_other_stop_subtitle')} nº ${stop.stopCode}`}
                <TimeWalkComponent coords={stop} size="s"/>
            </div>
            {
                stop.stopDesc && <div className="address">
                    <div className="marker">{createElement(placeIcon)}</div>
                    <div>{stop.stopDesc}</div>
                </div>
            }
        </div>
        <div className="bike-station-data">
            <div className="data-item">
                {createElement(parkingIcon)}
                <div className="value">
                    {status ? status.docksAvailable : 0}
                </div>
                <div className="label">{t('translation:transport_bicing_station')}</div>
            </div>
            <div className="data-item">
                {createElement(mechanicalBikeIcon)}
                <div className="value">
                    {status ? status.bikesAvailable : 0}
                </div>
                <div className="label">{t('translation:transport_bicing_mechanical')}</div>
            </div>
        </div>
    </div>;
};
