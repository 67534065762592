import React, {createElement} from 'react';

import {Link, Redirect, Route, Switch} from 'react-router-dom';
import {Header} from '../../clean-components/header/header';
import {LocationButtonComponent} from '../../clean-components/map/location-button.component';
import SearchButtonComponent from '../../components/buttons/search-button/search-button';
import TransportsButtonComponent from '../../components/buttons/transports-button/transports-button';
import {BottomSheetService} from '../../services/bottom-sheet/bottom-sheet';
import {LayerService} from '../../services/layer/layer.service';

import {mobileRoutingConfig} from './mobile-routing.config';

export const MobileRouting = () => {
    return <>
        <LayerService>
            <BottomSheetService>
                <SearchButtonComponent/>
                <Link to={{pathname: '/map-filters'}}>
                    <TransportsButtonComponent/>
                </Link>;
                <LocationButtonComponent/>
                <Header/>
                <Switch>
                    {mobileRoutingConfig.routes.map((route, key) => {
                        return <Route key={key} exact path={route.path}>
                            {createElement(route.component)}
                        </Route>;
                    })}
                    <Redirect to={mobileRoutingConfig.default}/>
                </Switch>
            </BottomSheetService>
        </LayerService>
    </>;
};
