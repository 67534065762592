import markerBase from '../../assets/icons/map/marker-base.svg';
import walkBase from '../../assets/icons/map/walk.svg';


export const fileToBase64 = async (path: string): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {

            const response = await fetch(path);

            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as string);
            };

            reader.readAsDataURL(await response.blob());

            return response;
        } catch (e) {
            reject(e);
        }
    });
};
export const createPin = async (icon: string, color: string) => {
    const pin = await getPinBase(color);

    const canvas = document.createElement('canvas');
    canvas.width = 260;
    canvas.height = 330;
    const ctx = canvas.getContext('2d');
    if (ctx) {
        await drawBase64Image(ctx, pin, 0, 0, 260, 330);
        await drawBase64Image(ctx, icon, 60, 60, 140, 140);
    }

    return canvas.toDataURL();
};

export const createWalkInfoPin = async (time: number) => {
    const svg = await getSvgElement(walkBase);

    if (svg) {
        const base = `data:image/svg+xml;base64,${btoa(new XMLSerializer().serializeToString(svg))}`;

        const canvas = document.createElement('canvas');
        canvas.width = 600;
        canvas.height = 500;
        const ctx = canvas.getContext('2d');

        if (ctx) {
            await drawBase64Image(ctx, base, 220, 0, 160, 240);
            ctx.font = 'bold 200px Arial';
            ctx.textAlign = 'center';
            ctx.fillStyle = '#a3958a';
            ctx.fillText(`${time} min`, 300, 450);
        }

        return canvas.toDataURL();
    }
};

export const drawBase64Image = async (
    ctx: CanvasRenderingContext2D,
    image: string,
    x: number,
    y: number,
    w: number,
    h: number,
): Promise<CanvasRenderingContext2D> => {

    return new Promise((resolve, reject) => {
        const i = new Image();
        i.onload = function() {
            ctx.drawImage(i, x, y, w, h);
            resolve(ctx);
        };
        i.src = image;
    });
};

export const getSvgElement = async (path: string): Promise<SVGSVGElement | null> => {

    return new DOMParser()
        .parseFromString(await (await fetch(path)).text(), 'image/svg+xml')
        .querySelector('svg');
};

const getPinBase = async (color: string): Promise<string> => {

    try {
        const svg = await getSvgElement(markerBase);

        if (svg) {
            const g = svg.querySelector('g');
            g?.setAttribute('fill', color);
            return `data:image/svg+xml;base64,${btoa(new XMLSerializer().serializeToString(svg))}`;
        }

        return '';
    } catch (e) {
        throw e;
    }
};
