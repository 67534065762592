import React, {useContext} from 'react';
import {isMobile} from 'react-device-detect';
import {animated, useSpring} from 'react-spring';
import {SidebarContext} from '../../services/sidebar/sidebar.context';


import './sidebar.scss';

export const SidebarComponent = ({children}: any) => {
    const {visible, hide} = useContext(SidebarContext);

    const {left} = useSpring({
        from: {left: '-100%'},
        left: visible ? '0' : '-100%',
    });
    return <>
        {visible && <div
            className="overlay"
            onClick={hide}
            onKeyDown={hide}
            role="button"
            tabIndex={0}
        />}
        <animated.div
            style={{left: left}}
            className={`sidebar ${!isMobile ? 'desktop' : ''}`}
        >
            {children}
        </animated.div>
    </>;
};

