import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {BottomPage} from '../../../clean-components/bottom-page/bottom-page';
import {CloseStopsComponent} from '../../../clean-components/close-stops/close-stops.component';
import {BackHomeButton} from '../../../components/buttons/backHome-button/backHome-button';

export function CloseStopsMobile() {
    const location = useLocation();

    useEffect(() => {
        document.getElementsByClassName('header')[0].classList.add('hide');
        document.getElementsByClassName('search-button')[0].classList.add('hide');
        document.getElementsByClassName('transports-button')[0].classList.add('hide');
    }, [location]);

    return <>
        <BackHomeButton/>
        <BottomPage maximizedOnCreate={true} medium={true}>
            <CloseStopsComponent/>
        </BottomPage>
    </>;
}
