import {AlertDetailMobile} from './alerts-detail-mobile/alert-detail.mobile';
import {AlertsMobile} from './alerts-mobile/alerts.mobile';
import {CloseStopsMobile} from './close-stops-mobile/close-stops.mobile';
import {ContactMobile} from './contact-mobile/contact.mobile';
import {HomeMobile} from './home-mobile/home.mobile';
import {LineDetailMobile} from './line-detail-mobile/line-detail.mobile';
import {LinesMobile} from './lines-mobile/lines.mobile';
import {MapFilterMobile} from './map-filter-mobile/map-filter.mobile';
import {SettingsMobile} from './settings-mobile/settings.mobile';
import {StopDetailMobile} from './stop-detail-mobile/stop-detail.mobile';

export const mobileRoutingConfig = {
    default: '/home',
    routes: [
        {
            path: '/home',
            component: HomeMobile,
            disabled: false,
        },
        {
            path: '/lines',
            component: LinesMobile,
            disabled: false,
        },
        {
            path: ['/lines/:routeId', '/lines/:routeId/stop/:stopId'],
            component: LineDetailMobile,
            disabled: false,
        },
        {
            path: '/alerts',
            component: AlertsMobile,
            disabled: false,
        },
        {
            path: '/alerts/:type/:id',
            component: AlertDetailMobile,
            disabled: false,
        },
        {
            path: '/settings',
            component: SettingsMobile,
            disabled: false,
        },
        {
            path: '/stops/:stopId',
            component: StopDetailMobile,
            disabled: false,
        },
        {
            path: '/close-stops',
            component: CloseStopsMobile,
            disabled: false,
        },
        {
            path: '/contact',
            component: ContactMobile,
            disabled: false,
        },
        {
            path: '/map-filters',
            component: MapFilterMobile,
            disabled: false,
        },
        // {
        //     path: '/home',
        //     component: Home,
        //     disabled: false,
        // },
        // {
        //     path: '/serveis-parada',
        //     component: CloseServicesList,
        //     disabled: false,
        // },
        // {
        //     path: '/serveis-aprop',
        //     component: CloseServices,
        //     disabled: false,
        // },
        // {
        //     path: '/serveis-compartits',
        //     component: SharedTransportsList,
        //     disabled: false,
        // },
        // {
        //     path: '/stop/:id',
        //     component: Pin,
        //     disabled: false,
        // },
        // {
        //     path: '/stop-route/:id',
        //     component: StopTouteDetail,
        //     disabled: false,
        // },
        // {
        //     path: '/stop/:id/detail',
        //     component: AllStopDetail,
        //     disabled: false,
        // },
        // {
        //     path: '/place/:id',
        //     component: PinPlace,
        //     disabled: false,
        // },
        // {
        //     path: '/bicing/:id',
        //     component: PinBicing,
        //     disabled: false,
        // },
        // {
        //     path: '/taxi/:id',
        //     component: PinTaxi,
        //     disabled: false,
        // },
        // {
        //     path: '/moto/:id',
        //     component: PinMoto,
        //     disabled: false,
        // },
        // {
        //     path: '/scooter/:id',
        //     component: PinScooter,
        //     disabled: false,
        // },
        // {
        //     path: '/car/:id',
        //     component: PinCar,
        //     disabled: false,
        // },
        // {
        //     path: '/line-timeline/:id',
        //     component: PinTimeline,
        //     disabled: false,
        // },
        // {
        //     path: '/line-timeline/:id/:stop',
        //     component: PinTimeline,
        //     disabled: false,
        // },
        // {
        //     path: '/account',
        //     component: Account,
        //     disabled: false,
        // },
        // {
        //     path: '/settings',
        //     component: Settings,
        //     disabled: false,
        // },
        // {
        //     path: '/alerts',
        //     component: Alerts,
        //     disabled: false,
        // },
        // {
        //     path: '/alerts/:id',
        //     component: AlertDetail,
        //     disabled: false,
        // },
        // {
        //     path: '/alerts-filters',
        //     component: AlertsFilter,
        //     disabled: false,
        // },
        // {
        //     path: '/lines',
        //     component: Lines,
        //     disabled: false,
        // },
        // {
        //     path: '/maps',
        //     component: Maps,
        //     disabled: false,
        // },
        // {
        //     path: '/services',
        //     component: Services,
        //     disabled: false,
        // },
        // {
        //     path: '/rates',
        //     component: Rates,
        //     disabled: false,
        // },
        // {
        //     path: '/buy',
        //     component: Buy,
        //     disabled: false,
        // },


    ],
};
