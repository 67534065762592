import React, {useContext, useState} from 'react';
import {RadioButton} from '../../components/buttons/radio-button/radio-button';
import {THEMES} from '../../core/constants';
import {ThemeContext} from '../../services/theme/themeContext';
import {analyticsEvent} from '../../utils/analytics';
import {themeOptions} from '../../utils/utils';

export const SettingsStyleComponent = () => {
    const ThemeService = useContext(ThemeContext);
    const [checked, sethecked] = useState(localStorage.getItem('theme') || themeOptions[0]);


    const changeTheme = (value: any, e: any) => {
        analyticsEvent('Configuració', 'configuration_themeselector_click');
        if (value.value === themeOptions[0]) {
            ThemeService.setTheme(themeOptions[0]);
        } else {
            ThemeService.setTheme(themeOptions[1]);
        }
        sethecked(value.value);
        window.location.reload();
    };
    return <div>
        {THEMES.map((value: any, index: number) => {
            return (
                <div
                    className="search-radio-button-group"
                    key={index}>
                    <RadioButton
                        changeFunc={changeTheme}
                        checkedOption={value.value}
                        checked={checked}
                        label={value.title}
                        value={value}
                    />
                </div>
            );
        })}
    </div>;
};
