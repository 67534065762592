import {RxCollection} from 'rxdb';
import {PinModel} from '../../models/pin.model';
import {DataSource} from './data-source';

export class PinDataSource extends DataSource<PinModel> {

    constructor(collection: RxCollection) {
        super(collection);
    }

    public async getPinSet(): Promise<{ [slug: string]: PinModel }> {
        const pins = await this.find();
        return pins.reduce((set, pin) => ({...set, [pin.id]: pin}), {});
    }
}
