import React, {createElement} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as emptylinkIcon} from '../../assets/icons/transports/no-results.svg';

import './errorPage.scss';

export default function ErrorPage(props: any) {
    const {t} = useTranslation();
    return (<div className="empty-link">
            <div className="empty-link-icon">
                {createElement(emptylinkIcon)}
            </div>
            <p className="empty-link-description">
                {t('translation:transport_marker_detail_timetable_error_text')}
            </p>
        </div>
    );
};
