import {LinesApi} from '../api';
import {Db} from '../data/db';
import {RouteModel, TripCoords} from '../models';
import {getDistance, getProjectionPoint, splitLine} from './math.utils';

export const getTripCoords = async (route: RouteModel, db: Db): Promise<TripCoords[]> => {
    const tripsCoords = [];

    for (const [index, tripPath] of Array.from(route.tripsPaths.entries())) {

        let polyline: google.maps.LatLngLiteral[] = [];
        const stops = [];

        if (tripPath.shapeId) {

            polyline = (await LinesApi.getRouteShapes(route.slug, tripPath.shapeId) || [])
                .reduce((arr, point) => {

                    const p = {lat: point.shape_pt_lat, lng: point.shape_pt_lon};
                    if (arr.length) {

                        if (getDistance(arr[arr.length - 1], p) > 10) {
                            const last = arr.pop();
                            return [
                                ...arr,
                                ...splitLine(last, p, 10),
                            ];
                        }
                        return arr;
                    }

                    return [p];
                }, [] as google.maps.LatLngLiteral[]);

        }

        for (const stopId of tripPath.stopIds) {

            let stop = await db?.dataSources?.stops.findOne({
                selector: {
                    stopId,
                    slug: route.slug,
                },
            });

            if (stop) {

                if (!tripPath.shapeId) {
                    polyline.push({
                        lat: stop.lat,
                        lng: stop.lng,
                    });
                } else {

                    const index = tripPath.stopIds.indexOf(stopId);

                    if (index === 0) {
                        stop = {
                            ...stop,
                            lat: polyline[0].lat,
                            lng: polyline[0].lng,
                        };
                    } else if (index === tripPath.stopIds.length - 1) {
                        stop = {
                            ...stop,
                            lat: polyline[polyline.length - 1].lat,
                            lng: polyline[polyline.length - 1].lng,
                        };
                    } else {
                        const point = getProjectionPoint(
                            {
                                lat: stop.lat,
                                lng: stop.lng,
                            },
                            polyline,
                        );

                        stop = {
                            ...stop,
                            lat: point.lat,
                            lng: point.lng,
                        };
                    }
                }

                stops.push(stop);
            }
        }

        tripsCoords.push(
            {
                index,
                name: tripPath.tripHeadsign,
                polyline,
                stops,
            },
        );
    }

    return tripsCoords;
};
