import React, {createElement, useCallback, useContext, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {ReactComponent as iconWarningLocation} from '../../assets/icons/map/map-geo-si-no-bg.svg';
import {RadioButton} from '../../components/buttons/radio-button/radio-button';
import {MessageKeys} from '../../core/constants/map.enums';
import {RouteModel, StopModel} from '../../core/models';
import {diacriticSensitiveRegex} from '../../core/utils';
import {DataContext} from '../../services/data-v2/data.context';
import {MapContext} from '../../services/map-v2/map.context';
import {AmbIcon} from '../amb-icon/amb-icon.component';
import {ListItem} from '../list/list-item.component';
import {List} from '../list/list.component';
import {ListItemInterface} from '../list/list.interface';
import SearchBar from '../search-bar/search-bar';
import {TimeWalkComponent} from '../time-walk/time-walk.component';
import TransportsComponent from '../transports/transports';
import './search.scss';

export const SearchComponent = () => {

    const {t} = useTranslation();

    const {db, pinSet} = useContext(DataContext);
    const {warningLocationVisibility} = useContext(MapContext);
    const [searchOption, setsearchOption] = useState('translation:web_search_direction');
    const [reset, setReset] = useState(false);
    const [searching, setSearching] = useState(false);
    const [inputType] = useState('text');
    const [result, setResult] = useState<ListItemInterface<RouteModel | StopModel>[]>([]);
    const placeHolder = (searchOption === 'translation:web_search_direction') ?
        'translation:web_search_direction' : 'translation:web_search_stop_code';

    const changeRadioButtonDirection = (value: any) => {
        setsearchOption(value);
        setReset(true);
    };
    const changeRadioButtonCode = (value: any) => {
        setsearchOption(value);
        setReset(true);
        onChangeSearch('');
    };

    const onChangeSearch = useCallback(async (value: any) => {

        if (db && pinSet && value.length) {
            setSearching(true);

            const $regex = new RegExp(`.*${diacriticSensitiveRegex(value)}.*`, 'i');

            const routes = await db.dataSources?.routes.find({
                selector: {
                    $or: [
                        {longName: {$regex}},
                        {shortName: {$regex}},
                    ],
                },
            }) || [];

            const stops = await db.dataSources?.stops.find({
                selector: {
                    $or: [
                        {stopName: {$regex}},
                        {stopDesc: {$regex}},
                        {stopCode: {$regex}},
                        {stopId: {$regex}},
                    ],
                },
            }) || [];

            setResult([
                ...routes.map((route) => ({
                    id: route.id,
                    title: route.longName,
                    href: `/lines/${route.id}`,
                    icon: <AmbIcon
                        text={route.shortName}
                        color={route.textColor}
                        backgroundColor={route.color}
                        shape={route.slug === 'busamb' ? 'round' : 'square'}
                        size="m"/>,
                    data: route,
                })),
                ...stops.map((stop) => ({
                    id: stop.id,
                    title: <div style={{width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                        <div>
                            {stop.stopName}
                        </div>
                        <div style={{whiteSpace: 'nowrap'}}>
                            {`Nº ${stop.stopCode || stop.stopId}`}
                        </div>
                    </div>,
                    href: `/stops/${stop.id}`,
                    icon: <AmbIcon
                        size="m"
                        shape="square"
                        backgroundColor={pinSet[stop.slug].color}
                        img={pinSet[stop.slug].icon}/>,
                    data: stop,
                })),
            ].slice(0, 20));
        } else {
            setSearching(false);
        }

    }, [db, pinSet]);

    const onSearchFocus = (value: number) => {
        // setSearching(value > 0);
    };

    const onSearchBlur = (value: any) => {
        console.log('blur', value);
    };

    const getWarningMessage = () => {
        const messangeId = warningLocationVisibility;
        switch (messangeId) {
        case MessageKeys.ENABLE:
            return t('translation:transport_root_location_snack');
        case MessageKeys.NO_SUPPORT:
            return t('translation:warnings_location_no-support');
        }
    };

    return <div className={`search-container${isMobile ? ' mobile' : ''}`}>
        {/* <div className="search-radio-button-group">*/}
        {/*    <RadioButton*/}
        {/*        changeFunc={changeRadioButtonDirection}*/}
        {/*        checkedOption={searchOption}*/}
        {/*        checked={'translation:web_search_direction'}*/}
        {/*        label={'translation:web_search_direction'}*/}
        {/*        value={'translation:web_search_direction'}*/}
        {/*    />*/}
        {/*    <RadioButton*/}
        {/*        changeFunc={changeRadioButtonCode}*/}
        {/*        checkedOption={searchOption}*/}
        {/*        checked={'translation:web_search_stop_code'}*/}
        {/*        label={'translation:web_search_stop_code'}*/}
        {/*        value={'translation:web_search_stop_code'}*/}
        {/*    />*/}
        {/* </div>*/}
        <SearchBar
            type={inputType}
            iconSearch={true}
            backIcon={false}
            value={{
                onChangeSearch,
                onFocus: onSearchFocus,
                onBlur: onSearchBlur,
                placeHolder: 'translation:web_search_bar_placeholder',
                reset,
            }}/>
        <div className="desktop__search-container">
            {
                !searching && <>
                    <h3>{t('translation:transport_bottom_sheet_services_title')}</h3>
                    <TransportsComponent/>
                </>
            }
            {
                searching && <List items={result}/>
            }
            {
                warningLocationVisibility > MessageKeys.NONE ?
                    <div className="warning-location">
                        {createElement(iconWarningLocation)}
                        <p>{getWarningMessage()}</p>
                    </div> : null
            }
        </div>
    </div>;
};
