import React, {createElement, useContext, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {ReactComponent as BackIcon} from '../../assets/icons/buttons/back.svg';
import {ReactComponent as CloseIcon} from '../../assets/icons/buttons/system-close.svg';
import {ReactComponent as SearchIcon} from '../../assets/system-search.svg';
import {SearchBarInterface} from '../../interfaces/components/searchBarInterface';
import {BottomSheetCtx} from '../../services/bottom-sheet/bottom-sheet-context';


import './search-bar.scss';

function SearchBar(props: SearchBarInterface) {
    const placeholder = props?.value?.placeHolder || 'translation:web_search_bar_placeholder';
    const reset = props?.value?.reset || false;
    const iconSearch = props.iconSearch;
    const type = props.type;
    const backIcon = props.backIcon;
    const history = useHistory();
    const sheetService = useContext(BottomSheetCtx);
    const location = useLocation();
    const goBack = () => {
        history.goBack();
    };
    const {t} = useTranslation();
    const [searchString, setSearchString] = useState('');
    const [searching, setSearching] = useState(false);
    useEffect(() => {
        if (reset) {
            resetInput();
        }
    }, [reset, searching]);

    const onChangeSearch = (e: any) => {
        const stringSearch = e.target?.value;
        setSearchString(stringSearch);
        setSearching(true);

        if (stringSearch.length <= 0) {
            setSearching(false);
        }
        props?.value?.onChangeSearch(stringSearch);
    };

    const onFocusSearch = () => {
        if (isMobile) {
            sheetService.setMaximized(true);
        }
        if (props?.value?.onFocus) {
            props.value.onFocus(searchString.length);
        }
    };

    const resetInput = () => {
        setSearchString('');
        setSearching(false);
        props?.value?.onChangeSearch('');
    };
    // input style
    let style = {};
    if (backIcon == true && iconSearch == true) {
        style = {
            'marginLeft': '40.5px',
            'width': '55%',
        } as React.CSSProperties;
    }
    if (backIcon == false && iconSearch == false) {
        style = {
            'marginLeft': '15.5px',
            'width': '85%',
        } as React.CSSProperties;
    }
    if (backIcon == false && iconSearch == true) {
        style = {
            'marginLeft': '15.5px',
            'width': '65%',
        } as React.CSSProperties;
    }
    if (backIcon == true && iconSearch == false) {
        style = {
            'marginLeft': '40.5px',
            'width': '75%',
        } as React.CSSProperties;
    }
    // reset-button style
    let styleCloseIcon = {};
    if (iconSearch == true) {
        styleCloseIcon = {
            'right': '18%',
            'borderRight': '1px solid #dddddd',
        } as React.CSSProperties;
    } else {
        styleCloseIcon = {
            'right': '0%',
            'border': 'none',
        } as React.CSSProperties;
    }

    return (
        <div className="form-class">
            {!!backIcon ?
                <button className="back-button"
                        onClick={goBack}>
                    <BackIcon/>
                </button> :
                ''
            }
            {isMobile && location.pathname === '/home' && sheetService.currentPosition > 500 ?
                <Link to="/">
                    <button className="return-button">
                        {createElement(BackIcon)}
                    </button>
                </Link> :
                ''
            }
            {searching ?
                <button className="reset-button"
                        style={styleCloseIcon}
                        onClick={resetInput}>
                    {createElement(CloseIcon)}
                </button> :
                ''
            }
            {!!iconSearch ?
                <span className="searchBar-icon">
          {createElement(SearchIcon)}
        </span> :
                ''
            }
            <input
                value={searchString}
                className="search-bar"
                onChange={onChangeSearch}
                onFocus={onFocusSearch}
                type={type}
                onBlur={() => props?.value?.onBlur && props.value.onBlur()}
                placeholder={t(placeholder)}
                style={style}
            />
        </div>
    );
}

export default SearchBar;
