import {RxCollection} from 'rxdb';
import {ServiceGroupModel} from '../../models';
import {DataSource} from './data-source';

export class ServiceGroupDataSource extends DataSource<ServiceGroupModel> {

    constructor(collection: RxCollection) {
        super(collection);
    }
}
