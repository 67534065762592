import React from 'react';
import ContactComponent from '../../../clean-components/contact/contact.component';
import {Page} from '../../../clean-components/page/page';

export function ContactMobile() {
    return <>
        <Page title="translation:misc_info_contact_item_title">
            <ContactComponent/>
        </Page>
    </>;
}
