import React, {useContext, useEffect} from 'react';
import {AlertsDetailComponent} from '../../../clean-components/alert-detail/alerts-detail.component';
import {DesktopPage} from '../../../clean-components/desktop-page/desktop-page';
import {HeaderContext} from '../../../services/header/header.context';

export function AlertDetailDesktop({children}: any) {
    // we get the alert id
    const HeaderService = useContext(HeaderContext);
    // we get the alert id
    const headerConfig = {
        title: 'translation:app_menu_alerts_and_incidences_title',
        backIcon: true,
        closeIcon: false,
        logoIcon: false,
        customClassName: {
            'backgroundColor': 'var(--gris-fons-amb)',
        },
    };

    useEffect(() => {
        if (HeaderService.headerConfig.title != headerConfig.title) {
            HeaderService.initHeader(headerConfig);
        }
    }, [HeaderService.headerConfig]);

    return <DesktopPage config={headerConfig}>
        <AlertsDetailComponent/>
    </DesktopPage>;
}
