import React, {useContext} from 'react';
import {isMobile} from 'react-device-detect';
import {DskPageContext} from '../../../services/desktop-page/desktop-page.context';
import {LayerContext} from '../../../services/layer/layer.context';
import {analyticsEvent} from '../../../utils/analytics';
import './transports-button.scss';

function TransportsButtonComponent() {
    const DskPageCtx = useContext(DskPageContext);
    const LayerCtx = useContext(LayerContext);
    let style = {};

    const handleClick = () => {
        analyticsEvent('Home', 'home_layer_click');

        if (DskPageCtx.visible) {
            DskPageCtx.hide();
        } else {
            DskPageCtx.toggleVisible();
        }
        if (!isMobile) {
            LayerCtx.toggleVisible();
        }
    };

    if (!isMobile) {
        style = {
            'bottom': '190px',
            'right': '10px',
        } as React.CSSProperties;
    } else {
        style = {
            'top': '132px',
            'right': '16px',
        } as React.CSSProperties;
    }
    return <>
    <span
        role="button"
        className={!LayerCtx.visible ? 'opened transports-button' : 'closed transports-button'}
        style={style}
        id="transportsButton"
        onClick={handleClick}
        onKeyPress={handleClick}
        tabIndex={-20}
    >
    </span>
    </>;
}

export default TransportsButtonComponent;
