import React, {createElement, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '../../../core/hooks/query.hook';
import {analyticsEvent} from '../../../utils/analytics';
import './transport.scss';

function TransportComponent(props: any) {

    const query = useQuery();
    const history = useHistory();

    const onClick = useCallback(() => {
        analyticsEvent('Home', 'home_stationsearch_click');
        history.push(`/close-stops?type=${props.id}${query.lat && query.lng ? `&lat=${query.lat}&lng=${query.lng}` : ''}`);
    }, []);

    return <div className="transport-box">
        <div className="transport-type">
            <button
                className={`transport-btn${!props.selected || props.selected.id === props.id ? ' active' : ''}`}
                disabled={props.disabled}
                onClick={onClick}
            >
                {createElement(props.icon)}
            </button>
        </div>
        <span className="transport-title">{props.title}</span>
    </div>;
}

export default TransportComponent;
