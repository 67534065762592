import {createContext} from 'react';

export const BottomSheetCtx = createContext({
    open: true,
    initPosition: 87,
    skipInitialTransition: true,
    minHeight: 0,
    mediumHeight: 0,
    maxHeight: 0,
    topHeight: 0,
    detailHeight: 0,
    currentPosition: 87,
    maximized: false,
    detail: false,
    useMedium: false,
    setMedium: (medium: boolean) => {
    },
    setMinHeight: (height: any) => {
    },
    setMaxHeight: (height: any) => {
    },
    setMediumHeight: (height: any) => {
    },
    setTopHeight: (height: any) => {
    },
    setCurrentPosition: (height: any) => {
    },
    setMaximized: (maximized: boolean) => {
    },
    setDetail: (detail: boolean) => {
    },
});
