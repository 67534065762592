import {createContext} from 'react';

export const SpinnerContext = createContext({
    activeSpinner: () => {
    },
    deactivateSpinner: () => {
    },
    active: false,
});

export const SpinnerProvider = SpinnerContext.Provider;
