import React, {createElement, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as iconBicisWhite} from '../../assets/icons/transports/transporte-bici-white.svg';

import {ReactComponent as iconBicisDark} from '../../assets/icons/transports/transporte-bici.svg';
import {ReactComponent as iconCochesWhite} from '../../assets/icons/transports/transporte-coche-white.svg';
import {ReactComponent as iconCochesDark} from '../../assets/icons/transports/transporte-coche.svg';
import {ReactComponent as iconMotosWhite} from '../../assets/icons/transports/transporte-moto-white.svg';
import {ReactComponent as iconMotosDark} from '../../assets/icons/transports/transporte-moto.svg';
import {themeOptions} from '../../utils/utils';
import {TransportListComponent} from '../transport-list/transport-list.component';

import './accordion.scss';

export const ServicesOperatorsContentOptions = [
    {logo: 'logo1', title: 'Bicibox', description: 'Red pública de aparcamientos gratuitos y seguros para bicicletas privadas.'},
];

function AccordionFilterComponent(props: any) {
    const {t} = useTranslation();
    const [setActive, setActiveState] = useState('');
    const [setHeight, setHeightState] = useState('0px');
    const [setRotate, setRotateState] = useState('accordion__icon');
    const theme = localStorage.getItem('theme');
    const content = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === '' ? 'active' : '');
        setHeightState(setActive === '' ? '500px' : '0px');
        setRotateState(setActive === 'active' ? 'accordion_icon' : 'accordion__icon rotate');
    }

    useEffect(() => {
        localStorage.getItem('theme');
    }, [theme]);
    return <div className="accordion__section accordion_filter">
        <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
            <div className="accordion__section_single">
                {props.title == t('translation:web_show_map_icon_bici') && (theme === themeOptions[1]) ? createElement(iconBicisWhite) : ''}
                {props.title == t('translation:web_show_map_icon_car') && (theme === themeOptions[1]) ? createElement(iconCochesWhite) : ''}
                {props.title == t('translation:web_show_map_icon_moto') && (theme === themeOptions[1]) ? createElement(iconMotosWhite) : ''}

                {props.title == t('translation:web_show_map_icon_bici') && (theme === themeOptions[0]) ? createElement(iconBicisDark) : ''}
                {props.title == t('translation:web_show_map_icon_car') && (theme === themeOptions[0]) ? createElement(iconCochesDark) : ''}
                {props.title == t('translation:web_show_map_icon_moto') && (theme === themeOptions[0]) ? createElement(iconMotosDark) : ''}
                <p className="accordion__title">{props.title}</p>
            </div>
            <svg
                className={`${setRotate}`}
                width="1rem"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 14 9">
                <defs>
                    <filter id="5u5eagi6aa">
                        <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.855826 0 0 0 0 0.195840 0 0 0 0 0.165660 0 0 0 1.000000 0"/>
                    </filter>
                </defs>
                <g fill="none">
                    <g filter="url(#5u5eagi6aa)" transform="translate(-341 -166)">
                        <g>
                            {/* eslint-disable-next-line */}
                            <path
                                fill="#A8A19B"
                                d="M10.832 3.257L5.008-2.567c-.46-.46-1.204-.46-1.663 0-.46.46-.46 1.204 0 1.664l4.991 4.991L3.345 9.08c-.46.46-.46 1.205 0 1.664.459.46 1.204.46 1.663 0l5.824-5.824c.46-.458.46-1.203 0-1.663z"
                                transform="translate(341 166.412) rotate(90 7.088 4.088)"/>
                        </g>
                    </g>
                </g>
            </svg>
        </button>
        <div ref={content} style={{maxHeight: `${setHeight}`}} className="accordion__content_container">
            <div className="accordion__text">
                {ServicesOperatorsContentOptions.map((option: any, index: number) =>
                    <div className="content-container" key={index}>
                        <TransportListComponent transports={props.transports}/>
                    </div>)}
            </div>
        </div>
    </div>;
}

export default AccordionFilterComponent;
