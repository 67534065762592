import React from 'react';

import {useHistory, useParams} from 'react-router-dom';

import ButtonSection from '../../components/buttons/button-section/button-section';
import {analyticsEvent} from '../../utils/analytics';
import MapPinContent from './map-pin-content';

import './map-pin-info.scss';

export default function MapPinInfo(props: any) {

    const {id}: any = useParams();
    const history = useHistory();
    const txtButtons = props.buttons ? props.buttons : [];

    const redirectMoreInfo = () => {
        history.push(`/stop/${id}/detail`);
    };

    const goToDirection = () => {
        analyticsEvent('general', 'general_wannago_click');
        return window.open(`https://www.google.com/maps/dir/?api=1&origin&destination=${props.coord?.lat},${props.coord?.lng}`, '_blank');
    };

    return (<>
        <MapPinContent
            icon={props.icon}
            title={props.title}
            description={props.description}
            accessibility={props.accessibility}
            distance={props.distance}
            type={props.type}
            slug={props.slug}
            lines={props.lines}
            pinType={props.pinType}
            hasAlert={props.hasAlert}
            alertId={props.alertId}
        />
        {
            !!txtButtons.length && <ButtonSection
                txtButtons={txtButtons}
                redirectMoreInfo={redirectMoreInfo}
                goToDirection={goToDirection}
            />
        }
    </>);
}
