import React, {useEffect} from 'react';
import {BottomPage} from '../../../clean-components/bottom-page/bottom-page';
import StopDetailComponent from '../../../clean-components/stop-detail/stop-detail.component';
import {BackHomeButton} from '../../../components/buttons/backHome-button/backHome-button';

export const StopDetailMobile = () => {

    useEffect(() => {
        document.getElementsByClassName('header')[0].classList.add('hide');
    }, [location]);

    return <>
        <BackHomeButton/>
        <BottomPage maximizedOnCreate={true} detail={true}>
            <StopDetailComponent buttons={['go', 'report']}/>
        </BottomPage>
    </>;
};
