import {themeOptions} from '../../utils/utils';

export const mapScriptLoaderOptions: any = {
    libraries: 'geometry',
};

export const INIT_COORDS: { lat: number, lng: number } = {lat: 41.3870154, lng: 2.1700471};
export const INIT_BOUNDS: google.maps.LatLngBoundsLiteral = {
    south: 41.33344856954757,
    west: 2.0435326651855545,
    north: 41.45437027090847,
    east: 2.2522728995605545,
};
export const INIT_ZOOM: number = 14;
export const MAXX_ZOOM: number = 14;

export const MARKER_WIDTH = 18;
export const MARKER_HEIGHT = 24;
export const MARKER_SELECTED_WIDTH = 18 * 1.5;
export const MARKER_SELECTED_HEIGHT = 24 * 1.5;
export const MARKER_SQUARE_SIZE = 24;
export const INFO_TIME_MARKER_WIDTH = 30;
export const INFO_TIME_MARKER_HEIGHT = 25;

export const CLUSTERER_CONFIG = {
    maxZoom: 14,
    clusterClass: 'custom-clustericon',
    styles: [
        {
            className: 'custom-clustericon-1',
            height: 53,
            width: 52,
            textLineHeight: 53,
        },
        {
            className: 'custom-clustericon-2',
            height: 55,
            width: 54,
            textLineHeight: 55,
        },
        {
            className: 'custom-clustericon-3',
            height: 66,
            width: 65,
            textLineHeight: 66,
        },
        {
            className: 'custom-clustericon-4',
            height: 78,
            width: 77,
            textLineHeight: 78,
        },
        {
            className: 'custom-clustericon-5',
            height: 90,
            width: 89,
            textLineHeight: 90,
        },
    ],
};

export function getMapStyles(theme: string) {
    if (theme == themeOptions[1]) {
        return [
            {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
            {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
            {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
            {
                featureType: 'administrative.locality',
                elementType: 'labels.text.fill',
                stylers: [{color: '#d59563'}],
            },
            {
                featureType: 'poi',
                stylers: [{visibility: 'off'}],
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{color: '#38414e'}],
            },
            {
                featureType: 'road',
                elementType: 'geometry.stroke',
                stylers: [{color: '#212a37'}],
            },
            {
                featureType: 'road',
                elementType: 'labels.text.fill',
                stylers: [{color: '#9ca5b3'}],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [{color: '#746855'}],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry.stroke',
                stylers: [{color: '#1f2835'}],
            },
            {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [{color: '#f3d19c'}],
            },
            {
                featureType: 'transit',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{color: '#17263c'}],
            },
            {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [{color: '#515c6d'}],
            },
            {
                featureType: 'water',
                elementType: 'labels.text.stroke',
                stylers: [{color: '#17263c'}],
            },
        ];
    } else {
        return [
            {
                featureType: 'poi',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'labels.icon',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'transit',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
        ];
    }
}

export enum MAP_VIEW {
    STOPS,
    CLOSE_STOPS,
    ROUTE,
}
