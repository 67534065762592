import {MangoQuery, RxCollection} from 'rxdb';
import {RouteModel, StopModel} from '../../models';
import {DataSource} from './data-source';

export class RouteDataSource extends DataSource<RouteModel> {

    constructor(collection: RxCollection) {
        super(collection);
    }

    public async find(query?: MangoQuery): Promise<RouteModel[]> {
        return (await super.find(query))
            .sort((a, b) => {
                return a.shortName.localeCompare(b.shortName, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            });
    }

    public async findByStop(stop: StopModel): Promise<RouteModel[]> {
        return this.find({
            selector: {
                routeId: {$in: stop.routesIds},
                slug: stop.slug,
            },
        });
    }
}
