import React, {Dispatch, SetStateAction} from 'react';
import {Observable} from 'rxjs';
import {Db} from '../../core/data/db';
import {RealTimes} from '../../core/data/real-time-worker';
import {AlertModel, RouteModel, ServiceModel, StopModel, TripCoords} from '../../core/models';
import {PinModel} from '../../core/models/pin.model';


export interface DataCtx {
    db?: Db,
    ready: boolean,
    init: (element: HTMLElement) => Promise<Db>,
    pinSet: { [slug: string]: PinModel } | null,
    services: ServiceModel[],
    getCurrentStop: (id?: string) => void,
    currentStop: StopModel | null,
    visibleStops: StopModel[],
    closeStops: StopModel[],
    currentStopAlerts: AlertModel[],
    getCurrentRoute: (id?: string) => void,
    currentRoute: RouteModel | null,
    currentRouteAlerts: AlertModel[],
    currentTrip: number,
    setCurrentTrip: Dispatch<SetStateAction<number>>,
    tripCoords: TripCoords[],
    realTimes: RealTimes
}

export const DataContext = React.createContext<DataCtx>(
    {
        ready: false,
        init: () => new Promise(Promise.resolve),
        pinSet: null,
        services: [],
        getCurrentStop: () => {
        },
        currentStop: null,
        visibleStops: [],
        closeStops: [],
        currentStopAlerts: [],
        getCurrentRoute: () => {
        },
        currentRoute: null,
        currentRouteAlerts: [],
        currentTrip: 0,
        setCurrentTrip: () => {
        },
        tripCoords: [],
        realTimes: {},
    },
);

export const DataProvider = DataContext.Provider;
