import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {analyticsEvent} from '../../utils/analytics';

function SocialMediaComponent(props: any) {
    const {t} = useTranslation();
    const clickEvent = () => {
        analyticsEvent('Menú', 'menu_socialmedia_click', props.title);
        const w: any = window;
        w.href = props.link;
    };
    return (<div className="section-container"
                 onKeyDown={clickEvent}
                 role="button"
                 tabIndex={0}
                 onClick={clickEvent}
        >
            {/* <a href={props.link} target="_blank" rel="noopener noreferrer"> */}
            <div className="section-box">
      <span className={props.mediaIcon}>
      </span>
                <h4 className="social-media-title">
                    {t(props.title)}
                </h4>
            </div>
            <span className={props.icon}>
    </span>
            {/* </a> */}
        </div>
    );
}

export default SocialMediaComponent;
