import React, {createElement, useContext} from 'react';
import {ReactComponent as WalkIcon} from '../../assets/icons/map/walk.svg';
import {getTimeDistance} from '../../core/utils';
import {MapContext} from '../../services/map-v2/map.context';


import './time-walk.scss';

export const TimeWalkComponent = ({
    coords,
    size = 'm',
}: {
    coords: google.maps.LatLngLiteral,
    size?: 's' | 'm' | 'l'
}) => {

    const {pointer} = useContext(MapContext);

    return <div className={`time-walk ${size}`}>
        {createElement(WalkIcon)}
        {`${getTimeDistance(pointer, coords)} min`}
    </div>;
};
