import React, {createElement, useCallback, useContext, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {ReactComponent as alertCanceled} from '../../assets/icons/alerts/system-alerta-anulada.svg';
import {ReactComponent as alertWarning} from '../../assets/icons/alerts/system-alerta-aviso.svg';
import {ReactComponent as alertDetour} from '../../assets/icons/alerts/system-alerta-desvio.svg';
import {ReactComponent as alertGeneral} from '../../assets/icons/alerts/system-alerta-general.svg';
import {ReactComponent as alertSchedule} from '../../assets/icons/alerts/systemAlertaHorari.svg';
import {ReactComponent as externalLink} from '../../assets/icons/menu/external_link_red.svg';
import {AlertModel, RouteModel, StopModel} from '../../core/models';
import {DataContext} from '../../services/data-v2/data.context';
import {translate} from '../../services/translation/translationUtils';
import {AmbIcon} from '../amb-icon/amb-icon.component';
import {ListItem} from '../list/list-item.component';
import {List} from '../list/list.component';
import {ListItemInterface} from '../list/list.interface';

import './alerts-detail.scss';

export const AlertsDetailComponent = () => {

    const {id, type}: any = useParams();
    const {t} = useTranslation();

    const {
        ready,
        db,
        pinSet,
        getCurrentStop,
        currentStop,
        currentStopAlerts,
        getCurrentRoute,
        currentRoute,
        currentRouteAlerts,
    } = useContext(DataContext);

    const [route, setRoute] = useState<ListItemInterface<RouteModel>>();
    const [stop, setStop] = useState<ListItemInterface<StopModel>>();
    const [alerts, setAlerts] = useState<ListItemInterface<AlertModel>[]>([]);

    const mapAlerts = useCallback((alerts: AlertModel[]) => {

        setAlerts(alerts.map((alert) => ({
            id: alert.id,
            title: <div className="alert-title">
                <div>
                    <span>{getAlertIcon(alert)}</span>
                    <span>{translate(alert.headerText)}</span>
                    {alert.isNew && <span className="new">{t('translation:misc_alert_detail_tag')}</span>}
                </div>
                <div>
                    {alert.alertActivePeriod.map((value, key) =>
                        <div key={key}>
                            <span>{`${t('translation:misc_alert_detail_from_date')} ${new Date(parseInt(value.start) * 1000).toLocaleDateString()} `}</span>
                            {
                                value.end &&
                                <span>{`${t('translation:misc_alert_detail_to_date')} ${new Date(parseInt(value.end) * 1000).toLocaleDateString()}`}</span>
                            }
                        </div>,
                    )}
                </div>
            </div>,
            subtitle: <div className="alert-description">
                <div
                    dangerouslySetInnerHTML={{__html: translate(alert.descriptionText).replace(/&nbsp;/g, ' ').trim()}}
                    className="text"
                />
                <a
                    href={translate(alert.url)}
                    target="_blank"
                    rel="noreferrer">
                    {t('translation:misc_alert_detail_show_more')}
                    {createElement(externalLink)}
                </a>
            </div>,
            data: alert,
        })));

    }, [type, id, db, pinSet]);

    const getAlertIcon = (alert: AlertModel) => {

        switch (alert.effect) {
        case 'MODIFIED_SERVICE':
        case 'REDUCED_SERVICE':
        case 'SIGNIFICANT_DELAYS':
        case 'ADDITIONAL_SERVICE':
            return createElement(alertSchedule);
        case 'NO_SERVICE':
        case 'UNKNOWN_EFFECT':
            return createElement(alertGeneral);
        case 'DETOUR':
            return createElement(alertDetour);
        case 'STOP_MOVED':
            return createElement(alertCanceled);
        case 'NO_EFFECT':
        case 'ACCESSIBILITY_ISSUE':
        case 'OTHER_EFFECT':
            return createElement(alertWarning);
        default:
            return null;
        }

    };


    useEffect(() => {
        if (ready && type) {
            switch (type) {
            case 'lines':
                getCurrentRoute(id);
                break;
            case 'stops':
                getCurrentStop(id);
                break;
            }
        }
        return () => {
            getCurrentRoute();
            getCurrentStop();
        };
    }, [ready, type, id, db, pinSet]);

    useEffect(() => {
        if (currentStop && pinSet) {

            setStop({
                id: currentStop.id,
                title: currentStop.stopName,
                icon: <AmbIcon
                    size="m"
                    shape="square"
                    backgroundColor={pinSet[currentStop.slug].color}
                    img={pinSet[currentStop.slug].icon}/>,
                data: currentStop,
            });
        } else {
            setStop(undefined);
        }
    }, [currentStop, pinSet]);

    useEffect(() => {

        if (currentRoute) {

            setRoute({
                id: currentRoute.id,
                title: currentRoute.longName,
                icon: <AmbIcon
                    text={currentRoute.shortName}
                    color={currentRoute.textColor}
                    backgroundColor={currentRoute.color}
                    shape={currentRoute.slug === 'busamb' ? 'round' : 'square'}
                    size="m"/>,
                data: currentRoute,
            });
        } else {
            setRoute(undefined);
        }
    }, [currentRoute]);

    useEffect(() => {
        if (type) {
            switch (type) {
            case 'lines':
                mapAlerts(currentRouteAlerts);
                break;
            case 'stops':
                mapAlerts(currentStopAlerts);
                break;
            }
        }
    }, [type, currentStopAlerts, currentRouteAlerts]);

    return <div className={`alert-detail-container${isMobile ? ' mobile' : ''}`}>
        {
            route && <ListItem {...route}/>
        }
        {
            stop && <ListItem {...stop}/>
        }
        <List items={alerts}/>
    </div>;
};
