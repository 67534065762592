import React, {useContext, useEffect} from 'react';
import {DesktopPage} from '../../../clean-components/desktop-page/desktop-page';
import {SettingsComponent} from '../../../clean-components/setttings/settings.component';
import {HeaderContext} from '../../../services/header/header.context';

export const SettingsDesktop = () => {
    const HeaderService = useContext(HeaderContext);
    const headerConfig = {
        title: 'translation:misc_settings_title',
        backIcon: true,
        closeIcon: false,
        logoIcon: false,
        customClassName: true,
    };
    useEffect(() => {
        if (HeaderService.headerConfig.title != headerConfig.title) {
            HeaderService.initHeader(headerConfig);
        }
    }, [HeaderService.headerConfig]);

    return <DesktopPage config={headerConfig}>
        <SettingsComponent/>
    </DesktopPage>;
};
