import React, {createElement, useCallback, useContext, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {Link} from 'react-router-dom';
import {ReactComponent as systemFilter} from '../../assets/icons/buttons/system-filter.svg';
import {ALERTS_TABS} from '../../core/constants/alert.constants';
import {AlertModel, RouteModel, ServiceModel, StopModel} from '../../core/models';
import {DataContext} from '../../services/data-v2/data.context';
import {SpinnerContext} from '../../services/spinner/spinner.context';
import {translate} from '../../services/translation/translationUtils';
import {analyticsEvent} from '../../utils/analytics';
import {AmbIcon} from '../amb-icon/amb-icon.component';
import {List} from '../list/list.component';
import {ListItemInterface} from '../list/list.interface';
import {NavOptionsComponent} from '../nav-options/nav-options.component';
import SearchBar from '../search-bar/search-bar';

import './alerts.scss';

export const AlertsComponent = () => {

    const {ready, db, pinSet, services} = useContext(DataContext);
    const {activeSpinner, deactivateSpinner} = useContext(SpinnerContext);

    const [selectedOption, setSelectedOption] = useState(ALERTS_TABS[0]);
    const [selectedTitle, setSelectedTitle] = useState(ALERTS_TABS[0].translate);
    const [searchText, setSearchText] = useState('');

    // const [services, setServices] = useState<ServiceModel[]>([]);
    const [routes, setRoutes] = useState<{ [slug: string]: ListItemInterface<RouteModel>[] }>({});
    const [stops, setStops] = useState<{ [slug: string]: ListItemInterface<StopModel>[] }>({});

    const getAlerts = useCallback(async () => {
        activeSpinner();

        if (pinSet) {
            const routesData: { [slug: string]: ListItemInterface<RouteModel>[] } = {};
            const stopsData: { [slug: string]: ListItemInterface<StopModel>[] } = {};

            const alerts = await db?.dataSources?.alerts.find();

            const data = alerts?.reduce((data: { routes: string[], stops: string[] }, alert: AlertModel) => ({
                routes: Array.from(new Set([...data.routes, ...alert.alertRoute.map((id) => `${alert.slug}:${id}`)])),
                stops: Array.from(new Set([...data.stops, ...alert.alertStop.map((id) => `${alert.slug}:${id}`)])),
            }), {routes: [], stops: []}) || {routes: [], stops: []};

            for (const service of services) {

                const routes = await db?.dataSources?.routes.find({
                    selector: {
                        $and: [
                            {
                                slug: service.slug,
                            },
                            {
                                id: {$in: data.routes},
                            },
                            {
                                $or: [
                                    {longName: {$regex: new RegExp(`.*${searchText}.*`, 'i')}},
                                    {shortName: {$regex: new RegExp(`.*${searchText}.*`, 'i')}},
                                ],
                            },
                        ],
                    },
                }) || [];

                routesData[service.slug] = routes.map((route) => ({
                    id: route.id,
                    title: route.longName,
                    href: `/alerts/lines/${route.id}`,
                    icon: <AmbIcon
                        text={route.shortName}
                        color={route.textColor}
                        backgroundColor={route.color}
                        shape={route.slug === 'busamb' ? 'round' : 'square'}
                        size="m"/>,
                    data: route,
                }));

                const stops = await db?.dataSources?.stops.find({
                    selector: {
                        $and: [
                            {
                                slug: service.slug,
                            },
                            {
                                id: {$in: data.stops},
                            },
                            {
                                stopName: {$regex: new RegExp(`.*${searchText}.*`, 'i')},
                            },
                        ],
                    },
                }) || [];

                stopsData[service.slug] = stops.map((stop) => ({
                    id: stop.id,
                    title: stop.stopName,
                    href: `/alerts/stops/${stop.id}`,
                    icon: <AmbIcon
                        size="m"
                        shape="square"
                        backgroundColor={service.color}
                        img={pinSet[service.slug]?.icon}/>,
                    data: stop,
                }));
            }


            // setServices(services);
            setRoutes(routesData);
            setStops(stopsData);
        }

        deactivateSpinner();
    }, [db, searchText, services, pinSet]);

    const onChangeSearch = (searchString: string) => {
        analyticsEvent('Alertes i incidències', 'alerts_search_click');
        setSearchText(searchString);
    };

    const clickEventGa = () => {
        analyticsEvent('Alertes i incidències', 'alerts_filter_click');
    };

    useEffect(() => {
        if (ready) {
            getAlerts();
        }
    }, [ready, pinSet]);

    useEffect(() => {
        getAlerts();
    }, [searchText]);

    useEffect(() => {

        setSelectedTitle(selectedOption.translate);
    }, [selectedOption]);

    return <div className={`alerts-container${isMobile ? ' mobile' : ''}`}>
        <div className="search-container">
            <SearchBar
                iconSearch={true}
                backIcon={false}
                value={{
                    placeHolder: selectedTitle,
                    onChangeSearch,
                }}/>
            <div
                onKeyDown={clickEventGa}
                role="button"
                tabIndex={0}
                onClick={clickEventGa}>
                <Link to="/alerts-filters">
                    <span className="filter">{createElement(systemFilter)}</span>
                    {/* {filterList.length == 0 ? '' : <div className="filter-number">{filterList.length}</div>}*/}
                </Link>
            </div>
        </div>
        <div className="tabs">
            <NavOptionsComponent value={{
                tabOptions: ALERTS_TABS,
                changeOption: setSelectedOption,
                selectedOption,
            }}/>
        </div>
        <div className="bottom-line"/>
        <div className="alerts">
            {
                services
                    .filter((service) => selectedOption.id === 1 ?
                        routes[service.slug] && routes[service.slug].length :
                        stops[service.slug] && stops[service.slug].length)
                    .map((service: ServiceModel, index: number) => <List
                        key={index}
                        title={translate(service.name)}
                        items={selectedOption.id === 1 ? routes[service.slug] : stops[service.slug]}
                    />)
            }
        </div>
    </div>;
};
