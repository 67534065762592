import {ReactComponent as AMBIcon} from '../../assets/icons/menu/amb.svg';
import {ReactComponent as CommentsIcon} from '../../assets/icons/menu/comentario.svg';
import {ReactComponent as BuyIcon} from '../../assets/icons/menu/comprar.svg';
import {ReactComponent as MapsIcon} from '../../assets/icons/menu/planos.svg';
import {ReactComponent as ServicesIcon} from '../../assets/icons/menu/servicios.svg';
import {ReactComponent as SettingsIcon} from '../../assets/icons/menu/settings.svg';
import {ReactComponent as RatesIcon} from '../../assets/icons/menu/tarifas.svg';
import './menu.scss';

export interface MenuSectionInterface {
    title: string,
    items: MenuSectionItemInterface[]
}

export interface MenuSectionItemInterface {
    content: string,
    to: any,
    icon?: any,
    external?: boolean,
    ga?: string
}

const lang = localStorage.getItem('i18nextLng');

function addLangToUrl() {
    if (lang === 'es') {
        return ' https://www.ambmobilitat.cat/Principales/PlanosMunicipios.aspx?idioma=2';
    }
    if (lang === 'en') {
        return ' https://www.ambmobilitat.cat/Principales/PlanosMunicipios.aspx?idioma=3';
    }
    if (lang === 'cat') {
        return ' https://www.ambmobilitat.cat/Principales/PlanosMunicipios.aspx?idioma=1';
    }
}

export const desktopSections: MenuSectionInterface[] = [
    {
        title: 'translation:app_menu_services_information_title',
        items: [
            {
                content: 'translation:app_menu_service_plans_title',
                to: addLangToUrl(),
                icon: MapsIcon,
                external: true,
                ga: 'menu_scheme_click',
            },
            {
                content: 'translation:app_menu_service_operators_title',
                to: '/services',
                icon: ServicesIcon,
            },
            {
                content: 'translation:app_menu_rates_title',
                to: 'http://www.ambmobilitat.cat/principales/Tarifas.aspx',
                icon: RatesIcon,
                external: true,
                ga: 'menu_rates_click',
            },
            {
                content: 'translation:app_menu_buy_tickets_title',
                to: ' https://tickets.tmb.cat',
                icon: BuyIcon,
                external: true,
            },
        ],
    },
    {
        title: 'translation:app_name',
        items: [
            {
                content: 'translation:app_menu_settings_title',
                to: '/settings',
                icon: SettingsIcon,
            },
            {
                content: 'translation:app_menu_contact_amb_title',
                to: '/info',
                icon: CommentsIcon,
            },
            {
                content: 'translation:misc_info_legal_information_item_title',
                to: 'https://www.amb.cat/s/web/amb/avis-legal.html',
                icon: AMBIcon,
                external: true,
            },
        ],
    },
];

