import React from 'react';
import {useTranslation} from 'react-i18next';
import {navOptionsInterface} from '../../interfaces/components/NavOptionsInterface';
import './nav-options.scss';

export const NavOptionsComponent = (props: navOptionsInterface) => {
    const {t} = useTranslation();
    const options = props?.value.tabOptions;
    const changeOption = props?.value.changeOption;
    const selectedOption = props?.value.selectedOption;
    return (
        <>
            {options.map((option: any, index: number) => {
                let style = {};
                if (option.visible) {
                    if (option.id === selectedOption.id) {
                        style = {
                            'borderBottom': 'solid 3px #da322a',
                            'color': '#da322a',
                        } as React.CSSProperties;
                    }
                    return (
                        <div style={style} key={index}>
                            <div className="nav-options">
                                <div>
                  <span
                      role="button"
                      onKeyDown={(e) => changeOption(option)}
                      onClick={(e) => changeOption(option)}
                      tabIndex={0}
                  >
                    {t(option.translate)}
                  </span>
                                </div>
                            </div>
                        </div>
                    );
                }
            })}
        </>
    );
};
