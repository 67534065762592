import React, {createElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as placeIcon} from '../../assets/icons/lines/system-place.svg';
import {ReactComponent as iconGo} from '../../assets/icons/lines/system-quieroir-1.svg';
import {StopModel} from '../../core/models';
import {goTo} from '../../core/utils';
import {DataContext} from '../../services/data-v2/data.context';
import {TimeWalkComponent} from '../time-walk/time-walk.component';

import './car-stop-detail.scss';

export const CarStopDetailComponent = ({
    stop,
}: {
    stop: StopModel,
}) => {

    const {t} = useTranslation();

    const {pinSet} = useContext(DataContext);

    return <div className="car-stop">

        <div className="stop-header">
            {
                pinSet && <img
                    src={pinSet[stop.slug].logo}
                    alt="logo"
                    className="logo"
                />
            }
            <button className="go-to-button" onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                goTo(stop);
            }}>
                {createElement(iconGo)}
                {t('translation:transport_sheet_toolbar_indications')}
            </button>
        </div>

        <div className="stop-info">
            <div className="title">
                {`${t('translation:transport_marker_detail_other_stop_subtitle')} nº ${stop.stopName}`}
                <TimeWalkComponent coords={stop} size="s"/>
            </div>
            {
                stop.stopDesc && <div className="address">
                    <div className="marker">{createElement(placeIcon)}</div>
                    <div>{stop.stopDesc}</div>
                </div>
            }
        </div>
    </div>;
};
