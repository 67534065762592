import {createRxDatabase} from 'rxdb';
import {RxDatabase} from 'rxdb/dist/types/types';
import {getRxStorageLoki} from 'rxdb/plugins/lokijs';
import {AlertDataSource, PinDataSource, RouteDataSource, ServiceDataSource, ServiceGroupDataSource, StopDataSource} from './data-sources';
import {alertSchema, routeSchema} from './schemas';
import {pinSchema} from './schemas/pin.schema';
import {serviceGroupSchema} from './schemas/service-group.schema';
import {serviceSchema} from './schemas/service.schema';
import {stopSchema} from './schemas/stop.schema';

// addPouchPlugin(require('pouchdb-adapter-idb'));
const LokiIncrementalIndexedDBAdapter = require('lokijs/src/incremental-indexeddb-adapter');

export class Db {

    private db?: RxDatabase;
    public dataSources: {
        alerts: AlertDataSource,
        routes: RouteDataSource,
        stops: StopDataSource,
        serviceGroups: ServiceGroupDataSource,
        services: ServiceDataSource,
        pins: PinDataSource,
    } | undefined;

    constructor() {

    }

    public async init(): Promise<Db> {
        await this.createDatabase();
        await this.createCollections();
        this.createDataSources();
        return this;
    }

    private async createDatabase(): Promise<RxDatabase> {
        if (!this.db) {
            this.db = await createRxDatabase({
                name: 'amb',
                storage: getRxStorageLoki({
                    adapter: new LokiIncrementalIndexedDBAdapter(),
                }),
                multiInstance: true,
                eventReduce: true,
                ignoreDuplicate: true,
            });
        }

        return this.db;
    }

    private async createCollections(): Promise<any> {

        return this.db?.addCollections(
            {
                alerts: {
                    schema: alertSchema,
                },
                routes: {
                    schema: routeSchema,
                },
                stops: {
                    schema: stopSchema,
                },
                servicegroups: {
                    schema: serviceGroupSchema,
                },
                services: {
                    schema: serviceSchema,
                },
                pins: {
                    schema: pinSchema,
                },
            },
        );
    }

    private createDataSources(): Db {
        if (this.db) {
            this.dataSources = {
                alerts: new AlertDataSource(this.db.alerts),
                routes: new RouteDataSource(this.db.routes),
                stops: new StopDataSource(this.db.stops),
                serviceGroups: new ServiceGroupDataSource(this.db.servicegroups),
                services: new ServiceDataSource(this.db.services),
                pins: new PinDataSource(this.db.pins),
            };
        }
        return this;
    }
}
