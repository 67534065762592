import {translationSchema} from './common.schema';

export const serviceSchema = {
    title: 'services',
    version: 0,
    description: 'AMB services',
    type: 'object',
    primaryKey: 'id',
    properties: {
        id: {
            type: 'string',
        },
        slug: {
            type: 'string',
        },
        groupSlug: {
            type: 'string',
        },
        dataEndPoint: {
            type: 'string',
        },
        dataTypeOptions: {
            type: 'string',
        },
        dataType: {
            type: 'string',
        },
        name: {
            type: 'array',
            items: {
                type: 'object',
                properties: translationSchema,
            },
        },
        description: {
            type: 'array',
            items: {
                type: 'object',
                properties: translationSchema,
            },
        },
        color: {
            type: 'string',
        },
        colorDark: {
            type: 'string',
        },
        icon: {
            type: 'object',
            properties: {
                id: {
                    type: 'string',
                },
                type: {
                    type: 'string',
                },
                data: {
                    type: 'string',
                },
            },
        },
        iconDark: {
            type: 'object',
            properties: {
                id: {
                    type: 'string',
                },
                type: {
                    type: 'string',
                },
                data: {
                    type: 'string',
                },
            },
        },
        logo: {
            type: 'object',
            properties: {
                id: {
                    type: 'string',
                },
                type: {
                    type: 'string',
                },
                data: {
                    type: 'string',
                },
            },
        },
        logoDark: {
            type: 'object',
            properties: {
                id: {
                    type: 'string',
                },
                type: {
                    type: 'string',
                },
                data: {
                    type: 'string',
                },
            },
        },
    },
    indexes: [],
};
