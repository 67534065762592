import React, {createElement, useContext, useEffect} from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ReactComponent as ExternalLinkIcon} from '../../assets/icons/menu/external_link.svg';
import {ReactComponent as LogoAMB} from '../../assets/logo-amb-positivo.svg';
import {SidebarContext} from '../../services/sidebar/sidebar.context';
import {analyticsEvent} from '../../utils/analytics';
import {themeOptions} from '../../utils/utils';

import {desktopSections} from './menu-desktop.config';
import {MenuSectionInterface, MenuSectionItemInterface, sections} from './menu.config';

import './menu.scss';

export function Menu() {
    const menuSections = (isMobile) ? sections : desktopSections;
    const theme = localStorage.getItem('theme');
    const {t} = useTranslation();
    const appVersion = process.env.REACT_APP_VERSION;
    useEffect(() => {
        if (localStorage.getItem('theme') === themeOptions[0]) {
        } else if (localStorage.getItem('theme') === themeOptions[0]) {
        }
    }, [theme]);

    const clickEvent = (media: string) => {
        analyticsEvent('Menú', 'menu_socialmedia_click', media);
    };

    return <div className={`menu ${!isMobile ? 'desktop' : ''}`}>
        <nav>
            <div className="content">
                <div className="menu-top">
                    <LogoAMB className="logo-amb"/>
                    <span className="app-version">{t('translation:app_version')} {appVersion} </span>
                </div>
                {
                    menuSections.map((section: any, index: number) => {
                        return <MenuSection key={index} section={section}/>;
                    })
                }
            </div>
            <div className="menu-social-media">
        <span
            onKeyDown={() => clickEvent('facebook')}
            role="button"
            tabIndex={0}
            onClick={() => clickEvent('facebook')}
        >
          <Link to={{pathname: 'https://es-es.facebook.com/MobilitatAMB/'}} target="_blank">
            {theme == themeOptions[0] ?
                <span className="facebook light"></span> :
                <span className="facebook dark"></span>}
          </Link>
        </span>
                <span
                    onKeyDown={() => clickEvent('twitter')}
                    role="button"
                    tabIndex={0}
                    onClick={() => clickEvent('twitter')}
                >
          <Link to={{pathname: 'https://twitter.com/Mobilitat_AMB'}} target="_blank">
            {theme == themeOptions[0] ?
                <span className="twitter light"></span> :
                <span className="twitter dark"></span>}
          </Link>
        </span>
                <span
                    onKeyDown={() => clickEvent('instagram')}
                    role="button"
                    tabIndex={0}
                    onClick={() => clickEvent('instagram')}
                >
          <Link to={{pathname: 'https://instagram.com/ambmobilitat/'}} target="_blank">
            {theme == themeOptions[0] ?
                <span className="instagram light"></span> :
                <span className="instagram dark"></span>}
          </Link>
        </span>
                <span
                    onKeyDown={() => clickEvent('youtube')}
                    role="button"
                    tabIndex={0}
                    onClick={() => clickEvent('youtube')}
                >
          <Link to={{pathname: 'https://www.youtube.com/c/%C3%80reaMetropolitanadeBarcelona'}} target="_blank">
            {theme == themeOptions[0] ?
                <span className="youtube light"></span> :
                <span className="youtube dark"></span>}
          </Link>
        </span>
            </div>
        </nav>
    </div>;
}


export function MenuSection({section}: { section: MenuSectionInterface }) {
    const {t} = useTranslation();
    return <div className="menu-section">
        {section.title ?
            <div className="title">{t(section.title)}</div> : ''}
        {
            section.items.map((item: any, index: number) => {
                return <MenuSectionItem key={index} item={item}/>;
            })
        }
    </div>;
}

export function MenuSectionItem({item}: { item: MenuSectionItemInterface }) {
    const {t} = useTranslation();
    const {hide} = useContext(SidebarContext);

    const sectionClick = (item: any) => {
        if (!!item.ga) {
            analyticsEvent('Menú', item.ga);
        }
        hide();
    };

    return <div className="menu-section-item">
        <Link
            to={{pathname: item.to}}
            onClick={() => sectionClick(item)}
            target={item.external ? '_blank' : '_self'}>
            <div
                aria-label={item.content}
            >
                {createElement(item.icon, {className: 'icon'})}
            </div>
            <span className="text">{t(item.content)}</span>
            {item.external ? <ExternalLinkIcon className="external-link"/> : ''}
        </Link>
    </div>;
}
