export const pinSchema = {
    title: 'services',
    version: 0,
    description: 'AMB services',
    type: 'object',
    primaryKey: 'id',
    properties: {
        id: {
            type: 'string',
        },
        color: {
            type: 'string',
        },
        colorDark: {
            type: 'string',
        },
        icon: {
            type: 'string',
        },
        iconDark: {
            type: 'string',
        },
        logo: {
            type: 'string',
        },
        logoDark: {
            type: 'string',
        },
        pin: {
            type: 'string',
        },
    },
};
