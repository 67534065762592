export const activePeriodSchema = {
    start: {
        type: 'string',
    },
    end: {
        type: 'string',
    },
};

export const translationSchema = {
    code: {
        type: 'string',
    },
    text: {
        type: 'string',
    },
};
